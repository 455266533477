import { useContext, ReactNode, useEffect } from "react";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import ItemColumnButton from "../ItemColumnButton";
import ItemColumn from "../ItemColumn";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { context as ComponentContext } from "./ComponentOptionsProvider";
import Loading from "views/Loading";
import Select from "react-select";
import { RootState } from "state/store";
import { useSelector } from "hooks";

type Props = {
  children?: ReactNode;
};

type paramTypes = Record<"component_id" | "placement_id", string>;

export default function ComponentOptions({ children }: Props) {
  const { loading, components, group, setGroup } = useContext(ComponentContext);
  const params = useParams<paramTypes>();
  // @ts-ignore
  const componentID = parseInt(params.component_id);
  // @ts-ignore
  const placementID = parseInt(params.placement_id);
  const groups = useSelector((state) => state.group.groups);
  const component = components.find((c) => c.id === componentID);

  const placements = component?.placements || [];

  const projects = useSelector((state: RootState) => state.user.missions);
  const missionID = useSelector((state: RootState) => state.mission.id);

  const skyqraft_employee = useSelector(
    (state: RootState) => state.user.skyqraft_employee
  );

  const editAccess = !group?.access_levels?.includes("readonly");

  const navigate = useNavigate();

  function updateGroup(group: RootState["group"]["groups"][number] | null) {
    setGroup(group);

    // Unset component id when changing group
    if (componentID) {
      navigate("/options/pole/status/component/");
    }
  }

  useEffect(() => {
    if (groups.length > 0) {
      const missionGroup = projects.find((project) =>
        project.id === missionID ? project.group : null
      );
      const groupId = missionGroup?.group?.id;

      if (groupId) {
        updateGroup(groups.find((g) => g.id === groupId) ?? null);
      } else if (!skyqraft_employee) {
        updateGroup(groups[0]); // fallback to first available group if not internal user (then global is used)
      }
    }
  }, [groups, missionID, projects]);

  return (
    <Box>
      {loading && (
        <Stack
          width={"100%"}
          p={2}
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Loading relative size={60} color="black" />
          <Typography>Loading components...</Typography>
        </Stack>
      )}
      <Typography variant="h5">Select Customer</Typography>
      <Typography mb={1}>
        Structure templates are customer specific. Please select a customer
        first.
      </Typography>
      <Select
        options={[
          ...(skyqraft_employee
            ? [
                {
                  value: null,
                  label: "Global",
                },
              ]
            : []),
          ...groups.map((g) => ({ value: g.id, label: g.name })),
        ]}
        value={
          group
            ? {
                value: group.id,
                label: group.name,
              }
            : {
                value: null,
                label: skyqraft_employee ? "Global" : "Select a customer",
              }
        }
        // @ts-ignore
        onChange={(e) => {
          // @ts-ignore
          updateGroup(groups.find((g) => g.id === e.value) ?? null);
        }}
      />
      <Divider sx={{ my: 2 }} />
      {!loading && (
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem sx={{ mx: 2 }} />}
        >
          <ItemColumn
            title="Components"
            description="A component defines a physical object of a structure you want to keep track of"
            {...(editAccess && {
              addLink: "/options/pole/status/component/add",
            })}
            dataTestID="component-list"
          >
            {components
              .sort((a, b) => a.name.localeCompare(b.name))
              .filter(
                (component) =>
                  component.group_id === group?.id ||
                  (component.group_id === null &&
                    group === null &&
                    skyqraft_employee)
              )
              .map((component) => (
                <ItemColumnButton
                  key={component.id}
                  link={`/options/pole/status/component/${component.id}`}
                  active={component.id === componentID}
                >
                  {component.name}
                </ItemColumnButton>
              ))}
          </ItemColumn>
          <ItemColumn
            title="Placements"
            description="A placement defines where the component is located on the structure"
            {...(editAccess && {
              addLink: `/options/pole/status/component/${componentID}/placement/add`,
            })}
          >
            {placements
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((placement) => (
                <ItemColumnButton
                  key={placement.id}
                  link={`/options/pole/status/component/${componentID}/placement/${placement.id}`}
                  active={placement.id === placementID}
                >
                  {placement.name}
                </ItemColumnButton>
              ))}
          </ItemColumn>

          {editAccess ? children ? children : <Outlet /> : null}
        </Stack>
      )}
    </Box>
  );
}
