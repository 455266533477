import { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Virtuoso } from "react-virtuoso";
import { useParams } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Mission } from "interfaces";
import { useSelector, useDispatch } from "hooks";
import {
  setPreviousDefectImages,
  setSelectedPreviousDefectImage,
} from "state/actions";
import ListModes from "./ListModes";
import Controls from "./Controls";
import ImageSection from "./ImageSection";
import PoleSection from "./PoleSection";
import { listModes, IDataGroup } from "./constants";
import { useNavigation, ReviewedImagesHookTypes } from "./hooks";

interface IProps {
  dataGroup: IDataGroup;
  dataKeys: number[];
  imageCount: number;
  prevProject?: Mission;
  prevYearAccess: boolean;
  loading: boolean;
  listMode: number;
  filterActive: boolean;
  setListMode: (listMode: number) => void;
  onRefresh: () => void;
  reviewedImagesGroup: ReviewedImagesHookTypes["reviewedImagesGroup"];
  addReviewedImage: ReviewedImagesHookTypes["addReviewedImage"];
  removeReviewedImage: ReviewedImagesHookTypes["removeReviewedImage"];
}

export default function ListView(props: IProps): ReactElement {
  const {
    dataGroup,
    dataKeys,
    imageCount,
    prevProject,
    prevYearAccess,
    loading,
    listMode,
    filterActive,
    setListMode,
    onRefresh,
    reviewedImagesGroup,
    addReviewedImage,
    removeReviewedImage,
  } = props;

  const [viewedImageIds, setViewedImageIds] = useState<number[]>([]);

  const virtuosoRef = useRef(null);
  const prevDataIndexRef = useRef(-1);

  const previousDefectImageId = useSelector(
    (state) => state.imageViewer.selectedPreviousDefectImage?.id
  );

  // https://app.launchdarkly.com/projects/default/flags/map-panels-refactor/targeting?env=test&env=production&selected-env=test
  const { mapPanelsRefactor } = useFlags();

  const dispatch = useDispatch();
  const params = useParams();
  const urlImageId = params.image ? parseInt(params.image) : -1;

  const imageId =
    listMode === listModes.PREV_YEAR ? previousDefectImageId : urlImageId;

  const { goToNextImage, goToPreviousImage, goToImage, dataIndex } =
    useNavigation({
      dataGroup,
      dataKeys,
      prevProject,
      listMode,
      imageId,
      urlImageId,
    });

  const scrollToIndex = (index: number) => {
    if (!virtuosoRef.current) return;

    // Only use smooth scrolling for short distances
    const smooth =
      prevDataIndexRef.current >= 0 &&
      Math.abs(dataIndex - prevDataIndexRef.current) < 10;

    prevDataIndexRef.current = dataIndex;

    // @ts-ignore
    virtuosoRef.current.scrollToIndex({
      index,
      align: "center",
      ...(smooth && { behavior: "smooth" }),
    });
  };

  useEffect(() => {
    if (imageId && !viewedImageIds.includes(imageId)) {
      setViewedImageIds([...viewedImageIds, imageId]);
    }
  }, [imageId, viewedImageIds]);

  useEffect(() => {
    prevDataIndexRef.current = -1;

    if (listMode !== listModes.PREV_YEAR) {
      dispatch(setPreviousDefectImages([]));
      dispatch(setSelectedPreviousDefectImage(null));
    }

    setTimeout(() => {
      // Fallback for when scrollToIndex is triggered before the list is ready when switching modes
      if (prevDataIndexRef.current > 0) {
        scrollToIndex(prevDataIndexRef.current);
      }
    }, 100);
  }, [listMode]);

  // Scroll to the new index when dataIndex changes
  useEffect(() => {
    if (dataIndex < 0) return;

    scrollToIndex(dataIndex);
  }, [dataIndex]);

  const getListItem = (index: number, key: number) => {
    switch (listMode) {
      case listModes.IMAGES: {
        return (
          <ImageSection
            marker={dataGroup[key].markers[0]}
            onClick={() => goToImage(dataGroup[key], 0)}
            active={dataIndex === index}
            viewedImageIds={viewedImageIds}
          />
        );
      }
      case listModes.POLES: {
        return (
          <>
            <PoleSection
              poleId={key}
              markers={dataGroup[key].markers}
              onClick={() => goToImage(dataGroup[key], 0)}
              active={dataIndex === index}
            />
            {dataGroup[key].markers.map((m, i) => (
              <ImageSection
                key={m.id}
                marker={m}
                onClick={() => goToImage(dataGroup[key], i)}
                active={imageId === m.id}
                childItem
                viewedImageIds={viewedImageIds}
              />
            ))}
          </>
        );
      }
      case listModes.PREV_YEAR: {
        return (
          <>
            <PoleSection
              poleId={key}
              markers={dataGroup[key].markers}
              onClick={() => goToImage(dataGroup[key], 0)}
              active={dataIndex === index}
            />
            {dataGroup[key].markers.map((m, i) => {
              const reviewed = !!reviewedImagesGroup[m.id];
              const onToggleReviewed = reviewed
                ? async () => await removeReviewedImage(m.id)
                : async () => await addReviewedImage(m.id);

              return (
                <ImageSection
                  key={m.id}
                  marker={m}
                  onClick={() => goToImage(dataGroup[key], i)}
                  active={previousDefectImageId === m.id}
                  childItem
                  viewedImageIds={viewedImageIds}
                  reviewEnabled
                  reviewed={reviewed}
                  onToggleReviewed={onToggleReviewed}
                />
              );
            })}
          </>
        );
      }
    }
  };

  return (
    <Container
      mapPanelsRefactor={mapPanelsRefactor}
      data-testid="imageList.container"
    >
      <ListModes
        listMode={listMode}
        setListMode={setListMode}
        prevYearAccess={prevYearAccess}
      />
      <Controls
        imageCount={imageCount}
        loading={loading}
        filterActive={filterActive}
        goToNextImage={goToNextImage}
        goToPreviousImage={goToPreviousImage}
        onRefresh={() => {
          // reset data index so it doesn't try to smooth scroll to its position after data is loaded
          prevDataIndexRef.current = -1;
          onRefresh();
        }}
      />
      <List>
        <Virtuoso
          style={{ width: "100%" }}
          ref={virtuosoRef}
          data={dataKeys}
          itemContent={(index: number, key: number) => {
            // @ts-ignore
            return <ListItem key={key}>{getListItem(index, key)}</ListItem>;
          }}
        />
      </List>
    </Container>
  );
}

interface IContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  mapPanelsRefactor: boolean;
}
const Container = styled.div<IContainerProps>`
	${(props) =>
    props.mapPanelsRefactor &&
    `flex-shrink: 0;
		flex-grow: 0;`}

	${(props) =>
    !props.mapPanelsRefactor &&
    `position: absolute;
	top: 56px;
	right: 0px;
	height: calc(100vh - 58px);
	background-color: #fff;
	box-shadow: -3px 0px 0px 0px rgba(0,0,0,0.75);
	z-index: 100;
	`}

	display: flex;
	flex-direction: column;
	align-items: flex-end;
	box-sizing: border-box;
	width: 370px;
	padding-bottom: 32px;
`;

const List = styled.div`
	flex: 1;
	width: 100%;
	box-sizing: border-box;
`;

const ListItem = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 5px;
`;
