import { ImageMeta, Mission, Pole } from "interfaces";
import { connect } from "react-redux";
import {
  clearFilterArea,
  loadPowerlines,
  loadZones,
  onApiLoaded,
  onMapClick,
  setFilterSelectorStatus,
  setMissionID,
  updateAnnotatorAssignments,
  updateAssignments,
  updateMarkers,
} from "state/actions";
import { RootState } from "state/store";
import { Marker } from "interfaces";
import Component from "./MapView";

interface IStateProps {
  // @ts-ignore
  markers: Marker[];
  showMenu: boolean;
  currentImage: ImageMeta | null;
  showMarkers: boolean;
  clusterMethod: string;
  clusterDistance: number;
  clusterVisible: boolean;
  poles: Pole[];
  showPoles: boolean;
  showZones: boolean;
  missions: Mission[];
  demoMode: boolean;
  visibleImageTypes: number[];
  supervisorMode: boolean;
  inspectorMode: boolean;
  mapLoading: boolean;
  clearanceMarkers: any[];
  showClearanceMarkers: boolean;
  lidarMarkers: any[];
  showLidarMarkers: boolean;
  showListView: boolean;
  openedMarkers: number[];
}

interface IDispatchProps {
  onApiLoaded: Function;
  loadPowerlines: (
    map: google.maps.Map,
    updateBounds: boolean,
    mission: number
  ) => void;
  loadZones: (map: google.maps.Map, mission: number) => void;
  setMissionID: (mission: string | number) => void;
  updateAssignments: (mission: number) => void;
  onMapClick: () => void;
  updateAnnotatorAssignments: (mission: number) => void;
  setFilterSelectorStatus: Function;
  clearFilterArea: Function;
  updateMarkers: Function;
}

interface IOwnProps {
  hideElements?: boolean;
}

const mapStateToProps = (state: RootState) => {
  return {
    markers: state.map.markers,
    showMenu: state.map.menu.show,
    currentImage: state.image.current,
    showMarkers: state.ui.showMarkers,
    clusterMethod: state.imageViewer.clusterMethod,
    clusterDistance: parseInt(state.imageViewer.distance),
    clusterVisible: state.ui.clusterVisible,
    poles: state.map.poles,
    showPoles: state.ui.showPoles,
    showZones: state.ui.showZones,
    missions: state.user.missions,
    demoMode: state.ui.demoMode,
    visibleImageTypes: state.map.visibleImageTypes,
    supervisorMode: state.ui.supervisorMode,
    inspectorMode: state.map.inspectorMode,
    mapLoading:
      !!state.map.markerController ||
      state.map.powerlineController ||
      state.map.polesLoading,
    clearanceMarkers: state.map.clearanceMarkers,
    showClearanceMarkers: state.ui.showClearanceMarkers,
    lidarMarkers: state.map.lidarMarkers,
    showLidarMarkers: state.ui.showLidarMarkers,
    showListView: state.ui.showListView,
    openedMarkers: state.map.openedMarkers,
  };
};

const mapDispatchToProps = {
  onApiLoaded,
  loadPowerlines,
  loadZones,
  setMissionID,
  updateAssignments,
  onMapClick,
  setFilterSelectorStatus,
  updateAnnotatorAssignments,
  clearFilterArea,
  updateMarkers,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps, // @ts-ignore
  mapDispatchToProps // @ts-ignore
)(Component);
