import { ListViewMarker } from "interfaces";

export const listModes = {
  POLES: 1,
  IMAGES: 2,
  PREV_YEAR: 3,
};

export interface IData {
  poleId?: number;
  clientPoleId?: number;
  currentImageIds?: number[];
  imageIds: number[];
  markers: ListViewMarker[];
}

export interface IDataGroup {
  [key: number]: IData;
}

export interface ReviewedImage {
  id: number;
  image_id: number;
  object_type_id: number | null;
  severity_id: number | null;
}

export interface IReviewedImagesGroup {
  [imageId: number]: ReviewedImage["id"][];
}

export interface SocketEventCreatedData {
  action: "created";
  image_id: number;
  created_reviewed_images: ReviewedImage[];
}

export interface SocketEventDeletedData {
  action: "deleted";
  image_id: number;
  deleted_reviewed_image_ids: ReviewedImage["id"][];
}

export type SocketEventData = SocketEventCreatedData | SocketEventDeletedData;
