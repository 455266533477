import { Stack, Divider, Typography, Button } from "@mui/material";
import { useContext } from "react";
import { SummaryContext } from "views/PoleViewer/provider";
import { IPoleStatus } from "views/PoleViewer/api";
import CircleDot from "./CircleDot";
import { getSortedUniqCategories } from "../../utils";

export interface IProps {
  filteredComponents: IPoleStatus[];
}

export default function PoleContentOverview({ filteredComponents }: IProps) {
  const { selectedComponentID, componentClick } = useContext(SummaryContext);

  const sortedCategories = getSortedUniqCategories({
    components: filteredComponents,
  });

  return (
    <>
      {sortedCategories.map((category) => (
        <Stack key={category.id}>
          <Divider sx={{ my: 1, width: "440px" }} />
          {filteredComponents
            .filter((i) => i.category_id === category.id)
            .sort((a, b) => b.sorting - a.sorting)
            .map((item) => (
              <Stack
                key={item.id}
                direction="row"
                alignItems="center"
                sx={{
                  width: "100%",
                  minHeight: "40px",
                  background:
                    selectedComponentID === item.id
                      ? "rgba(254, 247, 221, 0.7)"
                      : "transparent",
                  borderRadius: 1,
                  padding: "4px",
                  transition: "background 0.3s ease",
                }}
              >
                <Typography sx={{ width: 100 }} variant="caption">
                  <Button
                    size="small"
                    onClick={() =>
                      componentClick(
                        selectedComponentID === item.id ? null : item.id
                      )
                    }
                    sx={{
                      color: "black",
                      fontSize: "11px",
                      textTransform: "none",
                      minHeight: "40px",
                    }}
                    fullWidth={true}
                  >
                    {item.name}:
                  </Button>
                </Typography>
                <Stack direction="row" spacing={1} flexWrap={"wrap"}>
                  {item.placements
                    .sort((a, b) => a.id - b.id)
                    .map((status) => (
                      <CircleDot
                        key={status.id}
                        status={status}
                        itemID={item.id}
                      />
                    ))}
                </Stack>
              </Stack>
            ))}
        </Stack>
      ))}
    </>
  );
}
