import { IPoleStatus } from "views/PoleViewer/api";
import { uniqBy } from "lodash";

export interface IProp {
  components: IPoleStatus[];
}

export function getSortedUniqCategories({ components }: IProp) {
  // Get unique categories from the components list
  const uniqueCategories = uniqBy(components, "category_id").map((c) => ({
    id: c?.category_id ?? null,
    name: c?.category_name ?? null,
    sorting: c?.category_sorting ?? null,
  }));

  // Sort the categories
  return uniqueCategories.sort((a, b) => {
    if (a.id === null) return 1; // Move "No Category" to the end
    if (b.id === null) return -1;
    if (a.sorting === null && b.sorting === null) return 0;
    if (a.sorting === null) return -1;
    if (b.sorting === null) return 1;
    return b.sorting - a.sorting;
  });
}
