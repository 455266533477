import { zoomToImage } from "utils/map";

interface ILocation {
  lng: number;
  lat: number;
}

interface IPanToImageOptions {
  mapPanelsRefactor?: boolean;
  viewerIsOpen?: boolean;
}

export function panToImageOnMap(
  gmap: google.maps.Map | null,
  location: ILocation,
  options: IPanToImageOptions
): void {
  // Protect against null gmap
  if (!gmap) return;

  const { mapPanelsRefactor = true, viewerIsOpen = false } = options;
  zoomToImage(gmap, location.lat, location.lng);

  if (!mapPanelsRefactor) {
    const referenceWidth = viewerIsOpen ? 0.8 : 0.4;
    const slit = 1 - referenceWidth;
    const offset = referenceWidth - 0.5;
    const x = offset + slit / 2;
    gmap.panBy(-x * window.innerWidth, 0);
  }
}

export function scrollToImageInThumbnails(imageId: number, attempt = 0) {
  // Only try twice
  if (attempt > 1) return;

  // Find marker in the thumbnail list
  const marker = document.querySelector(
    `.clusterPreviewImage .marker.image-${imageId}`
  );
  const parentNode = marker?.parentNode;
  if (!parentNode) {
    // Try again in 500 ms (when opening the image viewer,
    // the function is sometimes executed before the dom
    // elements are loaded
    setTimeout(() => {
      scrollToImageInThumbnails(imageId, attempt + 1);
    }, 500);
    return;
  }

  // Scroll the node into view
  // @ts-ignore
  parentNode.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "center",
  });
}
