import {
  Box,
  FormControl,
  InputLabel,
  IconButton,
  Stack,
  MenuItem as MuiMenuItem,
  Typography,
  Tooltip,
} from "@mui/material";
import MenuItem from "components/MenuItem";
import { PlayCircle, InfoOutlined } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "hooks";
import {
  getPreviousDefectsPoles,
  setPreviousDefectProject,
} from "state/actions";
import { findCurrentMission } from "utils/utils";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useFlags } from "launchdarkly-react-client-sdk";

export default function PolePreviousDefectFilter() {
  const dispatch = useDispatch();

  const params = useParams();
  // @ts-ignore
  const currentMission: number = parseInt(params.mission) || 0;

  const missions = useSelector((state) => state.user.missions);
  const previousDefectProject = useSelector(
    (state) => state.map.previousDefectProject
  );
  const polesLoading = useSelector((state) => state.map.polesLoading);
  const { refactorMarkersList } = useFlags();

  // https://app.launchdarkly.com/projects/default/flags/refactor-markers-list
  if (refactorMarkersList) {
    return null;
  }

  const selectedMission = findCurrentMission(currentMission, missions);

  if (!selectedMission || !currentMission || !selectedMission?.area?.name) {
    return null;
  }

  const areaMissions = missions
    .filter(
      (mission) =>
        mission.area?.id === selectedMission.area?.id &&
        !mission.deleted &&
        mission.year < selectedMission.year
    )
    .sort((a, b) => (new Date(a.timestamp) < new Date(b.timestamp) ? 1 : -1));

  if (!areaMissions.length) {
    return null;
  }

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(setPreviousDefectProject(parseInt(event.target.value)));
  };

  return (
    <>
      <MenuItem className="section" passive>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ my: 2 }}>
          <Typography>Previous Defects</Typography>
          <Tooltip
            title={
              "Select a previous project and get a list of structures that has unhandled defects from that project on the same structure for the current project."
            }
            placement="right"
            arrow
          >
            <InfoOutlined fontSize="small" />
          </Tooltip>
        </Stack>
      </MenuItem>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <IconButton
          color="primary"
          aria-label="get structures"
          onClick={() => dispatch(getPreviousDefectsPoles(1, true))}
        >
          {polesLoading ? (
            <CircularProgress
              size={20}
              sx={{ marginTop: "2px", marginLeft: "2px" }}
            />
          ) : (
            <PlayCircle />
          )}
        </IconButton>
        <Box sx={{ minWidth: 380 }}>
          <FormControl fullWidth>
            <InputLabel>Previous Project {previousDefectProject}</InputLabel>

            <Select
              value={previousDefectProject?.toString()}
              label=""
              onChange={handleChange}
            >
              {areaMissions.map((mission) => (
                <MuiMenuItem
                  value={mission.id}
                >{`${mission.year} : ${mission.name}`}</MuiMenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </>
  );
}
