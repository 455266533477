import { useEffect } from "react";
import { Delete, StarBorder } from "@mui/icons-material";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { createSavedFilter, deleteSavedFilter } from "api";
import { useSavedFilters } from "hooks/filter/useSavedFilters";
import { useSearchParams } from "react-router-dom";
import translations from "translations";

// Import filter keys for unified control
import { FilterKey } from "hooks/filter/search_parameters/constants";
import { useFilterPlay, useLanguage } from "hooks";

type savedFiltersType = ReturnType<typeof useSavedFilters>["savedFilters"];

function applyKeyToSearchParams(
  searchParams: URLSearchParams,
  key: string,
  value: string
) {
  if (value) searchParams.set(key, value);
  else searchParams.delete(key);
}

function applySavedFiltersToSearchParams(
  searchParams: URLSearchParams,
  filter: savedFiltersType[number]
) {
  applyKeyToSearchParams(
    searchParams,
    FilterKey.DATE_DEFECT_ADDED,
    // @ts-ignore
    filter.dateDefectAdded
  );
  applyKeyToSearchParams(
    searchParams,
    FilterKey.DATE_DEFECT_FIXED,
    // @ts-ignore
    filter.dateDefectFixed
  );
  applyKeyToSearchParams(
    searchParams,
    FilterKey.DATE_DEFECT_REPORTED,
    // @ts-ignore
    filter.dateDefectReported
  );
  applyKeyToSearchParams(
    searchParams,
    FilterKey.DATE_IMAGE_CAPTURE,
    // @ts-ignore
    filter.dateImageCapture
  );
  applyKeyToSearchParams(
    searchParams,
    FilterKey.DATE_IMAGE_UPLOADED,
    // @ts-ignore
    filter.dateImageUploaded
  );
  applyKeyToSearchParams(
    searchParams,
    FilterKey.DATE_DEFECT_PROCESSED,
    // @ts-ignore
    filter.dateDefectProcessed
  );
  // @ts-ignore
  applyKeyToSearchParams(searchParams, FilterKey.SEVERITY, filter.severity);
  // @ts-ignore
  applyKeyToSearchParams(searchParams, FilterKey.LIST, filter.list);
  // @ts-ignore
  applyKeyToSearchParams(searchParams, FilterKey.WORKFLOW, filter.workflow);
  // @ts-ignore
  applyKeyToSearchParams(searchParams, FilterKey.FLIGHT, filter.flight);
  // @ts-ignore
  applyKeyToSearchParams(searchParams, FilterKey.DETECTION, filter.detection);
  // @ts-ignore
  applyKeyToSearchParams(searchParams, FilterKey.FLAGGED, filter.flagged);
  applyKeyToSearchParams(
    searchParams,
    FilterKey.CLEARANCE_AXES,
    // @ts-ignore
    filter.clearanceAxes
  );
  applyKeyToSearchParams(
    searchParams,
    FilterKey.CLEARANCE_RULES,
    // @ts-ignore
    filter.clearanceRules
  );

  // @ts-ignore
  applyKeyToSearchParams(searchParams, FilterKey.DSO_TSO, filter.showDsoTso);
  // @ts-ignore
  applyKeyToSearchParams(searchParams, FilterKey.AREA, filter.area);

  // @ts-ignore
  applyKeyToSearchParams(searchParams, FilterKey.ACTORS, filter.actors);
  applyKeyToSearchParams(
    searchParams,
    FilterKey.SKYQRAFT_HIDDEN,
    // @ts-ignore
    filter.skyqraftHidden
  );
  applyKeyToSearchParams(
    searchParams,
    FilterKey.DEFECT_NOT_REPORTED,
    // @ts-ignore
    filter.defectNotReported
  );
  applyKeyToSearchParams(
    searchParams,
    FilterKey.DEFECT_NOT_PROCESSED,
    // @ts-ignore
    filter.defectNotProcessed
  );
  applyKeyToSearchParams(
    searchParams,
    FilterKey.FEED_BAY,
    // @ts-ignore
    filter.feedBay
  );
  // Always apply the filter when it is activated
  applyKeyToSearchParams(
    searchParams,
    FilterKey.FILTER_ACTIVE,
    // @ts-ignore
    "true"
  );
}

function FilterItem({
  filter,
  savedFilters,
  updateSavedFilters,
}: {
  filter: ReturnType<typeof useSavedFilters>["savedFilters"][number];
  savedFilters: savedFiltersType;
  updateSavedFilters: () => void;
}) {
  const { language } = useLanguage();
  const [searchParams, setSearchParams] = useSearchParams();

  const matchesCurrentFilter = Object.keys(filter).every((key) => {
    if (key === "name" || key === "id") return true;
    // @ts-ignore
    return filter[key] === searchParams.get(key);
  });

  function deleteFilter(filter_id: number) {
    const confirmation = window.confirm(
      translations.Filter.DeleteConfirmationFilter[language]
    );
    if (!confirmation) return;
    deleteSavedFilter(filter_id).then(updateSavedFilters);
  }

  function applyFilter(filter: savedFiltersType[number]) {
    applySavedFiltersToSearchParams(searchParams, filter);
    setSearchParams(searchParams);
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        background: matchesCurrentFilter ? "#DDEEFF" : "inherit",
      }}
    >
      <Stack direction="row" alignItems="center">
        <Button onClick={() => applyFilter(filter)}>
          {translations.Filter.Activate[language]}
        </Button>
        <Typography>{filter.name}</Typography>
      </Stack>
      <IconButton onClick={() => deleteFilter(filter.id)}>
        <Delete />
      </IconButton>
    </Stack>
  );
}

export default function SavedFilters() {
  const [searchParams] = useSearchParams();
  const { filterPopulated } = useFilterPlay();
  const { savedFilters, setTabActive, update } = useSavedFilters();
  const { language } = useLanguage();
  useEffect(() => {
    setTabActive(true);
  }, []);

  function saveCurrentFilter() {
    const filterName = window.prompt(
      translations.Filter.EnterNameForFilter[language]
    );
    if (!filterName) return;
    const search = new URLSearchParams(searchParams);
    // Convert searchParams and filerName to an object
    const filter = {
      name: filterName,
      ...Object.fromEntries(search),
    };
    // Save the filter
    createSavedFilter(filter).then(update);
  }

  return (
    <Stack>
      {savedFilters.length === 0 ? (
        <Stack ml={2}>
          <Typography color="text.secondary">
            {translations.Filter.NoSavedFilters[language]}
          </Typography>
          {filterPopulated ? (
            <Stack direction="row" alignItems="center">
              <Typography color="text.secondary">
                {translations.Filter.PressStarToSave[language]}
              </Typography>
              <IconButton
                onClick={() => {
                  saveCurrentFilter();
                }}
              >
                <StarBorder htmlColor="#e5e100" />
              </IconButton>
            </Stack>
          ) : (
            <Typography color="text.secondary">
              {translations.Filter.SelectFilterToSave[language]}
            </Typography>
          )}
        </Stack>
      ) : (
        savedFilters
          .sort((fa, fb) => (fa.name < fb.name ? 1 : -1))
          .map((filter) => (
            <FilterItem
              filter={filter}
              savedFilters={savedFilters}
              updateSavedFilters={update}
            />
          ))
      )}
    </Stack>
  );
}
