import { useState, useEffect } from "react";
import { authorizedGet } from "utils/request";

export interface IProject {
  id: number;
  name: string;
  year: number;
  type: {
    id: number;
    name: string;
  };
  responsible_annotator: number;
  deleted: boolean;
  fixable_defects: boolean;
  processable_defects: boolean;
  timestamp: string;
  status: {
    id: number;
    name: string;
  };
  region: {
    id: number;
    name: string;
  };
  area?: {
    id: number;
    name: string;
    group_id: number;
  };
  date_order_placed: string;
  drone_operator: string;
  date_flight: string;
  image_analysis: boolean;
  thermal_analysis: boolean;
  corrosion_analysis: boolean;
  lidar_analysis: boolean;
  order_comment: string;
  creator: {
    id: number;
    name: string;
    email: string;
  };
  date_order_accepted: string;
  contract_number: string;
  invoice_reference: string;
  detailed_steelwork: boolean;
}

interface IGroup {
  id: number;
  name: string;
  regions: {
    id: number;
    name: string;
  }[];
  projects: IProject[];
}

/**
 * A React hook that collects groups
 *
 *  @example
 *  ```javascript
 *  const [group, updateGroup, loading] = useGroup(25);
 *  ```
 */
export function useGroup(groupID: number): [IGroup, Function, boolean] {
  const [group, setGroup] = useState<IGroup | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  function updateGroup() {
    collectGroup(groupID);
  }

  function collectGroup(groupID: number) {
    let endpoint = `/admin/group/${groupID}`;
    setLoading(true);
    authorizedGet<IGroup>(endpoint)
      .then((response) => {
        setLoading(false);
        setGroup(response);
      })
      .catch((err) => {
        setLoading(false);
        setGroup(null);
        console.error("Failed to collect group", err);
      });
  }

  useEffect(() => {
    collectGroup(groupID);
  }, [groupID]);
  // @ts-ignore
  return [group, updateGroup, loading];
}
