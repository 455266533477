import { ReactNode, createContext, useState, useEffect } from "react";
import {
  updatePoleComponent,
  getAvailablePoleComponents,
  IPoleComponent,
  deletePoleComponent,
  addPoleComponent,
  getPoleValues,
  IPoleSystem,
  updatePoleComponentPlacement,
  deletePoleComponentPlacement,
  addPoleComponentPlacement,
  getPoleComponentCategories,
  IPoleComponentCategory,
} from "views/PoleViewer/api";
import { Outlet } from "react-router-dom";
import { RootState } from "state/reducers";

interface IContextValue {
  componentCategories: IPoleComponentCategory[];
  components: IPoleComponent[];
  valueSystems: IPoleSystem[];
  loading: boolean;
  updateComponent: (params: {
    componentID: number;
    name?: string | null;
    valueSystemID?: number | null;
    categoryID?: number | null;
    sorting?: number | null;
  }) => Promise<void>;
  deleteComponent: (componentID: number) => Promise<void>;
  createComponent: (params: {
    name: string;
    gradingSystemID: number;
    categoryID?: number | null;
    sorting?: number | null;
  }) => Promise<number>;
  updatePlacement: (
    componentID: number,
    placementID: number,
    name?: string,
    regexPattern?: string
  ) => Promise<void>;
  deletePlacement: (componentID: number, placementID: number) => Promise<void>;
  createPlacement: (
    componentID: number,
    name: string,
    regexPattern?: string
  ) => Promise<number>;
  group: RootState["group"]["groups"][number] | null;
  setGroup: (group: RootState["group"]["groups"][number] | null) => void;
  project: RootState["user"]["missions"][number] | null;
  setProject: (project: RootState["user"]["missions"][number] | null) => void;
}

// Set up a context that is importable
const context = createContext<IContextValue>({
  componentCategories: [],
  components: [],
  valueSystems: [],
  loading: false,
  updateComponent: async () => {},
  deleteComponent: async () => {},
  createComponent: async () => 0,
  updatePlacement: async () => {},
  deletePlacement: async () => {},
  createPlacement: async () => 0,
  group: null,
  setGroup: () => null,
  project: null,
  setProject: () => null,
});

interface IProps {
  children?: ReactNode;
}

function ComponentOptionsProvider({ children }: IProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [components, setComponents] = useState<IPoleComponent[]>([]);
  const [valueSystems, setValueSystems] = useState<IPoleSystem[]>([]);
  const [componentCategories, setComponentCategories] = useState<
    IPoleComponentCategory[]
  >([]);
  const [group, setGroup] = useState<
    RootState["group"]["groups"][number] | null
  >(null);
  const [project, setProject] = useState<
    RootState["user"]["missions"][number] | null
  >(null);
  // Define the shared state

  async function updateValueSystems(groupID: number | null) {
    // @ts-ignore
    const systems = await getPoleValues(groupID);
    setValueSystems(systems);
  }

  async function updateComponentCategories(groupID: number | null) {
    const categories = await getPoleComponentCategories(groupID);
    setComponentCategories(categories);
  }

  async function updateComponents(
    groupID: number | null,
    projectId: number | null
  ) {
    setLoading(true);
    const components = await getAvailablePoleComponents(groupID, projectId);
    setComponents(components);
    setLoading(false);
  }

  async function updateComponent({
    componentID,
    name = null,
    valueSystemID = null,
    categoryID = null,
    sorting = null,
  }: {
    componentID: number;
    name?: string | null;
    valueSystemID?: number | null;
    categoryID?: number | null;
    sorting?: number | null;
  }) {
    await updatePoleComponent({
      componentID,
      name,
      valueSystemID,
      group_id: group?.id,
      categoryID,
      sorting,
    });
    await updateComponents(group?.id ?? null, project?.id ?? null);
  }

  async function deleteComponent(componentID: number) {
    await deletePoleComponent(componentID);
    await updateComponents(group?.id ?? null, project?.id ?? null);
  }

  async function createComponent({
    name,
    gradingSystemID,
    categoryID = null,
    sorting = null,
  }: {
    name: string;
    gradingSystemID: number;
    categoryID?: number | null;
    sorting?: number | null;
  }) {
    const componentID = await addPoleComponent({
      name,
      gradingSystem: gradingSystemID,
      group_id: group?.id,
      categoryID,
      sorting,
    });
    await updateComponents(group?.id ?? null, project?.id ?? null);
    return componentID;
  }

  async function updatePlacement(
    componentID: number,
    placementID: number,
    name: string | null = null,
    regexPattern: string | null = null
  ) {
    await updatePoleComponentPlacement(
      componentID,
      placementID,
      project?.id,
      name,
      regexPattern,
      group?.id
    );
    await updateComponents(group?.id ?? null, project?.id ?? null);
  }

  async function deletePlacement(componentID: number, placementID: number) {
    await deletePoleComponentPlacement(componentID, placementID);
    await updateComponents(group?.id ?? null, project?.id ?? null);
  }

  async function createPlacement(
    componentID: number,
    name: string,
    regexPattern: string | null = null
  ) {
    if (regexPattern && project?.id) {
      const placementID = await addPoleComponentPlacement(
        componentID,
        name,
        group?.id ?? null,
        project.id,
        regexPattern
      );
      await updateComponents(group?.id ?? null, project?.id);
      return placementID;
    } else {
      const placementID = await addPoleComponentPlacement(
        componentID,
        name,
        group?.id ?? null
      );
      await updateComponents(group?.id ?? null, project?.id ?? null);
      return placementID;
    }
  }

  useEffect(() => {
    updateComponents(group?.id ?? null, project?.id ?? null);
    updateValueSystems(group?.id ?? null);
    updateComponentCategories(group?.id ?? null);
  }, [group, project]);

  // Build the payload to all consumers
  const payload: IContextValue = {
    componentCategories,
    components,
    valueSystems,
    loading,
    updateComponent,
    deleteComponent,
    createComponent,
    updatePlacement,
    deletePlacement,
    createPlacement,
    group,
    setGroup,
    project,
    setProject,
  };

  // Render the context provider
  return (
    <context.Provider value={payload}>
      {children ? children : <Outlet />}
    </context.Provider>
  );
}

export default ComponentOptionsProvider;
export { context };
export type { IContextValue };
