import React from "react";
import styled from "styled-components";

interface IItemBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  active: boolean;
  childItem?: boolean;
}

export const ItemBox = styled.div<IItemBoxProps>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;
	padding: 5px 10px 5px 15px;
	margin-bottom: 1px;
	font-size: 14px;
	cursor: pointer;

	&:hover {
		${(props) => (props.active ? "" : "background-color: #f0f0f0;")}
	}
`;

export const ImageBox = styled(ItemBox)`
	${(props) => props.active && "p { font-weight: bold; }"}

	${(props) => props.childItem && "padding-left: 30px;"}

	${(props) =>
    props.active && !props.childItem ? "background-color: #FFE893;" : ""}

	${(props) =>
    props.active && props.childItem ? "background-color: #FEF7DD;" : ""}
`;

export const PoleBox = styled(ItemBox)`
	${(props) => props.active && "background-color: #FFE893;"}
`;

interface IItemStartProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  viewed?: boolean;
}

export const ItemStart = styled.div<IItemStartProps>`
	display: flex;
	align-items: center;
	font-weight: bold;

	p {
		padding-left: 10px;
		padding-right: 5px;
		margin: 0;
	}
	svg:last-child {
		width: 0.75em;
    height: 0.75em;
	}

	${(props) => props.viewed && "color: #0F9E43; svg { fill: #0F9E43; }"}
`;

export const ItemEnd = styled.div`
	display: flex;
	align-items: center;
`;

export const SeverityCount = styled.div`
	min-width: 25px;
	padding: 3px 5px;
	box-sizing: border-box;
	border-radius: 50%;
	text-align: center;
	color: #FFF;
	background-color: #9A9E9F;
	margin-left: 5px;
`;
