import { useContext, ReactNode, useEffect } from "react";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import ItemColumnButton from "../ItemColumnButton";
import ItemColumn from "../ItemColumn";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { context as CategoryContext } from "./CategoryOptionsProvider";
import Loading from "views/Loading";
import Select from "react-select";
import { RootState } from "state/store";
import { useSelector } from "hooks";

type Props = {
  children?: ReactNode;
};

type paramTypes = Record<"category_id", string>;

export default function CategoryOptions({ children }: Props) {
  const { loading, componentCategories, group, setGroup } =
    useContext(CategoryContext);
  const params = useParams<paramTypes>();

  const categoryID = params.category_id ? parseInt(params.category_id) : null;
  const groups = useSelector((state) => state.group.groups);

  const projects = useSelector((state: RootState) => state.user.missions);
  const missionID = useSelector((state: RootState) => state.mission.id);

  const skyqraft_employee = useSelector(
    (state: RootState) => state.user.skyqraft_employee
  );

  const editAccess = !group?.access_levels?.includes("readonly");

  const navigate = useNavigate();

  function updateGroup(group: RootState["group"]["groups"][number] | null) {
    setGroup(group);
    // Unset category id when changing group
    if (categoryID) {
      navigate("/options/pole/status/category/");
    }
  }

  useEffect(() => {
    if (groups.length > 0) {
      const missionGroup = projects.find((project) =>
        project.id === missionID ? project.group : null
      );
      const groupId = missionGroup?.group?.id;

      if (groupId) {
        updateGroup(groups.find((g) => g.id === groupId) ?? null);
      } else if (!skyqraft_employee) {
        updateGroup(groups[0]); // fallback to first available group if not internal user (then global is used)
      }
    }
  }, [groups, missionID, projects]);

  return (
    <Box>
      {loading && (
        <Stack
          width={"100%"}
          p={2}
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Loading relative size={60} color="black" />
          <Typography>Loading categories...</Typography>
        </Stack>
      )}
      <Typography variant="h5">Select Customer</Typography>
      <Typography mb={1}>
        Structure templates are customer specific. Please select a customer
        first.
      </Typography>
      <Select
        options={[
          ...(skyqraft_employee
            ? [
                {
                  value: null,
                  label: "Global",
                },
              ]
            : []),
          ...groups.map((g) => ({ value: g.id, label: g.name })),
        ]}
        value={
          group
            ? {
                value: group.id,
                label: group.name,
              }
            : {
                value: null,
                label: skyqraft_employee ? "Global" : "Select a customer",
              }
        }
        onChange={(e) => {
          updateGroup(groups.find((g) => g.id === e?.value) ?? null);
        }}
      />
      <Divider sx={{ my: 2 }} />
      {!loading && (
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem sx={{ mx: 2 }} />}
        >
          <ItemColumn
            title="Categories"
            description="A category for a component"
            {...(editAccess && {
              addLink: "/options/pole/status/category/add",
            })}
            dataTestID="category-list"
          >
            {componentCategories
              .filter(
                (category) =>
                  category.group_id === group?.id ||
                  (category.group_id === null &&
                    group === null &&
                    skyqraft_employee)
              )
              .map((category) => (
                <ItemColumnButton
                  key={category.id}
                  link={`/options/pole/status/category/${category.id}`}
                  active={category.id === categoryID}
                >
                  {category.name}
                </ItemColumnButton>
              ))}
          </ItemColumn>

          {editAccess ? children ? children : <Outlet /> : null}
        </Stack>
      )}
    </Box>
  );
}
