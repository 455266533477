import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { captureException, EventHint } from "@sentry/react";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import { ListViewMarker, Mission } from "interfaces";
import {
  useSkyqraftHiddenFilter,
  useSelector,
  useDispatch,
  useSocket,
  useSeverityFilter,
  useObjectTypeFilter,
} from "hooks";
import {
  setPreviousDefectImages,
  setSelectedPreviousDefectImage,
} from "state/actions";
import {
  listModes,
  IDataGroup,
  IData,
  ReviewedImage,
  IReviewedImagesGroup,
  SocketEventData,
} from "./constants";
import {
  getMarkers,
  checkIfImageExistsInData,
  fetchReviewedImages,
  postReviewedImage,
  deleteReviewedImage,
} from "./utils";

export const useReviewedImages = (args: {
  projectId?: number;
  access: boolean;
  filterActive: boolean;
}) => {
  const { projectId, access, filterActive } = args;

  const [loading, setLoading] = useState(false);
  const [reviewedImages, setReviewedImages] = useState<ReviewedImage[]>([]);
  const [reviewedImagesGroup, setReviewedImagesGroup] =
    useState<IReviewedImagesGroup>({});

  const { onEvent, removeListeners } = useSocket();
  const { severityFilter: severityIds } = useSeverityFilter();
  const { objectTypeFilter: objectTypeIds } = useObjectTypeFilter();
  const location = useLocation();
  const disabled = !projectId || loading || !access;

  const updateReviewedImages = async () => {
    if (disabled) return;

    if (!filterActive) {
      setReviewedImages([]);
      return;
    }

    setLoading(true);
    setReviewedImages([]);

    try {
      const reviewedImages = await fetchReviewedImages({
        projectId,
        severityIds,
        objectTypeIds,
      });

      setReviewedImages(reviewedImages);
    } catch (error) {
      console.error(error);
      const exceptionHint: EventHint = {
        event_id: "list.useReviewedImages.fetchReviewedImages",
        originalException: error,
        data: {
          projectId,
          severityIds,
          objectTypeIds,
        },
      };
      captureException(error, exceptionHint);
      toast.error("Failed to load image reviewed states");
    }
    setLoading(false);
  };

  const addReviewedImage = async (imageId: number) => {
    if (disabled) return;

    try {
      const { data: createdReviewedImages } = await postReviewedImage({
        projectId,
        imageId,
        severityIds,
        objectTypeIds,
      });

      setReviewedImages([...reviewedImages, ...createdReviewedImages]);
    } catch (error) {
      console.error(error);
      const exceptionHint: EventHint = {
        event_id: "list.useReviewedImages.postReviewedImage",
        originalException: error,
        data: {
          projectId,
          imageId,
          severityIds,
          objectTypeIds,
        },
      };
      captureException(error, exceptionHint);
      toast.error("Failed to set image reviewed state");
    }
  };

  const removeReviewedImage = async (imageId: number) => {
    if (disabled) return;

    const ids = reviewedImagesGroup[imageId];

    try {
      await deleteReviewedImage({
        projectId,
        imageId,
        ids,
      });

      setReviewedImages(reviewedImages.filter((ri) => ri.image_id !== imageId));
    } catch (error) {
      console.error(error);
      const exceptionHint: EventHint = {
        event_id: "list.useReviewedImages.deleteReviewedImage",
        originalException: error,
        data: {
          projectId,
          imageId,
          ids,
        },
      };
      captureException(error, exceptionHint);
      toast.error("Failed to unset image reviewed state");
    }
  };

  useEffect(() => {
    updateReviewedImages();
  }, [projectId, location.search, filterActive]);

  useEffect(() => {
    const reviewedImagesGroup = reviewedImages.reduce((acc, reviewedImage) => {
      if (!acc[reviewedImage.image_id]) {
        acc[reviewedImage.image_id] = [];
      }
      acc[reviewedImage.image_id].push(reviewedImage.id);
      return acc;
    }, {} as IReviewedImagesGroup);

    setReviewedImagesGroup(reviewedImagesGroup);
  }, [reviewedImages]);

  useEffect(() => {
    const socketEvent = "image_review.image_reviewed";
    const socketKey = "list.image_reviewed";
    // @ts-ignore
    onEvent(socketEvent, socketKey, (data: SocketEventData) => {
      if (data.action === "created") {
        const matchingReviewedImages = data.created_reviewed_images.filter(
          (cri) => {
            // Skip if image already exists in state
            if (reviewedImagesGroup[cri.image_id]?.includes(cri.id)) {
              return false;
            }
            // Null means that no filtering has been used and therefor applies to all
            const severityMatches =
              cri.severity_id === null || severityIds.includes(cri.severity_id);

            const objectTypeMatches =
              cri.object_type_id === null ||
              objectTypeIds.includes(cri.object_type_id);

            // Both severity and object type matches current filters
            return severityMatches && objectTypeMatches;
          }
        );

        if (matchingReviewedImages.length) {
          setReviewedImages([...reviewedImages, ...matchingReviewedImages]);
        }
      }
      if (data.action === "deleted") {
        setReviewedImages(
          reviewedImages.filter(
            (ri) => !data.deleted_reviewed_image_ids.includes(ri.id)
          )
        );
      }
    });

    return () => {
      removeListeners(socketKey);
    };
  }, [severityIds, objectTypeIds, reviewedImagesGroup]);

  return {
    reviewedImagesGroup,
    loadingReviewedImages: loading,
    updateReviewedImages,
    addReviewedImage,
    removeReviewedImage,
  };
};

export type ReviewedImagesHookTypes = ReturnType<typeof useReviewedImages>;

export const useFetchMarkers = (args: {
  projectId?: number;
  setData: (data: ListViewMarker[]) => void;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  access: boolean;
  filterActive: boolean;
}): { updateData: () => void } => {
  const { projectId, setData, setLoading, loading, access, filterActive } =
    args;

  const location = useLocation();
  const { skyqraftHidden } = useSkyqraftHiddenFilter();

  const updateData = async () => {
    if (!projectId || loading || !access) return;

    if (!filterActive) {
      setData([]);
      return;
    }

    setLoading(true);
    setData([]);

    try {
      const markers = await getMarkers({
        projectId,
        skyqraftHidden,
      });

      if (markers?.length) {
        setData(markers.sort((a, b) => a.id - b.id));
      }
    } catch (error) {
      console.error(error);
      const exceptionHint: EventHint = {
        event_id: "list.useFetchMarkers.getMarkers",
        originalException: error,
        data: {
          projectId,
          skyqraftHidden,
        },
      };
      captureException(error, exceptionHint);
      toast.error("Failed to load list");
    }
    setLoading(false);
  };

  useEffect(() => {
    updateData();
  }, [projectId, skyqraftHidden, location.search, filterActive]);

  return { updateData };
};

export const useNavigation = (args: {
  dataGroup: IDataGroup;
  dataKeys: number[];
  prevProject?: Mission;
  listMode: number;
  imageId?: number;
  urlImageId: number;
}) => {
  const { dataGroup, dataKeys, prevProject, listMode, imageId, urlImageId } =
    args;

  const [dataIndex, setDataIndex] = useState(-1);

  const currentImageMeta = useSelector((state) => state.image.current);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const projectId = params.mission;
  const currentDataGroup = dataGroup[dataKeys[dataIndex]];

  // Reset state on unmount
  useEffect(
    () => () => {
      dispatch(setPreviousDefectImages([]));
      dispatch(setSelectedPreviousDefectImage(null));
    },
    []
  );

  // Update dataIndex when imageId changes in url
  useEffect(() => {
    const newDataIndex = dataKeys.findIndex((key) => {
      return checkIfImageExistsInData({
        data: dataGroup[key],
        imageId: urlImageId,
        currentImageMeta,
      });
    });

    // If we can find the index quickly before loading currentImageMeta then thats good
    // If not fallback and try to use currentImageMeta before clearing the index with -1
    if (newDataIndex === -1 && currentImageMeta?.id !== urlImageId) {
      return;
    }
    if (dataIndex !== newDataIndex) {
      setDataIndex(newDataIndex);
    }
  }, [urlImageId, dataKeys, dataIndex, currentImageMeta, listMode]);

  const goToNextImage = () => {
    if (!dataKeys.length) return;

    if (!currentDataGroup) {
      // Go to first image if noting is selected and user navigates down
      return goToImage(dataGroup[dataKeys[0]], 0);
    }

    const imageIndex = currentDataGroup.imageIds.findIndex(
      (id) => id === imageId
    );

    if (imageIndex < currentDataGroup.imageIds.length - 1) {
      return goToImage(currentDataGroup, imageIndex + 1);
    }
    if (dataKeys.length > dataIndex + 1) {
      // Go to first image in next group
      goToImage(dataGroup[dataKeys[dataIndex + 1]], 0);
    }
  };

  const goToPreviousImage = () => {
    if (!dataKeys.length || !currentDataGroup) return;

    const imageIndex = currentDataGroup.imageIds.findIndex(
      (id) => id === imageId
    );

    if (imageIndex > 0) {
      return goToImage(currentDataGroup, imageIndex - 1);
    }
    if (dataKeys.length > 0) {
      // Go to last image in prev group
      goToImage(
        dataGroup[dataKeys[dataIndex - 1]],
        dataGroup[dataKeys[dataIndex - 1]].imageIds.length - 1
      );
    }
  };

  const debouncedGoToPreviousImage = debounce(goToPreviousImage, 200);
  const debouncedGoNextImage = debounce(goToNextImage, 200);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 38) {
        // up arrow key
        event.preventDefault();
        debouncedGoToPreviousImage();
      } else if (event.keyCode === 40) {
        // down arrow key
        event.preventDefault();
        debouncedGoNextImage();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      // remove the event listener when the component unmounts
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [debouncedGoToPreviousImage, debouncedGoNextImage]);

  const goToImage = (data: IData, imageIndex: number) => {
    let newImageId = data.imageIds[imageIndex];

    if (listMode === listModes.PREV_YEAR) {
      if (!prevProject || !data.currentImageIds) return;

      newImageId = data.currentImageIds[0];

      const formattedMarkers = data.markers.map((m) => ({
        id: m.id,
        src: m.src,
        compass_dir: m.compass_dir,
        name: m.name,
        project: prevProject.id,
      }));

      dispatch(setPreviousDefectImages(formattedMarkers));
      dispatch(setSelectedPreviousDefectImage(formattedMarkers[imageIndex]));
    }

    if (location.pathname.includes("/annotate")) {
      navigate(`/${projectId}/${newImageId}/annotate${location.search}`);
    } else {
      navigate(`/${projectId}/${newImageId}${location.search}`);
    }
  };

  return {
    goToNextImage: debouncedGoNextImage,
    goToPreviousImage: debouncedGoToPreviousImage,
    goToImage,
    dataIndex,
  };
};
