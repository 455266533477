import { unstable_useBlocker } from "react-router-dom";
import { useEffect, useRef } from "react";

interface Props {
  enableBlocker: boolean;
  confirmationMessage: string;
}
interface Return {
  assertExit: () => boolean;
}

export function useNavigationBlocker(props: Props): Return {
  const { enableBlocker, confirmationMessage } = props;

  const unloadEventHandlerRef = useRef(null);
  const disableRouteAssert = useRef(false);

  const assertExit = () => {
    if (!enableBlocker || disableRouteAssert.current) {
      return true;
    }
    if (window.confirm(confirmationMessage)) {
      disableRouteAssert.current = true;
      return true;
    }
    return false;
  };

  unstable_useBlocker(({ currentLocation, nextLocation }) => {
    //Only block if the pathnames are different.
    //This is to avoid triggering the blocker if only search params change (e.g. boxIds)
    const currentPath = currentLocation.pathname;
    const nextPath = nextLocation.pathname;
    return currentPath === nextPath ? false : !assertExit(); // return true to block route navigation
  });

  useEffect(() => {
    // @ts-ignore
    unloadEventHandlerRef.current = (e) => {
      if (enableBlocker) {
        e.preventDefault(); // triggers the browser confirm dialog
      }
    };
    // handles both reload and closing of tab
    // @ts-ignore
    window.addEventListener("beforeunload", unloadEventHandlerRef.current);

    return () => {
      // @ts-ignore
      window.removeEventListener("beforeunload", unloadEventHandlerRef.current);
    };
  }, [enableBlocker]);

  useEffect(() => {
    return () => {
      // @ts-ignore
      window.removeEventListener("beforeunload", unloadEventHandlerRef.current);
    };
  }, []);

  return {
    assertExit,
  };
}
