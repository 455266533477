import { Slider } from "@mui/material";
import translations from "translations";
import { useLanguage, useSelector, useDispatch } from "hooks";
import { setZoomSpeed } from "state/actions";
import SettingsContainer from "../SettingsContainer";

interface Props {
  size: number;
}

export default function ZoomSpeed({ size }: Props) {
  const zoomSpeed = useSelector((state) => state.imageViewer.zoomSpeed);
  const dispatch = useDispatch();
  const { language } = useLanguage();
  // @ts-ignore
  function onChange(e, value) {
    dispatch(setZoomSpeed(value));
  }

  return (
    <SettingsContainer
      size={size}
      title={translations.Menu.Settings.ZoomSpeed[language]}
    >
      <Slider
        aria-label="Volume"
        value={zoomSpeed}
        valueLabelDisplay="auto"
        onChange={onChange}
        min={1}
        max={100}
        style={{ width: "100%", marginLeft: 20, marginRight: 5 }}
      />
    </SettingsContainer>
  );
}
