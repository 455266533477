import React, { useEffect } from "react";
import { MapOutlined, FormatListBulleted } from "@mui/icons-material";
import styled from "styled-components";
import "./style.scss";
import { Tooltip } from "@mui/material";
import { useSearchParams, useLocation } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isFilterActive } from "utils/filter";
import { useLanguage, useSelector, useDispatch, useLocalStorage } from "hooks";
import { setListView, setShowMap } from "state/actions";
import translations from "./translations";

export default function MapListButton() {
  const { language } = useLanguage();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const filterActive = isFilterActive(searchParams);

  const showListView = useSelector((state) => state.ui.showListView);
  const showMap = useSelector((state) => state.ui.showMap);

  const dispatch = useDispatch();

  // https://app.launchdarkly.com/projects/default/flags/refactor-markers-list
  // https://app.launchdarkly.com/projects/default/flags/map-panels-refactor
  const { refactorMarkersList, mapPanelsRefactor } = useFlags();

  useEffect(() => {
    if (refactorMarkersList) return;

    !filterActive && dispatch(setListView(false));
  }, [filterActive, refactorMarkersList]);

  useLocalStorage({
    data: showMap,
    key: "toggles.showMap",
    onLoad: (data) => dispatch(setShowMap(data)),
  });

  if (location.pathname.includes("/landing")) {
    return null;
  }

  if (mapPanelsRefactor) {
    return (
      <Container>
        <Tooltip
          title={
            showMap
              ? translations.MapToggleDisable[language]
              : translations.MapToggleEnable[language]
          }
          placement="bottom"
          arrow
        >
          <Button
            active={showMap}
            onClick={() => dispatch(setShowMap(!showMap))}
          >
            <MapOutlined />
          </Button>
        </Tooltip>
        <Tooltip
          title={
            showListView
              ? translations.ListToggleDisable[language]
              : translations.ListToggleEnable[language]
          }
          placement="bottom"
          arrow
        >
          <Button
            active={showListView}
            onClick={() => dispatch(setListView(!showListView))}
            data-testid="statusBar.listToggle"
          >
            <FormatListBulleted />
          </Button>
        </Tooltip>
      </Container>
    );
  }

  return (
    <div
      className="mapListButtonContainer"
      style={{
        // @ts-ignore
        opacity:
          (Boolean(!filterActive && !refactorMarkersList) ||
            location.pathname.includes("/landing")) &&
          0.5,
      }}
    >
      <div className="mapListButtonWrapper" data-testid="statusBar.listToggle">
        <Tooltip
          title={
            Boolean(!filterActive && !refactorMarkersList) &&
            translations.ListView.ActivateFilter[language]
          }
          placement="right"
          arrow
        >
          <div
            className="mapListButton"
            onClick={() =>
              Boolean(filterActive || refactorMarkersList) &&
              !location.pathname.includes("/landing") &&
              dispatch(setListView(!showListView))
            }
          >
            <div
              className={
                showListView ? "mapListElement" : "mapListElement active"
              }
            ></div>

            <MapOutlined
              htmlColor="white"
              style={{ zIndex: 1, marginRight: "2px" }}
            />

            <FormatListBulleted
              htmlColor="white"
              style={{ zIndex: 1, marginRight: "-3px" }}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

export const Container = styled.div`
	display: flex;
	align-items: center;
`;

interface IButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  active: boolean;
}
export const Button = styled.div<IButtonProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	cursor: pointer;

	&:first-child {
		margin-right: 5px;
	}

	svg {
		color: #fff;
	}

	${(props) =>
    props.active ? "background-color: #0078EB;" : "background-color: #D9D9D9;"}
`;
