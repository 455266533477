import { captureException, EventHint } from "@sentry/react";
import { useState, useEffect } from "react";
import { axiosInstance } from "utils/request";
import z from "zod";
import { SingleSignOnProvider } from "authentication/SingleSignOn/useSingleSignOnConfigs";

const UserSchema = z.object({
  id: z.number(),
  name: z.string(),
  email: z.string(),
  visitor: z.boolean(),
  employee: z.boolean(),
  sso: z
    .object({
      name: z.string(),
      provider: SingleSignOnProvider,
    })
    .nullable(),
});

export function useUserAdmin() {
  const [users, setUsers] = useState<z.infer<typeof UserSchema>[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const endpoint = "/admin/user";

  async function collectUsers() {
    setLoading(true);
    try {
      const response = await axiosInstance.get(endpoint);
      const data = response.data;
      const parsedData = z.array(UserSchema).parse(data);
      setUsers(parsedData);
    } catch (error) {
      console.error("Error fetching users", error);
      const hint: EventHint = {
        event_id: "userAdmin.hook.useUserAdmin",
      };
      captureException(error, hint);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    collectUsers();
  }, []);

  return { users, collectUsers, loading };
}
