import { useTranslation } from "hooks/settings";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useCookie } from "react-use";

export function useFilterCookie() {
  const { saveAndRestoreFiltersFromCookie } = useFlags();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterCookie, setFilterCookie, removeCookie] = useCookie("filter");

  // Restore the filter from the cookie.
  const applyFilterTranslation = useTranslation(
    "ApplyFilterFromPreviousSession"
  );
  useEffect(() => {
    if (
      filterCookie &&
      searchParams.size === 0 &&
      saveAndRestoreFiltersFromCookie &&
      window.confirm(applyFilterTranslation)
    ) {
      const searchParams = new URLSearchParams(filterCookie);
      setSearchParams(searchParams);
    }
  }, []); // Only when first loading.
  // Save the current filter to the cookie.
  useEffect(() => {
    if (!saveAndRestoreFiltersFromCookie) {
      return;
    }

    const searchString = searchParams.toString();

    setFilterCookie(searchString, {
      sameSite: "lax", // Allow us to use this for lidar.
      secure: true,
      expires: 0.5, // 12 hours in days.
    });
  }, [searchParams]);

  useEffect(() => {
    if (!saveAndRestoreFiltersFromCookie) {
      removeCookie();
    }
  }, [saveAndRestoreFiltersFromCookie]);
}
