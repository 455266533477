import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Microsoft } from "@mui/icons-material";
import { useState } from "react";

import type { AzureDataType } from "./types";
import { EmailAlreadyExistsError } from "./errors";
import { azureLogin } from "./utils";

// Interface for component props
interface IProps extends AzureDataType {
  onLogin: (value: boolean) => void;
  label: string;
  hidden: boolean;
  id: number;
}

export default function AzureLoginButton({
  onLogin,
  clientId,
  authority,
  redirectUri,
  label,
  hidden,
  id,
}: IProps): JSX.Element {
  const [overridePopupOpen, setOverridePopupOpen] = useState(false);

  const azureData: AzureDataType = {
    clientId,
    authority,
    redirectUri,
  };

  /**
   * Handles the click event for the login button.
   * @param {boolean} override - Whether to override the existing account.
   */
  async function onClick(override = false) {
    try {
      await azureLogin(azureData, override, id);
    } catch (err) {
      if (err === EmailAlreadyExistsError) {
        setOverridePopupOpen(true);
        return;
      }
      throw err;
    }

    onLogin(true);
  }

  return (
    <Box
      sx={{
        maxHeight: hidden ? 0 : "40px", // Use maxHeight instead of height
        overflow: "hidden",
        transition: "max-height 0.2s linear", // Corrected transition property
        flex: 1,
        width: "100%",
      }}
    >
      <Button
        sx={{
          position: "relative",
          justifyContent: "center",
          textAlign: "center",
          width: "100%",
          mb: hidden ? 0 : 1,
        }}
        variant="outlined"
        fullWidth
        onClick={() => onClick(false)}
      >
        <Microsoft
          sx={{
            position: "absolute",
            left: 10,
          }}
        />
        {label}
      </Button>
      <Dialog
        open={overridePopupOpen}
        onClose={() => setOverridePopupOpen(false)}
      >
        <DialogTitle>Email already exists.</DialogTitle>
        <DialogContent>
          Do you want to convert your existing account to an {label} account?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOverridePopupOpen(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              onClick(true);
              setOverridePopupOpen(false);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
