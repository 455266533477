import translations from "translations";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { setMapType } from "state/actions";
import { useDispatch, useLanguage, useSelector } from "hooks";
import { MapTypes } from "constants/mapTypes";
import SettingsContainer from "../SettingsContainer";

const options = [
  { value: MapTypes.ROADMAP, label: "Roadmap" },
  { value: MapTypes.SATELLITE, label: "Satellite" },
  { value: MapTypes.HYBRID, label: "Hybrid" },
  { value: MapTypes.TERRAIN, label: "Terrain" },
];

interface IProps {
  size: number;
}

function MapTypeSelector({ size }: IProps) {
  const dispatch = useDispatch();
  const mapType = useSelector((state) => state.map.type);
  const demoMode = useSelector((state) => state.ui.demoMode);
  const gmap = useSelector((state) => state.map.gmap);
  const { language } = useLanguage();

  function onChange(event: SelectChangeEvent<string>) {
    if (event.target.value) {
      dispatch(setMapType(event.target.value));
      if (gmap) {
        gmap.setMapTypeId(event.target.value);
      }
    }
  }

  const selectorOptions = demoMode
    ? options.filter((o) => o.value === MapTypes.SATELLITE)
    : options;

  return (
    <SettingsContainer
      title={translations.Menu.Settings.Map[language]}
      size={size}
    >
      <Select
        sx={{ ml: 2 }}
        fullWidth={true}
        size="small"
        id="map-type-selector"
        data-testid="settings.mapType"
        value={options.find((o) => o.value === mapType)?.value}
        onChange={onChange}
      >
        {selectorOptions.map((option, i) => (
          <MenuItem
            key={i.toString()}
            value={option.value}
            data-testid={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </SettingsContainer>
  );
}
export default MapTypeSelector;
