import { ReactElement } from "react";
import { ArrowCircleDown, ArrowCircleUp, Refresh } from "@mui/icons-material";
import styled from "styled-components";
import { Tooltip, IconButton } from "@mui/material";
import { useLanguage } from "hooks";
import { ILanguages } from "utils/translations";
import translations from "./translations";

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
	padding: 0 10px 10px 20px;
	border-bottom: 1px solid #d9d9d9;

	p {
		margin: 0;
	}
`;

const Buttons = styled.div`
	display: flex;
`;

const SmallText = styled.span`
	font-size: 14px;
`;

interface IInfoTextProps {
  imageCount: number;
  loading: boolean;
  filterActive: boolean;
  language: ILanguages;
}

interface IProps extends Omit<IInfoTextProps, "language"> {
  goToPreviousImage: () => void;
  goToNextImage: () => void;
  onRefresh?: () => void;
}

const InfoText = (props: IInfoTextProps) => {
  const { imageCount, loading, filterActive, language } = props;

  if (loading) {
    return (
      <p data-testid="imageList.loading">{translations.Loading[language]}</p>
    );
  }
  if (!filterActive) {
    return (
      <p data-testid="imageList.noFilterActive">
        <SmallText>{translations.FilterNotActive[language]}</SmallText>
      </p>
    );
  }
  if (!imageCount) {
    return (
      <p data-testid="imageList.noImages">
        <SmallText>{translations.NoImages[language]}</SmallText>
      </p>
    );
  }

  return (
    <p>
      <span data-testid="imageList.imageCount">{imageCount}</span>{" "}
      {translations.Images[language]}
    </p>
  );
};

export default function Controls(props: IProps): ReactElement {
  const {
    imageCount,
    loading,
    filterActive,
    goToPreviousImage,
    goToNextImage,
    onRefresh,
  } = props;

  const { language } = useLanguage();

  return (
    <Wrapper>
      <InfoText {...{ imageCount, loading, filterActive, language }} />
      <Buttons>
        <Tooltip
          title={translations.RefreshList[language]}
          placement="top"
          arrow
        >
          <IconButton
            onClick={onRefresh}
            disabled={loading || !filterActive}
            data-testid="imageList.reload"
          >
            <Refresh />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={translations.GoToPreviousImage[language]}
          placement="top"
          arrow
        >
          <IconButton
            onClick={goToPreviousImage}
            disabled={loading || !imageCount}
            data-testid="imageList.prevImage"
          >
            <ArrowCircleUp />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={translations.GoToNextImage[language]}
          placement="top"
          arrow
        >
          <IconButton
            onClick={goToNextImage}
            disabled={loading || !imageCount}
            data-testid="imageList.nextImage"
          >
            <ArrowCircleDown />
          </IconButton>
        </Tooltip>
      </Buttons>
    </Wrapper>
  );
}
