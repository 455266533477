import { Box, Menu, MenuItem } from "@mui/material";
import { PreviousDefectImages } from "interfaces";
import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { isFilterActive } from "utils/filter";
import { sortImages } from "utils/utils";
import MapMarker from "views/map/marker";
import LazyLoadImage from "../LazyLoadImage";
import { useSelector, useDispatch, useLanguage } from "hooks";
import { setSelectedPreviousDefectImage, getImageMeta } from "state/actions";
import translations from "translations";
import "./style.css";

const DEFAULT_WIDTH = 133;
const ROTATION_OFFSET = 180;

interface IProps {
  canLoad: boolean;
}

export default function GeneralImagePreviewSlider({ canLoad }: IProps) {
  const params = useParams();
  const [searchParams] = useSearchParams();
  // @ts-ignore
  const projectID = parseInt(params.mission);
  const dispatch = useDispatch();
  const { language } = useLanguage();

  const imageID = useSelector(
    (state) => state.imageViewer.selectedPreviousDefectImage?.id
  );
  const objectTypes = useSelector((state) => state.objects.objectTypes);
  const bbox = true;
  const skyqraft_employee = useSelector(
    (state) => state.user.skyqraft_employee
  );
  const clusterHidden = !useSelector((state) => state.ui.clusterVisible);
  const annotatorObjectColor = useSelector(
    (state) => state.user.annotator_color
  );
  const openedMarkers = useSelector((state) => state.map.openedMarkers);
  const imageSortingMethod = useSelector(
    (state) => state.imageViewer.imageSortingMethod
  );
  const previousDefectImages = useSelector(
    (state) => state.imageViewer.previousDefectImages
  );

  const [sortedThumbnails, setSortedThumbnails] = useState<
    PreviousDefectImages[]
  >([]);

  const [imageEditorContextMenuAnchor, setImageEditorContextMenuAnchor] =
    useState<null | HTMLElement>(null);
  const [imageToNAvigateTo, setImageToNavigateTo] =
    useState<null | PreviousDefectImages>(null);

  useEffect(() => {
    if (previousDefectImages.length > 0) {
      const sorted = sortImages(previousDefectImages, ROTATION_OFFSET);
      setSortedThumbnails(sorted);
    }
  }, [imageSortingMethod, previousDefectImages]);

  const sliderRef = useRef(null);
  const trackRef = useRef(null);
  const [mouseIsDown, setMouseDown] = useState(false);
  const [oldX, setOldX] = useState(0);
  const [oldY, setOldY] = useState(0);
  const [pressTime, setPressTime] = useState(0);
  // @ts-ignore
  function moveTrack(x) {
    const slider = sliderRef.current;
    // @ts-ignore
    slider.scrollBy(-x, 0);
  }

  useEffect(() => {
    // @ts-ignore
    function updateScroll(e) {
      if (trackRef?.current && (mouseIsDown || e.type === "wheel")) {
        if (sliderRef?.current && trackRef && trackRef.current) {
          moveTrack(e.type === "wheel" ? 0.3 * e.wheelDelta : e.movementX);
        }
      }
    }
    // @ts-ignore
    function setMouse(e) {
      if (e.button === 0 && e.type === "mousedown") {
        setMouseDown(true);
        setOldX(e.pageX);
        setOldY(e.pageY);
        setPressTime(new Date().getTime());
      } else {
        setMouseDown(false);
      }
    }

    if (sliderRef?.current) {
      const slider = sliderRef.current;
      // @ts-ignore
      slider.addEventListener("mousemove", updateScroll);
      // @ts-ignore
      slider.addEventListener("wheel", updateScroll);
      // @ts-ignore
      slider.addEventListener("mousedown", setMouse);
      // @ts-ignore
      slider.addEventListener("mouseup", setMouse);
      return () => {
        // @ts-ignore
        slider.removeEventListener("mousemove", updateScroll);
        // @ts-ignore
        slider.removeEventListener("wheel", updateScroll);
        // @ts-ignore
        slider.removeEventListener("mousedown", setMouse);
        // @ts-ignore
        slider.removeEventListener("mouseup", setMouse);
      };
    }
  });
  // @ts-ignore
  const handleClick = (e, image) => {
    dispatch(setSelectedPreviousDefectImage(image));
  };

  const navigateToOldProject = () => {
    if (!imageToNAvigateTo) return;
    window.open(
      `${location.origin}/${imageToNAvigateTo.project}/${imageToNAvigateTo.id}/annotate${location.search}`,
      "_blank"
    );
    setImageEditorContextMenuAnchor(null);
    setImageToNavigateTo(null);
  };

  return (
    <div
      id="sliderContainer"
      className="sliderContainer"
      ref={sliderRef}
      onClick={(e) => e.preventDefault}
      onMouseLeave={() => setMouseDown(false)}
    >
      <div
        className="sliderTrack"
        ref={trackRef}
        onDragStart={(e) => e.preventDefault()}
        style={{
          transition: mouseIsDown
            ? "height 500ms"
            : "height 500ms, margin-left 500ms",
          height: clusterHidden ? 0 : 125,
        }}
      >
        {!clusterHidden &&
          sortedThumbnails.map((clusterImage, i) => {
            return (
              <div
                style={{ position: "relative" }}
                key={i}
                className={`clusterPreviewImage ${imageID} ${
                  clusterImage?.id === imageID ? "imageOverlay" : ""
                }`}
                onContextMenu={(e) => {
                  if (imageEditorContextMenuAnchor) return;
                  setImageEditorContextMenuAnchor(e.target as HTMLElement);
                  setImageToNavigateTo(clusterImage);
                }}
                data-testid={`previewSlider.thumbnail.${clusterImage.id}`}
                {...(clusterImage?.id === imageID && { "data-active": "" })}
              >
                <Menu
                  anchorEl={imageEditorContextMenuAnchor}
                  open={!!imageEditorContextMenuAnchor}
                  onClose={() => {
                    setImageToNavigateTo(null);
                    setImageEditorContextMenuAnchor(null);
                  }}
                >
                  <MenuItem onClick={navigateToOldProject}>
                    {
                      translations.PreviousDefectsListView.EditInNewTab[
                        language
                      ]
                    }
                  </MenuItem>
                </Menu>
                <LazyLoadImage
                  onLoad={() => {}}
                  // @ts-ignore
                  onClick={(e) => {
                    const distance = Math.sqrt(
                      (oldX - e.pageX) * (oldX - e.pageX) +
                        (oldY - e.pageY) * (oldY - e.pageY)
                    );
                    distance < 10 &&
                      new Date().getTime() - pressTime < 1000 &&
                      handleClick(e, clusterImage);
                  }}
                  defaultWidth={DEFAULT_WIDTH}
                  alt="A preview"
                  image={clusterImage?.id}
                  skyqraft_employee={skyqraft_employee}
                  src={clusterImage?.src}
                  canLoad={canLoad}
                  objectTypes={objectTypes}
                  bbox={bbox}
                  annotatorObjectColor={annotatorObjectColor}
                  altProject={clusterImage?.project}
                />
                <Box position="absolute" top={2} right={18}>
                  <MapMarker
                    id={clusterImage?.id}
                    weight={1000}
                    viewed={0}
                    is_filtered={true}
                    pole_id={null}
                    cluster_id={null}
                    compass_dir={clusterImage.compass_dir}
                    image_type={0}
                    lat={0}
                    lng={0}
                    isPreview={true}
                    filterActive={isFilterActive(searchParams)}
                    openedMarkers={openedMarkers}
                  />
                </Box>
              </div>
            );
          })}
      </div>
    </div>
  );
}
