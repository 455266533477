import { Select, MenuItem, SelectChangeEvent } from "@mui/material";

import { changeClientNaming } from "state/actions";
import translations from "translations";
import { useLanguage, useSelector, useDispatch } from "hooks";
import SettingsContainer from "../SettingsContainer";

interface IProps {
  size: number;
}

function ClientNamingSwitch({ size }: IProps) {
  const clientNaming = useSelector((state) => state.user.client_naming);
  const dispatch = useDispatch();

  const onChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value === "true"; // Convert string back to boolean
    dispatch(changeClientNaming(value));
  };
  const { language } = useLanguage();

  const options = [
    {
      value: false,
      label: "Arkion Naming",
      dataTestId: "settings.clientNaming.option.arkionNaming",
    },
    {
      value: true,
      label: "Client Naming",
      dataTestId: "settings.clientNaming.option.clientNaming",
    },
  ];

  return (
    <SettingsContainer
      title={translations.Menu.Settings.ClientNaming[language]}
      size={size}
    >
      <Select
        sx={{ ml: 2 }}
        fullWidth={true}
        size="small"
        id="select-client-naming"
        data-testid="settings.clientNaming"
        value={clientNaming.toString()} // Convert boolean to string
        onChange={onChange}
      >
        {options.map((option, i) => (
          <MenuItem
            key={i.toString()}
            value={option.value.toString()} // Convert boolean to string
            data-testid={option.dataTestId}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </SettingsContainer>
  );
}

export default ClientNamingSwitch;
