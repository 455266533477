import { ReactElement, useState, useRef, useEffect } from "react";
import { Image, Check } from "@mui/icons-material";
import { Tooltip, Checkbox } from "@mui/material";
import translations from "translations";
import { ListViewMarker } from "interfaces";
import { useLanguage } from "hooks";
import {
  ImageBox,
  ItemStart,
  ItemEnd,
  SeverityCount,
} from "./StyledComponents";

interface IProps {
  marker: ListViewMarker;
  onClick: () => void;
  active: boolean;
  childItem?: boolean;
  viewedImageIds: number[];
  reviewEnabled?: boolean;
  reviewed?: boolean;
  onToggleReviewed?: () => void;
}

export default function ImageSection(props: IProps): ReactElement {
  const {
    marker,
    onClick,
    active,
    childItem,
    viewedImageIds,
    reviewEnabled,
    reviewed,
    onToggleReviewed,
  } = props;

  const [updatingReviewed, setUpdatingReviewed] = useState(false);

  const keydownEventHandlerRef = useRef<(e: KeyboardEvent) => void>(() => {});

  const toggleReview = async () => {
    if (updatingReviewed || !reviewEnabled || !onToggleReviewed) return;

    setUpdatingReviewed(true);
    await onToggleReviewed();
    setUpdatingReviewed(false);
  };

  useEffect(() => {
    if (active && reviewEnabled) {
      keydownEventHandlerRef.current = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
          toggleReview();
        }
      };
      window.addEventListener("keydown", keydownEventHandlerRef.current);
    }

    return () => {
      window.removeEventListener("keydown", keydownEventHandlerRef.current);
    };
  }, [active, reviewEnabled, toggleReview]);

  const { language } = useLanguage();

  const viewed = !!marker.viewed || viewedImageIds.includes(marker.id);

  return (
    <ImageBox
      {...(!active && { onClick })}
      active={active}
      childItem={childItem}
      data-testid={`imageList.imageSection.${marker.id}`}
      data-severity-count={marker.severity_count}
      {...(active && { "data-active": "" })}
      {...(viewed && { "data-viewed": "" })}
    >
      <ItemStart viewed={viewed}>
        <Image htmlColor="#6E6E6E" />
        <p>{marker.id}</p>
        {viewed && <Check />}
      </ItemStart>

      <ItemEnd>
        {reviewEnabled && (
          <Checkbox
            checked={reviewed}
            onClick={toggleReview}
            disabled={updatingReviewed}
          />
        )}
        <Tooltip
          title={translations.ListView.NumberOfDefects[language]}
          placement="left"
          arrow
        >
          <SeverityCount>{marker.severity_count}</SeverityCount>
        </Tooltip>
      </ItemEnd>
    </ImageBox>
  );
}
