import { Switch } from "@mui/material";

import translations from "translations";
import { useLanguage, useSelector, useDispatch } from "hooks";
import { setShowDatesBeforeProjectName } from "state/actions";
import SettingsContainer from "../SettingsContainer";

interface IProps {
  size: number;
}

function DateBeforeProjectName({ size }: IProps) {
  const showDatesBeforeProjectName = useSelector(
    (state) => state.ui.showDatesBeforeProjectName
  );
  const dispatch = useDispatch();
  const { language } = useLanguage();

  function onChange() {
    dispatch(setShowDatesBeforeProjectName(!showDatesBeforeProjectName));
  }

  return (
    <SettingsContainer
      size={size}
      title={translations.Menu.Settings.ShowDatesBeforeProjectName[language]}
    >
      <Switch
        color="primary"
        checked={showDatesBeforeProjectName}
        onChange={onChange}
      />
    </SettingsContainer>
  );
}
export default DateBeforeProjectName;
