import { z } from "zod";
import { EventHint, captureException } from "@sentry/react";

// Define the Zod schema for steelwork
const SteelworkSchema = z
  .object({
    bolt_condition: z
      .object({
        id: z.number().nullable(),
        name: z.string().nullable(),
      })
      .nullable(),
    child_link: z
      .object({
        id: z.number().nullable(),
        image_id: z.number().nullable(),
      })
      .nullable(),
    direction: z
      .object({
        id: z.number(),
        name: z.string(),
      })
      .nullable(),
    id: z.number(),
    item_id: z.string(),
    parent_link: z
      .object({
        id: z.number().nullable(),
        image_id: z.number().nullable(),
      })
      .nullable(),
    pole_id: z.number().nullable(),
    second_direction: z
      .object({
        id: z.number().nullable(),
        name: z.string().nullable(),
      })
      .nullable(),
    steel_grading: z
      .object({
        id: z.number().nullable(),
        name: z.string().nullable(),
      })
      .nullable(),
    save_error: z.string().nullable().optional(),
  })
  .nullable();

// Define the main schema
const ObjectSchema = z.object({
  confidence: z.array(z.number()),
  creator: z.array(z.string()),
  creator_is_ai: z.array(z.boolean()),
  fixed: z.string().nullable(),
  h: z.number(),
  id: z.string(),
  item_id: z.string().nullable(),
  objectHasNoDefect: z.boolean(),
  processed: z.array(z.object({}).nullable()),
  reported: z.string().nullable(),
  rotation: z.number(),
  severities: z.array(z.number().nullable()),
  skyqraft_hidden: z.array(z.boolean()),
  steelwork: SteelworkSchema,
  type_comment: z.array(z.string()),
  type_id: z.array(z.number().nullable()),
  types: z.array(z.number()),
  updated_by: z.array(z.string()),
  w: z.number(),
  workflow_status: z.array(z.number().nullable()),
  x: z.number(),
  y: z.number(),
  isLocked: z.boolean(),
  visible: z.boolean(),
  categories: z.array(z.number().nullable()),
  is_defect: z.array(z.boolean()),
  hover: z.boolean(),
  active: z.boolean(),
  client_types: z.array(z.unknown()),
  editIndex: z.number().nullable().optional(),
  editType: z.string().nullable().optional(),
  ring_count: z.number().nullable().optional(),
  is_locked: z.boolean().nullable().optional(),
  origin: z.number().nullable().optional(),
});

// @ts-ignore
export default function objectPasteValidation(data): boolean {
  try {
    ObjectSchema.parse(data);
    return true;
  } catch (error) {
    console.log(error);
    const exceptionHint: EventHint = {
      event_id: "annotationTool.objectPasteValidation",
      originalException: error,
      data: {
        data,
      },
    };
    captureException(error, exceptionHint);
  }
  return false;
}
