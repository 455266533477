import { useContext, useEffect, useRef, useState } from "react";
import ItemColumn from "../ItemColumn";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { context } from "./ComponentOptionsProvider";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFlags } from "launchdarkly-react-client-sdk";

type paramTypes = Record<"component_id", string>;

export default function EditComponent() {
  const formRef = useRef<HTMLFormElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const sortingRef = useRef<HTMLInputElement>(null);
  const {
    components,
    updateComponent,
    deleteComponent,
    valueSystems,
    componentCategories,
  } = useContext(context);
  const params = useParams<paramTypes>();
  // @ts-ignore
  const componentID = parseInt(params.component_id);

  const { structureTemplateEditorCategories } = useFlags();

  const [selectedValueSystem, setSelectedValueSystem] = useState<number | "">(
    ""
  );
  const [selectedCategory, setSelectedCategory] = useState<number | "">("");

  const component = components.find(
    (component) => component.id === componentID
  );

  useEffect(() => {
    // Update the name value when swapping component
    if (component) {
      if (nameRef.current) {
        nameRef.current.value = component.name;
      }
      if (sortingRef.current) {
        sortingRef.current.value = component.sorting.toString();
      }
      setSelectedValueSystem(component.value_system_id || "");
      setSelectedCategory(component.category_id || "");
    }
  }, [component]);

  // @ts-ignore
  function onFormSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name") as string;
    const sorting = Number(formData.get("sorting"));

    if (!name || name === "") {
      toast.error("The new name has to be at least 1 character long");
      return;
    }
    if (!selectedValueSystem) {
      toast.error("You have to select a value system");
      return;
    }

    if (selectedCategory) {
      updateComponent({
        componentID,
        name,
        valueSystemID: selectedValueSystem,
        categoryID: selectedCategory,
        sorting,
      });
    } else {
      updateComponent({
        componentID,
        name,
        valueSystemID: selectedValueSystem,
        sorting,
      });
    }
  }

  function onDelete() {
    const confirmation = window.confirm(
      "Are you sure you want to delete this component? All templates using this component will be altered. All placements connected to this component will also be deleted."
    );
    if (confirmation) {
      deleteComponent(componentID);
    }
  }

  if (!component || !component.name) {
    return (
      <ItemColumn
        title="Component not found"
        description="The specified component could not be found."
      />
    );
  }

  return (
    <ItemColumn
      title={component ? component.name : "Unknown component"}
      description="Edit the properties of this component below"
      onSave={() =>
        onFormSubmit({ target: formRef.current, preventDefault: () => {} })
      }
    >
      <form onSubmit={onFormSubmit} ref={formRef}>
        <Stack spacing={2}>
          <FormControl>
            <Typography>Name</Typography>
            <TextField
              defaultValue={component.name}
              inputRef={nameRef}
              name="name"
              variant="standard"
            />
          </FormControl>
          <Typography>Value system</Typography>
          <Select
            name="valueSystem"
            variant="standard"
            fullWidth
            label="Value system"
            value={selectedValueSystem.toString()}
            onChange={(e) => setSelectedValueSystem(Number(e.target.value))}
          >
            {valueSystems.map((system) => (
              <MenuItem key={system.id} value={system.id.toString()}>
                {system.name}
              </MenuItem>
            ))}
          </Select>
          {structureTemplateEditorCategories && (
            <>
              <Typography>Category</Typography>
              <Select
                name="category"
                variant="standard"
                fullWidth
                label="Category"
                value={selectedCategory.toString()}
                onChange={(e) => setSelectedCategory(Number(e.target.value))}
              >
                {componentCategories.map((category) => (
                  <MenuItem key={category.id} value={category.id.toString()}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              <FormControl>
                <Typography>Sorting</Typography>
                <TextField
                  defaultValue={component.sorting}
                  inputRef={sortingRef}
                  name="sorting"
                  type="number"
                  variant="standard"
                />
              </FormControl>
            </>
          )}
        </Stack>

        <Stack mt={2} spacing={1} direction="row">
          <Button type="submit" variant="contained" color="success">
            Save
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </Stack>
      </form>
    </ItemColumn>
  );
}
