import { useContext, useEffect, useRef } from "react";
import ItemColumn from "../ItemColumn";
import {
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { context } from "./CategoryOptionsProvider";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

type paramTypes = Record<"category_id", string>;

export default function EditCategory() {
  const formRef = useRef<HTMLFormElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const sortingRef = useRef<HTMLInputElement>(null);
  const { updateCategory, deleteCategory, componentCategories } =
    useContext(context);
  const params = useParams<paramTypes>();

  const categoryID = params.category_id ? parseInt(params.category_id) : null;

  const category = componentCategories.find(
    (category) => category.id === categoryID
  );

  useEffect(() => {
    // Update the name value when swapping categories
    if (category && nameRef.current && sortingRef.current) {
      nameRef.current.value = category.name;
      sortingRef.current.value = category.sorting.toString();
    }
  }, [category]);

  function onFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const name = formData.get("name") as string;
    const sorting = Number(formData.get("sorting"));
    let newName = null;
    let newSorting = null;

    if (!name || name === "") {
      toast.error("The new name has to be at least 1 character long");
      return;
    }

    if (name !== category?.name) {
      newName = name;
    }

    if (sorting !== category?.sorting && !Number.isNaN(sorting)) {
      newSorting = sorting;
    }

    if (categoryID && (newName || newSorting !== null)) {
      updateCategory(categoryID, newName, sorting);
    }
  }

  function onDelete() {
    const confirmation = window.confirm(
      "Are you sure you want to delete this category? All components using this category will be altered."
    );
    if (confirmation && categoryID) {
      deleteCategory(categoryID);
    }
  }

  if (!category || !category.name) {
    return (
      <ItemColumn
        title="Category not found"
        description="The specified category could not be found."
      />
    );
  }

  return (
    <ItemColumn
      title={category ? category.name : "Unknown category"}
      description="Edit the properties of this category below"
      onSave={() => {
        if (formRef.current) {
          const customEvent = {
            target: formRef.current,
            preventDefault: () => {},
          } as unknown as React.FormEvent<HTMLFormElement>;
          onFormSubmit(customEvent);
        }
      }}
    >
      <form onSubmit={onFormSubmit} ref={formRef}>
        <Stack spacing={2}>
          <FormControl>
            <Typography>Name</Typography>
            <TextField
              defaultValue={category.name}
              inputRef={nameRef}
              name="name"
              variant="standard"
            />
          </FormControl>
          <FormControl>
            <Typography>Sorting</Typography>
            <TextField
              defaultValue={category.sorting}
              inputRef={sortingRef}
              name="sorting"
              type="number"
              variant="standard"
            />
          </FormControl>
        </Stack>

        <Stack mt={2} spacing={1} direction="row">
          <Button type="submit" variant="contained" color="success">
            Save
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </Stack>
      </form>
    </ItemColumn>
  );
}
