import { DomainSettings, defaultDomainSettings } from "../actions/domain";

export interface IState {
  domainSettings: DomainSettings;
}

export const initialState: IState = {
  domainSettings: defaultDomainSettings,
};

interface IAction {
  type: string;
  payload: DomainSettings;
}

const domainReducer = (state: IState | undefined, action: IAction): IState => {
  if (state === undefined) {
    return initialState;
  }
  switch (action.type) {
    case "SET_DOMAIN_SETTINGS":
      return {
        ...state,
        domainSettings: action.payload,
      };

    default:
      return state;
  }
};

export default domainReducer;
