import { Stack, Typography } from "@mui/material";
import { Loading } from "views";

interface IProps {
  title: string;
  loading?: boolean;
  children?: React.ReactNode;
}

export default function Wrapper({ children, loading, title }: IProps) {
  return (
    <Stack pt={4} px={4} justifyContent="center" alignItems="center">
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={2}
        maxWidth={700}
      >
        {loading && <Loading relative size={50} color="black" />}
        <Typography variant="h5">{title}</Typography>
        {children}
      </Stack>
    </Stack>
  );
}
