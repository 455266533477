import { useContext, useRef } from "react";
import ItemColumn from "../ItemColumn";
import {
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { context } from "./ComponentOptionsProvider";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function AddPlacement() {
  const navigate = useNavigate();
  const params = useParams<Record<"component_id", string>>();
  // @ts-ignore
  const componentID = parseInt(params.component_id);
  const formRef = useRef<HTMLFormElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const { createPlacement } = useContext(context);

  // @ts-ignore
  function onFormSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name") as string;
    const regexPattern = formData.get("regexPattern") as string;

    if (!name || name === "") {
      toast.error("The new name has to be at least 1 character long");
      return;
    }

    createPlacement(componentID, name).then((newPlacementID) => {
      navigate(
        `/options/pole/status/component/${componentID}/placement/${newPlacementID}`
      );
    });
  }

  return (
    <ItemColumn
      title="Create a new placement"
      description="Enter the properties of this placement below"
      onSave={() =>
        onFormSubmit({ target: formRef.current, preventDefault: () => {} })
      }
    >
      <form onSubmit={onFormSubmit} ref={formRef}>
        <Stack spacing={2}>
          <FormControl>
            <Typography>Name</Typography>
            <TextField inputRef={nameRef} name="name" variant="standard" />
          </FormControl>
        </Stack>

        <Stack mt={2} spacing={1} direction="row">
          <Button type="submit" variant="contained" color="success">
            Create
          </Button>
        </Stack>
      </form>
    </ItemColumn>
  );
}
