import { BASE_URI } from "config";
import axios from "axios";
import { toast } from "react-toastify";
import { getLambdaURL } from "./path";

const axiosInstance = axios.create({
  baseURL: BASE_URI,
  withCredentials: true,
  validateStatus: (status) => {
    if (status === 401) {
      // Session has expired, reload page and client will take care of redirecting and re-authenticating
      window.location.reload();
      return true;
    }
    return status >= 200 && status < 400; // Throw on any other status
  },
});

function extractProjectID() {
  let currentCustomer = null;
  try {
    currentCustomer = window.location.pathname.split("/")[1];

    currentCustomer = Number.parseInt(currentCustomer);
  } catch {
    currentCustomer = null;
  }
  return currentCustomer;
}

export async function authorizedGet<T = never, R = T>(
  // @ts-ignore
  path,
  header = {},
  options = {},
  requireMissionID = false
): Promise<R> {
  try {
    // Perform the fetch
    const currentCustomer = extractProjectID();

    if (requireMissionID && !currentCustomer) {
      // @ts-ignore
      return null;
    }

    const response = await axiosInstance.get(path, {
      headers: {
        MissionID: currentCustomer,
        ...header,
      },
      ...options,
    });

    if (response.status >= 400) {
      console.error(response);
      // @ts-ignore
      return null;
    }
    if (response.status < 300) {
      const headers = response.headers;
      // @ts-expect-error
      const sessionTimeExpire = headers.get("X-Session-Expires");
      if (sessionTimeExpire) {
        const time = Date.parse(sessionTimeExpire);
        sessionStorage.setItem("sessionTimeExpire", time.toString());
      }
    }

    return response.data;
  } catch (err) {
    // @ts-ignore
    return null;
  }
}

export async function authorizedGetBlob(
  // @ts-ignore
  path,
  header = {},
  options = {},
  requireMissionID = false
): Promise<BlobPart> {
  try {
    // Perform the fetch
    const currentCustomer = extractProjectID();

    if (requireMissionID && !currentCustomer) {
      // @ts-ignore
      return null;
    }
    const response = await axiosInstance.get<BlobPart>(path, {
      headers: {
        MissionID: currentCustomer,
        ...header,
      },
      responseType: "blob",
      ...options,
    });

    if (response.status >= 400) {
      toast.error(`${response.status} - ${response.statusText}`);
    }
    return response.data;
  } catch (err) {
    // @ts-ignore
    return null;
  }
}

export async function authorizedPost<T = never, R = T>(
  // @ts-ignore
  path,
  body = {},
  header = {},
  requireMissionID = false
): Promise<R> {
  // Perform the fetch
  const currentCustomer = extractProjectID();

  if (requireMissionID && !currentCustomer) {
    // @ts-ignore
    return null;
  }
  const response = await axiosInstance.post<R>(path, body, {
    headers: {
      MissionID: currentCustomer,
      "content-type": "application/json",
      ...header,
    },
  });

  return response.data;
}

export async function authorizedPut<T = never, R = T>(
  // @ts-ignore
  path,
  body = {},
  header = {},
  requireMissionID = false
): Promise<R> {
  // Perform the fetch
  const currentCustomer = extractProjectID();

  if (requireMissionID && !currentCustomer) {
    // @ts-ignore
    return null;
  }
  const response = await axiosInstance.put<R>(path, body, {
    headers: {
      MissionID: currentCustomer,
      "content-type": "application/json",
      ...header,
    },
  });

  if (response.status >= 400) {
    toast.error(`${response.status} - ${response.statusText}`);
  }

  return response.data;
}

export async function authorizedDelete(
  // @ts-ignore
  path,
  header = {},
  requireMissionID = false
) {
  // Perform the fetch
  const currentCustomer = extractProjectID();

  if (requireMissionID && !currentCustomer) {
    return null;
  }
  const response = await axiosInstance.delete(path, {
    headers: {
      MissionID: currentCustomer,
      "content-type": "application/json",
      ...header,
    },
  });

  if (response.status >= 400) {
    toast.error(`${response.status} - ${response.statusText}`);
  }

  return response;
}

export { axiosInstance };

export async function extractArrayStream(
  stream: ReadableStream
  // @ts-ignore
): Promise<any[]> {
  const decoder = new TextDecoder("utf-8");
  let textData = "";

  try {
    const reader = stream.getReader();

    while (true) {
      const { done, value } = await reader.read();

      if (done) {
        break;
      }
      const chunk = decoder.decode(value, { stream: true });
      textData += chunk;
    }
    // The stream only contains the objects so we need to put them into an array and remove the last comma for valid json
    const textResult = `[${textData.slice(0, -1)}]`;
    const data = JSON.parse(textResult);

    return data;
  } catch (err) {
    console.error("Stream error:", err);
  }
}
