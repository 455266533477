import { Menu, Stack } from "@mui/material";
import ProfileMenuHeader from "./ProfileMenuHeader";
import ProfileMenuContent from "./ProfileMenuContent";

type Props = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
};

export default function ProfileMenu({ anchorEl, onClose }: Props) {
  return (
    <Menu
      id="profile-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      container={document.body}
      slotProps={{
        paper: {
          sx: {
            boxShadow: "0px 10px 10px rgba(0,0,0,0.2)", // Removes the shadow
            borderTopLeftRadius: 10,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
            paddingBottom: 0,
            padding: 0,
            margin: 0,
          },
        },
      }}
      MenuListProps={{
        sx: {
          paddingBottom: 0, // Removes bottom padding from the MenuList
        },
      }}
    >
      <Stack
        direction="column"
        width={{
          xs: 200,
          md: 600,
        }}
      >
        <ProfileMenuHeader />
        <ProfileMenuContent />
      </Stack>
    </Menu>
  );
}
