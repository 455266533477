import { useContext, useRef } from "react";
import ItemColumn from "../ItemColumn";
import {
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { context } from "./CategoryOptionsProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function AddCategory() {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const sortingRef = useRef<HTMLInputElement>(null);

  const { createCategory } = useContext(context);

  async function onFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const name = formData.get("name") as string;
    const sorting = Number(formData.get("sorting"));

    if (!name || name === "") {
      toast.error("The new name has to be at least 1 character long");
      return;
    }

    await createCategory({ name, sorting }).then((newCategoryID) => {
      navigate(`/options/pole/status/category/${newCategoryID}`);
    });
  }

  return (
    <ItemColumn
      title="Create a new component category"
      description="Enter the properties of this category below"
      onSave={() => {
        if (formRef.current) {
          const syntheticEvent = new Event("submit", {
            bubbles: true,
            cancelable: true,
          });
          formRef.current.dispatchEvent(syntheticEvent);
        }
      }}
    >
      <form onSubmit={onFormSubmit} ref={formRef}>
        <Stack spacing={2}>
          <FormControl>
            <Typography>Name</Typography>
            <TextField
              inputRef={nameRef}
              name="name"
              variant="standard"
              inputProps={{ "data-testid": "category-name-input" }}
            />
          </FormControl>

          <FormControl>
            <Typography>Sorting Order</Typography>
            <TextField
              inputRef={sortingRef}
              name="sorting"
              type="number"
              variant="standard"
              inputProps={{ "data-testid": "category-sorting-input" }}
            />
          </FormControl>
        </Stack>

        <Stack mt={2} spacing={1} direction="row">
          <Button
            type="submit"
            variant="contained"
            color="success"
            data-testid="create-category-button"
          >
            Create
          </Button>
        </Stack>
      </form>
    </ItemColumn>
  );
}
