import React from "react";
import store, { RootState } from "state/store";
import { MenuItem, Typography, ListItemIcon } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import translations from "translations";
import { useParams } from "react-router-dom";
import { useCurrentProject, useLanguage } from "hooks";
import { openLidarWindow } from "utils/lidar";

interface IProps {
  // @ts-ignore
  data;
}

const ZoneItem: React.FC<IProps> = ({ data }) => {
  const { language } = useLanguage();
  const currentProject = useCurrentProject();
  const params = useParams();
  const hasLink = !!data.zoneID;
  const copyLink = hasLink
    ? `${window.location.origin}/${
        params.mission
      }?zone=${data.zoneId?.toString()}`
    : "no-link";
  return (
    <>
      <MenuItem
        onClick={() => {
          const state: RootState = store.getState();

          if (currentProject) {
            openLidarWindow(currentProject.id, data.name, {
              lat: data.lat,
              lng: data.lng,
              demoMode: state.ui.demoMode,
            });
          }
        }}
        onMouseEnter={() => {
          const state: RootState = store.getState();
          const map = state.map.gmap;
          if (!map) return;
          map.data.overrideStyle(data.feature, { fillColor: "grey" });
        }}
        onMouseLeave={() => {
          const state: RootState = store.getState();
          const map = state.map.gmap;
          if (!map) return;
          map.data.revertStyle(data.feature);
        }}
      >
        <ListItemIcon>
          <ViewInArIcon />
        </ListItemIcon>
        <Typography>
          {translations.Menu.Tools.Powerlines.Zones.Menu.Lidar[language]}
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={() => navigator.clipboard.writeText(copyLink)}
        onMouseEnter={() => {
          const state: RootState = store.getState();
          const map = state.map.gmap;
          if (!map) return;
          map.data.overrideStyle(data.feature, { fillColor: "grey" });
        }}
        onMouseLeave={() => {
          const state: RootState = store.getState();
          const map = state.map.gmap;
          if (!map) return;
          map.data.revertStyle(data.feature);
        }}
        disabled={!hasLink}
      >
        <ListItemIcon>
          <ContentCopyIcon />
        </ListItemIcon>
        <Typography>
          {translations.Menu.Tools.Powerlines.Zones.Menu.Link[language]}
        </Typography>
      </MenuItem>
    </>
  );
};

export default ZoneItem;
