import { Button, Grid } from "@mui/material";
import React from "react";

export enum SortingOptions {
  defects = "defects",
  processed = "processed",
  unprocessed = "unprocessed",
}

const Colors = {
  activeBackgroundColor: "#fafafa",
  activeColor: "#000000",
  activeHoverBackgroundColor: "#ffffff",
  activeHoverColor: "#000000",
  inactiveBackgroundColor: "#dedede",
  inactiveColor: "#000000",
  inactiveHoverBackgroundColor: "#ededed",
  inactiveHoverColor: "#000000",
} as const;

export type buttonMethodType =
  (typeof SortingOptions)[keyof typeof SortingOptions];

type Props = {
  buttonMethod: buttonMethodType;
  currentSortingMethod: buttonMethodType;
  setSortMethod: (method: buttonMethodType) => void;
  children: React.ReactNode;
};

export default function SortingButton({
  buttonMethod,
  currentSortingMethod,
  setSortMethod,
  children,
}: Props) {
  return (
    <Grid item xs={4} textAlign="center">
      <Button
        onClick={() => setSortMethod(buttonMethod)}
        fullWidth
        variant="contained"
        sx={{
          color:
            currentSortingMethod === buttonMethod
              ? Colors.activeColor
              : Colors.inactiveColor,
          bgcolor:
            currentSortingMethod === buttonMethod
              ? Colors.activeBackgroundColor
              : Colors.inactiveBackgroundColor,
          "&:hover": {
            color:
              currentSortingMethod === buttonMethod
                ? Colors.activeHoverColor
                : Colors.inactiveHoverColor,
            bgcolor:
              currentSortingMethod === buttonMethod
                ? Colors.activeHoverBackgroundColor
                : Colors.inactiveHoverBackgroundColor,
          },
        }}
      >
        {children}
      </Button>
    </Grid>
  );
}
