import { useContext, useState, ChangeEvent } from "react";
import { AnnotationContext } from "../../../provider";
import { BoxColumn } from "./BoxColumn";
import { useLanguage } from "hooks";
import {
  Box,
  Card,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import translations from "./translations";
import Timestamp from "./Timestamp";

type Props = {
  // @ts-ignore
  annotation;
  index: number;
};

export default function InfoCommentBox({ annotation, index }: Props) {
  const { updateAnnotation } = useContext(AnnotationContext);
  const { language } = useLanguage();
  const [stateComment, setStateComment] = useState(annotation.type_comment);

  const handleCommentChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number, // @ts-ignore
    region
  ) => {
    const newComment = [...stateComment];
    newComment[index] = e.target.value;
    setStateComment(newComment);

    updateAnnotation(annotation.id, {
      ...region,
      type_comment: newComment,
      isNew: false,
    });
  };

  const isNew = annotation.id.includes("n");
  const isDefect = annotation?.is_defect[index];
  const creator = annotation?.creator[index]
    ? annotation.creator[index]
    : "you";
  const updator = annotation?.creator[index]
    ? annotation.updated_by[index]
    : "you";

  if (!isDefect && isNew) {
    // Don't render if it is a new detection
    return null;
  }

  return (
    <Stack mx={1} spacing={1} mt={1}>
      <Grid container rowGap={1}>
        {!isNew && (
          <BoxColumn
            title={translations.CreatedBy[language]}
            size={6}
            padding={1}
          >
            <Tooltip title={isNew ? "" : creator} placement="top">
              <Typography
                fontSize="small"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {isNew ? "" : creator}
              </Typography>
            </Tooltip>
            <Timestamp timestamp={annotation?.time_created[index]} />
          </BoxColumn>
        )}
        {!isNew && (
          <BoxColumn
            title={translations.UpdatedBy[language]}
            size={6}
            padding={1}
          >
            <Tooltip title={isNew ? "" : updator} placement="top">
              <Typography
                fontSize="small"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {isNew ? "" : updator}
              </Typography>
            </Tooltip>
            <Timestamp timestamp={annotation?.time_updated[index]} />
          </BoxColumn>
        )}
        {isDefect && (
          <BoxColumn title={translations.Comment[language]} size={12}>
            <TextField
              fullWidth
              variant="standard"
              value={stateComment[index]}
              onChange={(e) => {
                handleCommentChange(e, index, annotation);
              }}
              inputProps={{
                style: {
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  paddingTop: "0.5rem",
                  paddingBottom: "0.5rem",
                },
              }}
              multiline
              rows={3}
              sx={{
                "& .MuiInputBase-root": {
                  fontSize: "0.75rem",
                },
              }}
            />
          </BoxColumn>
        )}
      </Grid>
    </Stack>
  );
}
