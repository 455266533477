import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import {
  ActorsFilter,
  AreaFilter,
  DateSelectorFilter,
  DsoTsoFilter,
  FlaggedImageFilter,
  FlightFilter,
  SkyqraftHiddenFilter,
  DefectNotReportedFilter,
  DefectNotProcessedFilter,
} from "components/Filter/FilterComponents";
import FeedBay from "components/Filter/FeedBay";
import { RoleWrapper } from "components";
import { useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import translations from "translations";
import { useLanguage } from "hooks";

function Explanation() {
  const [open, setOpen] = useState(false);
  const { language } = useLanguage();
  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        sx={{ mx: 2, mt: 2 }}
      >
        {translations.Filter.WhatAreTheseFilters[language]}
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ sx: { background: "#EDEDED" } }}
      >
        <DialogTitle>
          {translations.Filter.WhatAreTheseFilters.Title[language]}
        </DialogTitle>
        <DialogContent>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              {translations.Filter.WhatAreTheseFilters.AreaFilter[language]}
            </AccordionSummary>
            <AccordionDetails>
              {
                translations.Filter.WhatAreTheseFilters.AreaFilter.Description[
                  language
                ]
              }
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              {translations.Filter.WhatAreTheseFilters.FlightFilter[language]}
            </AccordionSummary>
            <AccordionDetails>
              {
                translations.Filter.WhatAreTheseFilters.FlightFilter
                  .Description[language]
              }
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              {translations.Filter.WhatAreTheseFilters.DateSelector[language]}
            </AccordionSummary>
            <AccordionDetails>
              {
                translations.Filter.WhatAreTheseFilters.DateSelector
                  .Description[language]
              }
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              {translations.Filter.WhatAreTheseFilters.FeedBay[language]}
            </AccordionSummary>
            <AccordionDetails>
              {
                translations.Filter.WhatAreTheseFilters.FeedBay.Description[
                  language
                ]
              }
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            {translations.Filter.Close[language]}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default function SpatialFilters() {
  const { language } = useLanguage();
  return (
    <Stack px={2} mb={2} spacing={1}>
      <AreaFilter />
      <FlightFilter />
      <DateSelectorFilter />
      <FeedBay />
      <Explanation />
      <RoleWrapper keyName="advancedFilter">
        <Stack>
          <Divider sx={{ py: 1 }}>
            {translations.Filter.SkyqraftHidden[language]}
          </Divider>
          <SkyqraftHiddenFilter />
          <Divider sx={{ py: 1 }}>
            {translations.Filter.AdvancedFilters[language]}
          </Divider>
          <ActorsFilter />
          <FlaggedImageFilter />
          <DsoTsoFilter />
          <DefectNotReportedFilter />
          <DefectNotProcessedFilter />
        </Stack>
      </RoleWrapper>
    </Stack>
  );
}
