import { DomainManager } from "./views";
import { useSelector } from "hooks";

export default function FlagProtectedDomainManager() {
  const isSuperuser = useSelector((state) => state.user.superuser);

  if (!isSuperuser) {
    return <p>You are not allowed access this resource</p>;
  }

  return <DomainManager />;
}
export { featureLocation } from "./constants";
export { DomainPage } from "./views";
