import styled from "styled-components";

export const Panels = styled.div`
	display: flex;
	flex: 1;
	position: relative;
	overflow-x: auto;
`;

interface IPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  grow?: boolean;
}
export const Panel = styled.div<IPanelProps>`
	display: flex;
	flex-shrink: 0;
	flex-grow: ${(props) => (props.grow ? 1 : 0)};
	z-index: 100;
	position: relative;
	background-color: #fff;
	box-shadow: -3px 0px 0px 0px rgba(0,0,0,0.75);
`;

export const MapViewWrapper = styled.div`
	flex: 1;
	min-width: 200px;
`;
