import { useMemo, useState } from "react";
import { useProjectStatistics } from "./hooks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import BarChart from "./BarChart";
import Summary from "./Summary";
import SortingButton, { SortingOptions } from "./SortingButton";
import type { buttonMethodType } from "./SortingButton";
import ExpandMoreTitle from "./ExpandMoreTitle";
import { useLanguage } from "hooks";
import translations from "./translations";
import { Search } from "@mui/icons-material";
import { filterProjects, sortProjects } from "./utils";

// 5 percent looks quite good!
const SPLIT_THRESHOLD = 0.05;

export default function ProjectStatistics() {
  const [searchString, setSearchString] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [sortMethod, setSortMethod] = useState<buttonMethodType>(
    SortingOptions.defects
  );

  // Extract project statitics from the API via this hook
  const { projectStatistics, loading } = useProjectStatistics();
  const { language } = useLanguage();

  // Filter and sort the statistics
  const filteredStats = useMemo(
    () => filterProjects(projectStatistics, searchString),
    [projectStatistics, searchString]
  );
  const stats = useMemo(
    () => sortProjects(filteredStats, sortMethod),
    [filteredStats, sortMethod]
  );

  const maxDefectsCount = useMemo(() => {
    // Calculate the maximum number of defects in the dataset
    return stats.map((s) => s.n_defects).reduce((a, b) => Math.max(a, b), 0);
  }, [stats]);

  const primaryViewStats = useMemo(() => {
    // Don't filter if we are searching
    if (searchString !== "") {
      return stats;
    }

    // Filter out the projects with the most defects
    return stats.filter(
      (s) => s.n_defects >= maxDefectsCount * SPLIT_THRESHOLD
    );
  }, [maxDefectsCount, stats, searchString, sortMethod]);

  const secondaryViewStats = useMemo(() => {
    // Filter out the projects with the least defects
    return stats.filter((s) => s.n_defects < maxDefectsCount * SPLIT_THRESHOLD);
  }, [maxDefectsCount, stats, sortMethod]);

  return (
    <Stack direction="column" mx={2}>
      <Summary stats={stats} loading={loading} />
      <Accordion
        component={Box}
        sx={{ backgroundColor: "transparent", boxShadow: "none" }}
        disableGutters
        expanded={accordionOpen}
        onChange={(_, expanded) => setAccordionOpen(expanded)}
      >
        <AccordionSummary sx={{ px: 0 }}>
          <ExpandMoreTitle open={accordionOpen}>
            {accordionOpen
              ? translations.ShowLess[language]
              : translations.ShowMore[language]}
          </ExpandMoreTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h5">
            {sortMethod === SortingOptions.unprocessed &&
              translations.ProjectWithMostUnprocessedDefects[language]}
            {sortMethod === SortingOptions.defects &&
              translations.ProjectWithMostDefects[language]}
            {sortMethod === SortingOptions.processed &&
              translations.ProjectWithMostProcessedDefects[language]}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "flex-end", my: 1 }}>
            <Search sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <Box width={{ xs: 300, sm: 400, md: 500 }}>
              <TextField
                data-testid="project-stats-search"
                fullWidth
                variant="standard"
                placeholder={translations.SearchForProject[language]}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
            </Box>
          </Box>
          <Grid container spacing={2}>
            <SortingButton
              buttonMethod={SortingOptions.defects}
              currentSortingMethod={sortMethod}
              setSortMethod={setSortMethod}
            >
              {translations.TotalDefects[language]}
            </SortingButton>
            <SortingButton
              buttonMethod={SortingOptions.processed}
              currentSortingMethod={sortMethod}
              setSortMethod={setSortMethod}
            >
              {translations.Processed[language]}
            </SortingButton>
            <SortingButton
              buttonMethod={SortingOptions.unprocessed}
              currentSortingMethod={sortMethod}
              setSortMethod={setSortMethod}
            >
              {translations.Unprocessed[language]}
            </SortingButton>
          </Grid>
          <BarChart
            stats={primaryViewStats}
            sortingMethod={sortMethod}
            loading={loading}
          />
          {searchString === "" && (
            <Button onClick={() => setShowMore(!showMore)}>
              {showMore
                ? translations.ShowLess[language]
                : translations.ShowMore[language]}
            </Button>
          )}
          {showMore && searchString === "" && (
            <BarChart
              stats={secondaryViewStats}
              sortingMethod={sortMethod}
              loading={loading}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
