import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useCurrentProject } from "hooks";
import {
  IPoleNavigationData,
  getPoleNavigationData,
} from "views/PoleViewer/api";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

interface IUsePoleNavigation {
  navigatePoleClick: (poleID: number, direction: "next" | "previous") => void;
  poleNavigationData: IPoleNavigationData[];
  poleLabel?: string;
}
export function usePoleNavigation(): IUsePoleNavigation {
  const navigate = useNavigate();
  const project = useCurrentProject();
  const [searchParams] = useSearchParams();
  const currentPoleId = parseInt(searchParams.get("pole") ?? "-1");

  const [poleNavigationData, setPoleNavigationData] = useState<
    IPoleNavigationData[]
  >([]);

  useEffect(() => {
    if (project?.id) {
      getPoleNavigationData(project?.id).then(setPoleNavigationData);
    }
  }, [project?.id]);

  const navigatePoleClick = (
    poleID: number,
    direction: "next" | "previous"
  ) => {
    const index = poleNavigationData.findIndex(
      (pole) => pole.pole_id === poleID
    );

    if (index === -1) {
      toast.error("No structure found");
      return;
    }

    const newIndex = direction === "next" ? index + 1 : index - 1;

    // Ensure newIndex is within valid range
    if (newIndex < 0 || newIndex >= poleNavigationData.length) {
      toast.error("No more structures in this direction");
      return;
    }

    const newPole = poleNavigationData[newIndex];
    if (!newPole?.image_id || !newPole?.pole_id) {
      toast.error("No structure found");
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("pole", newPole.pole_id.toString());
    searchParams.delete("placementID");
    searchParams.delete("componentID");

    navigate(`./${newPole.image_id}/?${searchParams.toString()}`);
  };

  const poleLabel = poleNavigationData.find(
    (pole) => pole.pole_id === currentPoleId
  )?.sorting_key;

  return {
    navigatePoleClick,
    poleNavigationData,
    poleLabel,
  };
}
