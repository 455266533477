import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import { axiosInstance } from "utils/request";
import { EventHint, captureException } from "@sentry/react";
import { toast } from "react-toastify";

// Interface for component props
type Props = {
  onLogin: (value: boolean) => void;
  hidden: boolean;
  id: number;
};

/**
 * GoogleLoginButton component for handling Google login.
 * @param {Props} props - The component props.
 * @returns {JSX.Element} - The rendered component.
 */
export default function GoogleLoginButton({
  onLogin,
  hidden,
  id,
}: Props): JSX.Element {
  const [credentialResponse, setCredentialResponse] =
    useState<CredentialResponse | null>(null);
  const [overridePopupOpen, setOverridePopupOpen] = useState(false);

  async function onSuccess(
    credentialResponse: CredentialResponse,
    override: boolean
  ) {
    setCredentialResponse(credentialResponse);
    try {
      await axiosInstance.post("/auth/oauth/google", {
        configId: id,
        token: credentialResponse.credential,
        overwrite: override,
      });
    } catch (err: any) {
      if (err?.response?.status === 409) {
        setOverridePopupOpen(true);
        return;
      } else {
        const exceptionHint: EventHint = {
          event_id: "googleloginbutton.onerror",
          data: {
            credentialResponse,
          },
        };
        captureException(
          new Error("Failed to login with Google"),
          exceptionHint
        );
        toast.error("Failed to login with Google");
      }
    }
    onLogin(true);
  }

  return (
    <Box
      sx={{
        "& .google-login-button div[role='button']": {
          border: "1px solid rgba(0, 0, 0, 0.5)",
          textTransform: "uppercase",
        },
        mb: hidden ? 0 : 1,
        maxHeight: hidden ? 0 : "45px", // Use maxHeight instead of height
        overflow: "hidden",
        transition: "max-height 0.2s linear", // Corrected transition property
      }}
    >
      <GoogleLogin
        onSuccess={(e) => onSuccess(e, false)}
        width="100%"
        text="continue_with"
        type="standard"
        containerProps={{
          style: {
            height: 45,
          },
        }}
        onError={() => {
          const exceptionHint: EventHint = {
            event_id: "googleloginbutton.onerror",
            data: {
              credentialResponse,
            },
          };
          captureException(
            new Error("Failed to login with Google"),
            exceptionHint
          );
        }}
      />
      <Dialog
        open={overridePopupOpen}
        onClose={() => setOverridePopupOpen(false)}
      >
        <DialogTitle>Email already exists.</DialogTitle>
        <DialogContent>
          Do you want to convert your existing account to a Google account?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOverridePopupOpen(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              if (!credentialResponse) return;
              onSuccess(credentialResponse, true);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
