export default {
  Comment: {
    EN: "Comment",
    SE: "Kommentar",
    NO: "Kommentar",
  },
  CreatedBy: {
    EN: "Created by",
    SE: "Skapad av",
    NO: "Opprettet av",
  },
  UpdatedBy: {
    EN: "Updated by",
    SE: "Uppdaterad av",
    NO: "Oppdatert av",
  },
};
