import { useMemo, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "hooks";
import {
  setThumbnailRegexPattern,
  setThumbnailPoleItemPlacement,
} from "state/actions";
import { IPoleStatus } from "views/PoleViewer/api";

export const categoryOptions = {
  ALL: "all",
  MISSING_CATEGORIES: "missingCategories",
} as const;

export interface IProps {
  components: IPoleStatus[];
}

export function useCategoryFilter({ components }: IProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCategory, setSelectedCategory] = useState<string>(
    categoryOptions.ALL
  );
  const dispatch = useDispatch();

  // Memoized filtering logic
  const filteredComponents = useMemo(() => {
    if (selectedCategory === categoryOptions.ALL) {
      return components;
    }
    if (selectedCategory === categoryOptions.MISSING_CATEGORIES) {
      return components.filter((c) => !c.category_id);
    }
    return components.filter((c) => c.category_id === Number(selectedCategory));
  }, [components, selectedCategory]);

  // Handle category selection and URL param changes
  useEffect(() => {
    const availableCategories = new Set(
      components.map((c) => c.category_id).filter(Boolean)
    );

    // If selected category is ALL or MISSING_CATEGORIES, return early
    if (
      selectedCategory === categoryOptions.ALL ||
      selectedCategory === categoryOptions.MISSING_CATEGORIES
    ) {
      return;
    }

    // Clear search params and dispatch resets
    searchParams.delete("placementID");
    searchParams.delete("boxIds");
    searchParams.delete("componentID");
    dispatch(setThumbnailRegexPattern(null));
    dispatch(setThumbnailPoleItemPlacement(null));
    setSearchParams(searchParams);

    // Reset category if it is not in available categories
    if (!availableCategories.has(Number(selectedCategory))) {
      setSelectedCategory(categoryOptions.ALL);
    }
  }, [components, selectedCategory]);

  return {
    selectedCategory,
    setSelectedCategory,
    filteredComponents,
  };
}
