import { IUserSettings, skyqraftHiddenSetting } from "api/user/userSettings";
import {
  AccessLevel,
  AccessRules,
  IAssignmentStatus,
  IDomain,
  LastLogin,
  Mission,
  OtherUser,
  Region,
  UserRoles,
} from "interfaces";

// @ts-ignore
export interface IState extends IUserSettings {
  accessRules: AccessRules | null;
  accessToken: null | string;
  username: string;
  filters: any;
  loggedIn: boolean;
  realRoles: UserRoles;
  accessLevelOverride: null | AccessLevel;
  otherUsers: OtherUser[];
  missions: Mission[];
  regions: Region[];
  domains: IDomain[];
  lastLogins: LastLogin[];
  assignmentsStatus: IAssignmentStatus[];
  client_naming: boolean;
  maxImageDistanceToMoveMap: number;
  projectsLoading: boolean;
}

export const initialRoles = {
  superuser: false,
  skyqraft_employee: false,
  annotator: false,
  supervisor: false,
};

export const initialState: IState = {
  // @ts-ignore
  id: null,
  accessRules: null,
  email: "",
  accessToken: null,
  language: "EN",
  annotator_color: false,
  client_naming: false,
  username: "",
  name: "",
  loggedIn: false,
  phone_number: "",
  missions: [],
  otherUsers: [],
  regions: [],
  lastLogins: [],
  filters: {},
  domains: [],
  ...initialRoles,
  realRoles: {
    ...initialRoles,
  },
  accessLevelOverride: null,
  assignmentsStatus: [],
  skyqraft_hidden: skyqraftHiddenSetting.hideHidden,
  auto_speed_zoom_in_review_modes: false,
  show_dso_tso: false,
  maxImageDistanceToMoveMap: 50,
  projectsLoading: false,
};

// @ts-ignore
const userReducer = (state: IState = initialState, action): IState => {
  switch (action.type) {
    case "SET_PROJECTS_LOADING": {
      return {
        ...state,
        projectsLoading: action.payload,
      };
    }
    case "SET_SKYQRAFT_HIDDEN": {
      return {
        ...state,
        skyqraft_hidden: action.payload,
      };
    }
    case "SET_MAX_IMAGE_DISTANCE_TO_MOVE_MAP": {
      return {
        ...state,
        maxImageDistanceToMoveMap: action.payload,
      };
    }
    case "SET_AUTO_SPEED_ZOOM_IN_REVIEW_MODES": {
      return {
        ...state,
        auto_speed_zoom_in_review_modes: action.payload,
      };
    }
    case "SET_SHOW_DSO_TSO": {
      return {
        ...state,
        show_dso_tso: action.payload,
      };
    }
    case "RESET_USER": {
      return {
        ...initialState,
      };
    }
    case "SET_LOGGED_IN": {
      return {
        ...state,
        loggedIn: action.payload,
      };
    }
    case "SET_SETTINGS":
      return {
        ...state,
        ...action.payload,
        realRoles: {
          superuser: action.payload.superuser,
          skyqraft_employee: action.payload.skyqraft_employee,
          annotator: action.payload.annotator,
          supervisor: action.payload.supervisor,
        },
      };
    case "SET_ASSIGNMENTS_STATUS": {
      return {
        ...state,
        assignmentsStatus: action.payload,
      };
    }
    case "SET_LAST_LOGINS":
      return {
        ...state,
        lastLogins: action.payload,
      };
    case "SET_USERS": {
      return {
        ...state,
        otherUsers: action.payload,
      };
    }
    case "SET_REGIONS": {
      return {
        ...state,
        regions: action.payload,
      };
    }
    case "SET_MISSIONS":
      return {
        ...state,
        missions: action.payload,
      };
    case "SET_ACCESS_TOKEN":
      return {
        ...state,
        accessToken: action.payload,
      };
    case "SET_LANGUAGE":
      // Send the language to the server
      return {
        ...state,
        language: action.payload,
      };
    case "SET_CLIENT_NAMING":
      return {
        ...state,
        client_naming: action.payload,
      };
    case "SET_ANNOTATOR_COLOR":
      return {
        ...state,
        annotator_color: action.payload,
      };
    case "SET_USERNAME":
      return {
        ...state,
        username: action.payload,
      };
    case "SET_NAME":
      return {
        ...state,
        name: action.payload,
      };
    case "SET_PHONE_NUMBER":
      return {
        ...state,
        phone_number: action.payload,
      };

    case "RESET_TO_REAL_PERMISSIONS":
      return {
        ...state,
        ...state.realRoles,
      };
    case "OVERRIDE_ACCESS_LEVEL":
      return {
        ...state,
        accessLevelOverride: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
