import translations from "translations";
import { ReactElement, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import { useCurrentProject, useDispatch, useLanguage } from "hooks";
import { Info } from "@mui/icons-material";
import Loading from "views/Loading";
import { blurAllImages } from "state/actions";
import { toast } from "react-toastify";

export default function BlurPanel(): ReactElement {
  const [loading, setLoading] = useState(false);
  const currentProject = useCurrentProject();
  const dispatch = useDispatch();
  const { language } = useLanguage();
  const [showPopup, setShowPopup] = useState(false);

  function blur(projectID: number) {
    setLoading(true);
    dispatch(
      blurAllImages(projectID, {
        callback: () => setShowPopup(false),
        onError: (error) =>
          toast.error(
            error?.response?.data?.message || "Failed to blur images"
          ),
        finally: () => setLoading(false),
      })
    );
  }

  return (
    <RoleWrapper keyName="blurAllImages">
      <Paper sx={{ m: 2, p: 2 }}>
        <Stack direction="row" alignSelf="center">
          <Info />
          <Typography>
            {translations.Menu.Tools.BlurImages.InfoBox[language]}
          </Typography>
        </Stack>
      </Paper>
      <Paper sx={{ m: 2, p: 1 }}>
        <Typography>
          {translations.Menu.Tools.BlurImages.BlurNowBox[language]}
        </Typography>
      </Paper>
      <Stack alignItems="center" justifyContent="center">
        <Button onClick={() => setShowPopup(true)} variant="contained">
          Start Blurring
        </Button>
      </Stack>

      <Dialog open={showPopup} onClose={() => setShowPopup(false)}>
        <DialogTitle data-testid="BlurDialogTitle">
          {translations.Menu.Tools.BlurImages.Tooltip[language]}
        </DialogTitle>
        <DialogActions>
          <Button
            disabled={loading}
            data-testid="BlurDialogItemNo"
            onClick={() => setShowPopup(false)}
          >
            {translations.Menu.Tools.BlurImages.No[language]}
          </Button>
          <Button
            endIcon={loading && <Loading relative size={20} color="black" />}
            disabled={!currentProject?.id || loading}
            data-testid="BlurDialogItemYes"
            // @ts-ignore
            onClick={() => blur(currentProject.id)}
          >
            {translations.Menu.Tools.BlurImages.Yes[language]}
          </Button>
        </DialogActions>
      </Dialog>
    </RoleWrapper>
  );
}
