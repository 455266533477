export const actionTypes = {
  SET_LOADED_KEY: "localStorage/SET_LOADED_KEY",
};

export interface IState {
  loadedKeys: string[];
}

export const initialState: IState = {
  loadedKeys: [],
};

const localStorageReducer = (
  state: IState = initialState,
  action = { type: null, payload: null }
) => {
  switch (action.type) {
    // @ts-ignore
    case actionTypes.SET_LOADED_KEY:
      return {
        ...state,
        loadedKeys: [...state.loadedKeys, action.payload],
      };

    default:
      return state;
  }
};

export const setLoadedKey = (payload: string) => ({
  type: actionTypes.SET_LOADED_KEY,
  payload,
});

export default localStorageReducer;
