import { Typography } from "@mui/material";
import { useSelector } from "hooks";
import React from "react";

import translations from "./translation";
import { useLanguage } from "hooks";
import { operationsEmail } from "./constants";
import Layout from "./Layout";

interface IProps {
  children: React.ReactNode;
}

export default function ProjectsExistsWrapper({ children }: IProps) {
  // This component verifies that projects exists.
  // If no projects exists, then a friendly message is shown.
  // If projects exists, then the children are rendered.
  const { language } = useLanguage();
  const projects = useSelector((state) => state.user.missions);
  const projectsLoading = useSelector((state) => state.user.projectsLoading);

  if (projectsLoading) {
    return <Layout loading title={translations.LoadingProjects[language]} />;
  }
  if (projects.length === 0) {
    return (
      <Layout title={translations.NoProjectsFound[language]}>
        <Typography textAlign="start" width="100%">
          {translations.NoProjectsFoundDescriptionOne[language]}
        </Typography>
        <Typography textAlign="start" width="100%">
          {translations.NoProjectsFoundDescriptionTwo[language]}{" "}
          <a href={`mailto:${operationsEmail}`}>{operationsEmail}</a>
        </Typography>
      </Layout>
    );
  }

  return <>{children}</>;
}
