export default {
  LoadingProjects: {
    EN: "Loading projects",
    SE: "Laddar projekt",
    NO: "Laster inn prosjekter",
  },
  NoProjectsFound: {
    EN: "No projects found",
    SE: "Inga projekt hittades",
    NO: "Ingen prosjekter funnet",
  },
  NoProjectsFoundDescriptionOne: {
    EN: "We could not find any projects connected to your account. Please contact your group administrator to gain access to projects.",
    SE: "Vi kunde inte hitta några projekt kopplade till ditt konto. Kontakta din gruppadministratör för att få tillgång till projekt.",
    NO: "Vi fant ingen prosjekter tilknyttet kontoen din. Vennligst kontakt gruppeadministratoren din for å få tilgang til prosjekter.",
  },
  NoProjectsFoundDescriptionTwo: {
    EN: "If you require assistance, please contact operations at",
    SE: "Om du behöver hjälp, kontakta operations på",
    NO: "Hvis du trenger hjelp, vennligst kontakt operations på",
  },
};
