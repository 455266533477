import { Stack } from "@mui/material";
import { RoleWrapper } from "components";
import ToggleVisibilityButton from "./ToggleVisibilityButton";
import VerifyAllButton from "./VerifyAllButton";
import NoneDefectVerificationButton from "./NoneDefectVerificationButton";

export default function ListNavBar() {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        zIndex: 10,
        width: "100%",
        height: "45px",
        boxShadow: "3px 3px 3px 0px rgba(0, 0, 0, 0.1)",
        borderRight: "3px solid rgba(0, 0, 0, 0.4)",
      }}
    >
      <Stack direction="row" spacing={2}>
        <ToggleVisibilityButton />
        <RoleWrapper keyName="internalAnnotationTools">
          <VerifyAllButton />
          <NoneDefectVerificationButton />
        </RoleWrapper>
      </Stack>
    </Stack>
  );
}
