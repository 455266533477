export default {
  Project: {
    EN: "Project",
    SE: "Projekt",
    NO: "Prosjekt",
  },
  ProjectWithMostUnprocessedDefects: {
    EN: "Project with most unprocessed defects",
    SE: "Projekt med flest obearbetade skador",
    NO: "Prosjekt med flest ubehandlede avvikelser",
  },
  ProjectWithMostDefects: {
    EN: "Project with most defects",
    SE: "Projekt med flest skador",
    NO: "Prosjekt med flest avvikelser",
  },
  ProjectWithMostProcessedDefects: {
    EN: "Project with most processed defects",
    SE: "Projekt med flest behandlade skador",
    NO: "Prosjekt med flest behandlede avvikelser",
  },
  TotalDefects: {
    EN: "Total defects",
    SE: "Totala skador",
    NO: "Totalt antall avvikelser",
  },
  SearchForProject: {
    EN: "Search for project using year or name",
    SE: "Sök efter projekt med år eller namn",
    NO: "Søk etter prosjekt ved å bruke år eller navn",
  },
  Processed: {
    EN: "Processed",
    NO: "Behandlet",
    SE: "Behandlad",
  },
  Unprocessed: {
    EN: "Unprocessed",
    SE: "Obehandlade",
    NO: "Ubehadlet",
  },
  SortBy: {
    EN: "Sort by",
    SE: "Sortera efter",
    NO: "Sorter etter",
  },
  ShowMore: {
    EN: "Show more",
    SE: "Visa mer",
    NO: "Vis mer",
  },
  ShowLess: {
    EN: "Show less",
    SE: "Visa mindre",
    NO: "Vis mindre",
  },
  TotalDefectsFound: {
    EN: "Total defects found",
    SE: "Antal skador hittade",
    NO: "Antall avvikelser funnet",
  },
  TotalDefectsProcessed: {
    EN: "Total defects processed",
    SE: "Antal skador behandlade",
    NO: "Antall avvikelser behandlet",
  },
  UnprocessedDefects: {
    EN: "Unprocessed defects",
    SE: "Obehandlade skador",
    NO: "Ubehandlede avvikelser",
  },
} as const;
