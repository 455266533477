import { useEffect } from "react";
import { authorizedPut } from "utils/request";
import { useDispatch, useSelector } from "hooks";
import { updatePoles } from "state/actions";

export default function PoleViewListener() {
  const imageMeta = useSelector((state) => state.image.current);
  const dispatch = useDispatch();

  async function waitNSeconds(n: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, n * 1000);
    });
  }

  async function markPoleAsViewed() {
    // Don't run if we dont have image or pole
    if (!imageMeta) return;
    if (!imageMeta.pole_id) return;

    // Wait for a second
    await waitNSeconds(1);

    // Check if the user is still on the same image
    const stillSameImage = window.location.pathname.includes(
      JSON.stringify(imageMeta.id)
    );

    // If the user is still on the same image, mark the pole as viewed
    if (stillSameImage) {
      await authorizedPut(`/poles/${imageMeta.pole_id}/viewed`);
      dispatch(updatePoles());
    }
  }

  // Mark the pole as viewed
  useEffect(() => {
    markPoleAsViewed();
  }, [imageMeta]);
  return <></>;
}
