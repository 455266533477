import { Link as LinkIcon } from "@mui/icons-material";
import { Chip, Stack, Tooltip, IconButton } from "@mui/material";
import { v4 as uuid4 } from "uuid";
import { EventHint, captureException } from "@sentry/react";
import { toast } from "react-toastify";
import { useCurrentProject } from "hooks";
import { ISteelwork } from "api/image/annotation";

type Props = {
  steelwork: ISteelwork;
};

const openSteelworkInNewTab = (
  project_id: number,
  image_id: number,
  image_object_id: number
) => {
  const url = new URL(
    `${location.origin}/${project_id}/${image_id}${location.search}`
  );
  url.searchParams.set("boxIds", image_object_id.toString());

  let otherTabName = null;

  if (!window.name.includes("tab-id")) {
    // If we do our first click in the initial tab
    const uuid = uuid4();
    window.name = `tab-id-${uuid}-start`;
    otherTabName = `tab-id-${uuid}-target`;
  } else if (window.name.includes("-start")) {
    // If we do our second click in the initial tab
    otherTabName = window.name.replace("-start", "-target");
  } else if (window.name.includes("-target")) {
    // If we click in our target tab
    otherTabName = window.name.replace("-target", "-start");
  } else {
    // If we have an invalid window name
    const error = new Error("Invalid window name");
    const exceptionHint: EventHint = {
      event_id: "steelworkInfo.openLinkInNewTab",
      originalException: error,
      data: {
        windowName: window.name,
        otherTabName: otherTabName,
      },
    };
    captureException(error, exceptionHint);
    toast.error(
      "An error occurred while opening the link in a new tab. Please try again."
    );
    return;
  }

  window.open(url.toString(), otherTabName);
};

export function SteelworkInfo({ steelwork }: Props) {
  const currentProject = useCurrentProject();
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="flex-end"
    >
      {steelwork?.direction?.name && (
        <Chip
          title="Primary Direction"
          label={steelwork?.direction?.name}
          size="small"
        />
      )}
      {steelwork?.second_direction?.name && (
        <Chip
          title="Secondary Direction"
          label={steelwork.second_direction.name}
          size="small"
        />
      )}

      {steelwork?.steel_grading?.id && (
        <Chip
          sx={{
            backgroundColor: steelwork.steel_grading?.color,
          }}
          title="Steel Grading"
          label={steelwork.steel_grading?.name?.slice(0, 2)}
          size="small"
        />
      )}
      {steelwork?.bolt_condition?.id && (
        <Chip
          sx={{
            backgroundColor: steelwork.bolt_condition?.color,
          }}
          title="Bolt condition"
          label={steelwork.bolt_condition?.name?.slice(0, 2)}
          size="small"
        />
      )}
      {steelwork?.child_link?.id && (
        <Tooltip title="Go to second angle">
          <IconButton
            onClick={() => {
              if (
                currentProject?.id &&
                steelwork.child_link?.image_id &&
                steelwork.child_link?.image_object_id
              ) {
                openSteelworkInNewTab(
                  currentProject.id,
                  steelwork.child_link?.image_id,
                  steelwork.child_link?.image_object_id
                );
              }
            }}
          >
            <LinkIcon />
          </IconButton>
        </Tooltip>
      )}
      {steelwork?.parent_link?.id && (
        <Tooltip title="Go to primary angle">
          <IconButton
            onClick={() => {
              if (
                currentProject?.id &&
                steelwork.parent_link?.image_id &&
                steelwork.parent_link?.image_object_id
              ) {
                openSteelworkInNewTab(
                  currentProject.id,
                  steelwork.parent_link.image_id,
                  steelwork.parent_link?.image_object_id
                );
              }
            }}
          >
            <LinkIcon />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
}
