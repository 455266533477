import { useState, useEffect, ReactNode, useCallback, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IContextValue, context } from "./context";
import {
  getAnnotationsFromAPI,
  saveAnnotations,
  getSteelworkDropdownValues,
} from "views/AnnotationTool/api";
import { useCurrentProject, useSelector } from "hooks";
import {
  setSavedObjectTypeId,
  setSavedDefectTypeId,
} from "state/actions/image";
import { context as navigationContext } from "providers/NavigationProvider";
import { captureException, EventHint } from "@sentry/react";
import isEqual from "lodash/isEqual";
import { useSearchParams } from "react-router-dom";
import { cloneDeep } from "lodash";

interface IProviderProps {
  children: ReactNode;
  imageID: number;
}

type ISpecialAnnotation = Awaited<
  ReturnType<typeof getAnnotationsFromAPI> // @ts-ignore
>[number];

type ISpecialSteelworkDropdown = Awaited<
  ReturnType<typeof getSteelworkDropdownValues>
>;

function AnnotationProvider({ children, imageID }: IProviderProps) {
  // Set up some states to keep track of
  const currentProject = useCurrentProject();
  const [searchParams] = useSearchParams();
  const [annotations, setAnnotations] = useState<ISpecialAnnotation[]>([]);
  const objectTypes = useSelector((state) => state.objects.objectTypes);
  const [originalAnnotations, setOriginalAnnotations] = useState<
    ISpecialAnnotation[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [saveFeedback, setSaveFeedback] = useState<string>("");
  const [selectedAnnotation, setSelectedAnnotationState] = useState<
    string | null
  >(null);

  const [steelworkDropdownValues, setSteelworkDropdownValues] =
    useState<ISpecialSteelworkDropdown>({
      directions: [],
      second_directions: [],
      bolt_conditions: [],
      steel_gradings: [],
    });

  const [steelworkLegacyDirectionId, setSteelworkLegacyDirectionId] = useState<
    number | null
  >();

  const [filteredObjectTypes, setFilteredObjectTypes] = useState([]);
  const [steelworkObjectTypes, setSteelworkObjectTypes] = useState([]);

  const { lastURL } = useContext(navigationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const savedObjectTypeId = useSelector(
    (state) => state.image.savedObjectTypeId
  );
  const savedDefectTypeId = useSelector(
    (state) => state.image.savedDefectTypeId
  );

  //Handle the update data and state logic
  const getAnnotationsFunction = useCallback(
    async function getAnnotationsFunction() {
      // Update the state
      setLoading(true);
      if (objectTypes.length === 0) return;
      const response = await getAnnotationsFromAPI(
        // @ts-ignore
        currentProject.id,
        imageID,
        objectTypes
      );
      // @ts-ignore
      setAnnotations(response);
      // Decouple the response from the original annotations
      // @ts-ignore
      setOriginalAnnotations(cloneDeep(response));
      setLoading(false);
    },
    // @ts-ignore
    [currentProject.id, imageID, objectTypes]
  );

  const getSteelworkDropdown = useCallback(
    async function getSteelworkDropdown() {
      try {
        // @ts-ignore
        const response = await getSteelworkDropdownValues(currentProject.id);
        const legacy_direction_id = response?.directions.find(
          (d) => d.legacy_data === true
        );
        setSteelworkLegacyDirectionId(legacy_direction_id?.id);
        setSteelworkDropdownValues(response);
      } catch (error) {
        const exceptionHint: EventHint = {
          event_id:
            "annotationTool.Provider.getSteelworkDropdownValues.request",
          data: {
            // @ts-ignore
            project_id: currentProject.id,
          },
        };
        captureException(error, exceptionHint);
      }
    },
    []
  );

  useEffect(() => {
    getAnnotationsFunction();
    getSteelworkDropdown();
  }, [imageID, objectTypes, getAnnotationsFunction]);

  useEffect(() => {
    const steelwork = objectTypes.find((o) => o.steelwork);

    const objectTypesWithoutSteelwork = objectTypes.filter(
      (e) => e.id !== steelwork?.id
    );
    const objectTypesWithSteelwork = objectTypes.filter(
      (e) => e.id === steelwork?.id
    );
    // @ts-ignore
    setFilteredObjectTypes(objectTypesWithoutSteelwork);
    // @ts-ignore
    setSteelworkObjectTypes(objectTypesWithSteelwork);
  }, [objectTypes]);

  async function updateAnnotation(
    annotationID: string,
    data: ISpecialAnnotation
  ) {
    const newAnnotations = annotations.map((annotation) => {
      if (annotation.id === annotationID) {
        const returnData = annotationsAutoChanges(annotation, data);
        return returnData;
      }
      return annotation;
    });
    setAnnotations(newAnnotations);
  }

  async function deleteAnnotation(annotationID: string) {
    const newAnnotations = annotations.filter((annotation) => {
      if (annotation.id === annotationID) {
        return false;
      }
      return true;
    });
    setAnnotations(newAnnotations);
  }

  // This function is where we can put logic to handle specific automatic changes
  // to the annotation data based on the changes made to the annotation
  function annotationsAutoChanges(
    oldAnnotation: ISpecialAnnotation,
    newAnnotation: ISpecialAnnotation
  ) {
    //Size and position changes
    if (
      oldAnnotation.x !== newAnnotation.x ||
      oldAnnotation.y !== newAnnotation.y ||
      oldAnnotation.w !== newAnnotation.w ||
      oldAnnotation.h !== newAnnotation.h ||
      oldAnnotation.rotation !== newAnnotation.rotation
    ) {
      //go thru all the workflow status and set them to 2 if they are 1
      for (let i = 0; i < oldAnnotation.workflow_status.length; i++) {
        if (oldAnnotation.workflow_status[i] === 1) {
          newAnnotation.workflow_status[i] = 2;
        }
      }
      return newAnnotation;
    }

    // Update the objectHasNoDefect property if the types have changed
    if (
      oldAnnotation.objectHasNoDefect &&
      !isEqual(oldAnnotation.types, newAnnotation.types)
    ) {
      newAnnotation.objectHasNoDefect = false;
    }

    return newAnnotation;
  }

  async function _saveAnnotations(
    newAnnotations: ISpecialAnnotation[],
    oldAnnotations: ISpecialAnnotation[]
  ) {
    setLoading(true);
    setSaveFeedback("loading");
    const saveResponse = await saveAnnotations(
      imageID,
      newAnnotations,
      oldAnnotations
    );
    setSaveFeedback(saveResponse);
    const response = await getAnnotationsFromAPI(
      // @ts-ignore
      currentProject.id,
      imageID,
      objectTypes
    );
    // @ts-ignore
    setAnnotations(response);
    // @ts-ignore
    setOriginalAnnotations(cloneDeep(response));
    setLoading(false);

    setTimeout(() => {
      setSaveFeedback("");
    }, 1000);
    return saveResponse;
  }

  function exit() {
    if (lastURL) {
      navigate(-1);
    } else {
      navigate(`..${location.search}`);
    }
  }

  function resetAnnotations() {
    setAnnotations(originalAnnotations);
  }

  function isSelectedAnnotation(annotationID: string) {
    return annotationID === selectedAnnotation;
  }

  // Update selectedAnnotation when the searchParams change
  useEffect(() => {
    const boxIDs = searchParams.get("boxIds")?.split(",") || [];
    setSelectedAnnotationState(boxIDs[0]);
  }, [searchParams]);

  const setSelectedAnnotation = (id: string | null) => {
    let boxIDs = searchParams.get("boxIds")?.split(",") || [];

    if (id) {
      boxIDs = boxIDs.includes(id) ? [] : [id];
    } else {
      boxIDs = [];
    }

    if (boxIDs.length === 0) {
      searchParams.delete("boxIds");
    } else {
      searchParams.set("boxIds", boxIDs.join(","));
    }
    // Use navigate with replace option to update the URL without changing the history
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  async function setHoveredAnnotation(annotationID: string | null) {
    const newAnnotations = annotations.map((annotation) => {
      if (
        annotation.id === annotationID &&
        isSelectedAnnotation(annotation.id)
      ) {
        annotation.hover = true;
      } else {
        annotation.hover = false;
      }
      return annotation;
    });
    setAnnotations(newAnnotations);
  }

  function _setSavedObjectTypeId(typeID: number) {
    dispatch(setSavedObjectTypeId(typeID));
  }

  function _setSavedDefectTypeId(typeID: number) {
    dispatch(setSavedDefectTypeId(typeID));
  }

  const payload: IContextValue = {
    annotations: annotations,
    originalAnnotations: originalAnnotations,
    loading,
    error: undefined,
    objectTypes,
    saveFeedback,
    // @ts-ignore
    steelworkLegacyDirectionId: steelworkLegacyDirectionId,
    // @ts-ignore
    steelworkDropdownValues,
    savedObjectTypeId,
    savedDefectTypeId,
    filteredObjectTypes,
    steelworkObjectTypes,
    selectedAnnotation,
    setSavedObjectTypeId: _setSavedObjectTypeId,
    setSavedDefectTypeId: _setSavedDefectTypeId,
    updateAnnotation: updateAnnotation,
    saveAnnotations: _saveAnnotations,
    setAnnotations: setAnnotations,
    setOriginalAnnotations: setOriginalAnnotations,
    resetAnnotations: resetAnnotations,
    deleteAnnotation: deleteAnnotation,
    setSelectedAnnotation: setSelectedAnnotation,
    getAnnotationsFunction: getAnnotationsFunction,
    setHoveredAnnotation: setHoveredAnnotation,
    exit,
    isSelectedAnnotation,
  };

  return <context.Provider value={payload}>{children}</context.Provider>;
}

export default AnnotationProvider;
