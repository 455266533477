import { useFlags } from "launchdarkly-react-client-sdk";
import Profile from "./Profile";

export default function FlagProtectedProfile() {
  const { userProfileInNavbar } = useFlags();

  if (!userProfileInNavbar) {
    return null;
  }
  return <Profile />;
}
