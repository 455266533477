import { ReactElement, useState } from "react";
import { Box, Collapse, Stack, IconButton } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import MapToggle from "components/MapToggle";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Home,
  LocationOn,
} from "@mui/icons-material";
import { ImageMeta } from "interfaces";
import { useLocation } from "react-router-dom";

import "./style.scss";
import { panToImageOnMap, scrollToImageInThumbnails } from "utils/image";

interface IProps {
  gmap: google.maps.Map;
  imageMeta: ImageMeta;
}

export default function InfoWindow({ gmap, imageMeta }: IProps): ReactElement {
  const location = useLocation();

  const annotationToolOpen = location.pathname.includes("/annotate");
  const supervisorReviewOpen = location.pathname.includes(
    "/super-verify-tp-bbs"
  );
  const machineReviewOpen = location.pathname.includes("/verify-ml");
  const falsepositiveReviewOpen = location.pathname.includes(
    "/super-verify-fp-images"
  );
  const falseReviewOpen = location.pathname.includes("/super-verify-fp-bbs");

  const modeOpen =
    annotationToolOpen ||
    supervisorReviewOpen ||
    machineReviewOpen ||
    falsepositiveReviewOpen ||
    falseReviewOpen;

  const [collapsed, setCollapsed] = useState<boolean>(false);

  // https://app.launchdarkly.com/projects/default/flags/map-panels-refactor/targeting?env=test&env=production&selected-env=test
  const { mapPanelsRefactor } = useFlags();

  return (
    <div id="infoWindow">
      <Stack alignItems="flex-end" spacing={1}>
        <Collapse in={collapsed} orientation="horizontal" collapsedSize={40}>
          <Stack direction="row" alignItems="flex-start">
            <IconButton
              disableFocusRipple
              disableRipple
              onClick={() => setCollapsed(!collapsed)}
              sx={{
                bgcolor: "rgba(255,255,255,0.75)",
                "&:hover": {
                  bgcolor: "#FFF",
                },
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              {collapsed ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <Collapse in={collapsed} orientation="vertical" collapsedSize={40}>
              <Box
                sx={{
                  bgcolor: "rgba(255,255,255,0.75)",
                  borderRadius: 2,
                  borderTopLeftRadius: 0,
                }}
              >
                <MapToggle />
              </Box>
            </Collapse>
          </Stack>
        </Collapse>
        <IconButton
          sx={{
            bgcolor: "rgba(255,255,255,0.75)",
            "&:hover": {
              bgcolor: "#FFF",
            },
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
          onClick={() => {
            if (gmap) {
              const bounds = new google.maps.LatLngBounds();
              // biome-ignore lint/complexity/noForEach: Google maps is not enumerable
              gmap.data.forEach((feature) => {
                // @ts-ignore
                feature.getGeometry().forEachLatLng((latlng) => {
                  bounds.extend(latlng);
                });
              });
              gmap.fitBounds(bounds);
            }
          }}
        >
          <Home />
        </IconButton>
        {!!imageMeta?.lat && (
          <IconButton
            sx={{
              bgcolor: "rgba(255,255,255,0.75)",
              "&:hover": {
                bgcolor: "#FFF",
              },
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            onClick={() => {
              panToImageOnMap(
                gmap,
                { lat: imageMeta.lat, lng: imageMeta.lng },
                { mapPanelsRefactor, viewerIsOpen: modeOpen }
              );
              scrollToImageInThumbnails(imageMeta.id);
            }}
          >
            <LocationOn />
          </IconButton>
        )}
      </Stack>
    </div>
  );
}
