import { Select, MenuItem, SelectChangeEvent } from "@mui/material";

import translations from "translations";
import { useLanguage } from "hooks";
import "./style.scss";
import SettingsContainer from "../SettingsContainer";

interface IProps {
  size: number;
}

function LanguageSelector({ size }: IProps) {
  const { language, setLanguage } = useLanguage();

  const onChange = (event: SelectChangeEvent<string>) => {
    const newLanguage = event.target.value;
    if (newLanguage === "EN" || newLanguage === "SE" || newLanguage === "NO") {
      setLanguage(newLanguage);
    }
  };

  const options = [
    { value: "EN", label: "English" },
    { value: "SE", label: "Swedish" },
    { value: "NO", label: "Norwegian" },
  ];

  return (
    <SettingsContainer
      title={translations.Menu.Settings.Language[language]}
      size={size}
    >
      <Select
        sx={{ ml: 2 }}
        fullWidth={true}
        size="small"
        id="language-selector"
        data-testid="settings.language"
        value={language}
        onChange={onChange}
      >
        {options.map((option, i) => (
          <MenuItem
            key={i.toString()}
            value={option.value}
            data-testid={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </SettingsContainer>
  );
}
export default LanguageSelector;
