import { axiosInstance, extractArrayStream } from "utils/request";
import { ListViewMarker, Mission, ImageMeta } from "interfaces";
import { listModes, IDataGroup, IData, ReviewedImage } from "./constants";

export async function fetchReviewedImages(args: {
  projectId: number;
  severityIds: number[];
  objectTypeIds: number[];
}): Promise<ReviewedImage[]> {
  const { projectId, severityIds, objectTypeIds } = args;

  const params = {
    ...(severityIds && { severityIds: severityIds.join(",") }),
    ...(objectTypeIds && { objectTypeIds: objectTypeIds.join(",") }),
  };

  const response = await axiosInstance.get<ReadableStream>(
    `/project/${projectId}/image/reviewed`,
    {
      params,
      headers: {
        "content-type": "application/json",
      },
      responseType: "stream",
      adapter: "fetch", // Fetch adapter is better for streaming
    }
  );
  const data = await extractArrayStream(response.data);

  return data;
}

export async function postReviewedImage(args: {
  projectId: number;
  imageId: number;
  severityIds: number[];
  objectTypeIds: number[];
}): Promise<{ data: ReviewedImage[] }> {
  const { projectId, imageId, severityIds, objectTypeIds } = args;

  const body = {
    ...(severityIds?.length && { severityIds }),
    ...(objectTypeIds?.length && { objectTypeIds }),
  };

  return await axiosInstance.post(
    `/project/${projectId}/image/${imageId}/reviewed`,
    body
  );
}

export async function deleteReviewedImage(args: {
  projectId: number;
  imageId: number;
  ids: number[];
}) {
  await axiosInstance.delete(
    `/project/${args.projectId}/image/${args.imageId}/reviewed`,
    {
      params: { ids: args.ids.join(",") },
    }
  );
}

export async function getMarkers(args: {
  projectId: number;
  skyqraftHidden: string;
}): Promise<ListViewMarker[]> {
  const params = new URLSearchParams(window.location.search);
  params.set("filteredOnly", "true");
  params.set("skyqraftHidden", args.skyqraftHidden);

  const response = await axiosInstance.get<ReadableStream>(
    "/image/list-markers",
    {
      params,
      headers: {
        MissionID: args.projectId,
        "content-type": "application/json",
      },
      responseType: "stream",
      adapter: "fetch", // Fetch adapter is better for streaming
    }
  );
  const data = await extractArrayStream(response.data);

  return data;
}

export const formatDataGroup = (
  listMode: number,
  markers: ListViewMarker[],
  prevMarkers: ListViewMarker[]
) => {
  switch (listMode) {
    case listModes.IMAGES:
      return markers.reduce((acc: IDataGroup, marker) => {
        acc[marker.id] = {
          imageIds: [marker.id],
          markers: [marker],
        };
        return acc;
      }, {});
    case listModes.POLES:
      return markers.reduce((acc: IDataGroup, marker) => {
        const { client_pole_id, pole_id } = marker;

        const clientPoleId = parseInt(client_pole_id || "-1");
        const poleId = parseInt(pole_id || "-1");
        const poleIdLabel = clientPoleId > -1 ? clientPoleId : poleId;

        if (!acc[poleIdLabel]) {
          acc[poleIdLabel] = {
            poleId,
            imageIds: [marker.id],
            markers: [marker],
          };
        } else {
          acc[poleIdLabel] = {
            ...acc[poleIdLabel],
            imageIds: [...acc[poleIdLabel].imageIds, marker.id],
            markers: [...acc[poleIdLabel].markers, marker],
          };
        }
        return acc;
      }, {});
    case listModes.PREV_YEAR:
      return prevMarkers.reduce((acc: IDataGroup, marker) => {
        const { client_pole_id } = marker;

        if (!client_pole_id) {
          return acc;
        }
        const clientPoleId = parseInt(client_pole_id);

        if (!acc[clientPoleId]) {
          const currentImageIds = markers
            .filter(
              (m) =>
                m.client_pole_id === client_pole_id &&
                !m.pole_previous_year_defects_copied
            )
            .map((m) => m.id);

          if (!currentImageIds.length) {
            return acc;
          }

          acc[clientPoleId] = {
            clientPoleId,
            currentImageIds,
            imageIds: [marker.id],
            markers: [marker],
          };
        } else {
          acc[clientPoleId] = {
            ...acc[clientPoleId],
            imageIds: [...acc[clientPoleId].imageIds, marker.id],
            markers: [...acc[clientPoleId].markers, marker],
          };
        }
        return acc;
      }, {});
    default:
      return {};
  }
};

export const getPrevAreaProject = (args: {
  projects: Mission[] | null;
  currentProject: Mission | null;
}) => {
  const { projects, currentProject } = args;

  if (!projects || !currentProject) return;

  const prevProjects = projects
    .filter(
      (project) =>
        project.area?.id === currentProject?.area?.id &&
        !project.deleted &&
        project.year < currentProject?.year
    )
    .sort((a, b) => (new Date(a.timestamp) < new Date(b.timestamp) ? 1 : -1));

  return prevProjects[0];
};

export const checkIfImageExistsInData = (args: {
  data: IData;
  imageId: number;
  currentImageMeta: ImageMeta | null;
}) => {
  const { data, imageId, currentImageMeta } = args;

  if (
    data.imageIds.includes(imageId) ||
    data.currentImageIds?.includes(imageId)
  ) {
    return true;
  }
  // Fallback to pole comparison if no image matches
  if (currentImageMeta?.id !== imageId) {
    return false;
  }
  if (data.poleId && data.poleId === currentImageMeta?.pole_id) {
    return true;
  }
  // Finally fallback to client_pole_id if nothing else works
  const currentImageClientPoleId = parseInt(
    currentImageMeta?.pole_client_id || "0"
  );

  if (
    currentImageClientPoleId &&
    currentImageClientPoleId === data.clientPoleId
  ) {
    return true;
  }
  return false;
};
