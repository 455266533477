import React from "react";
import { Card, Grid, Stack, Typography } from "@mui/material";

interface IBoxProps {
  title: string;
  size: number;
  children: React.ReactNode;
  padding?: number;
}
export function BoxColumn({ title, children, size, padding }: IBoxProps) {
  return (
    <Grid item xs={size}>
      <Card
        sx={{ position: "relative", mx: 0.5, height: "100%" }}
        elevation={3}
      >
        <Typography
          variant="caption"
          sx={{
            px: 1,
            background: "rgba(0,0,0,0.1)",
            display: "flex",
          }}
        >
          {title}
        </Typography>
        <Stack
          direction="column"
          spacing={0}
          sx={{ height: "100%", padding: padding }}
        >
          {children}
        </Stack>
      </Card>
    </Grid>
  );
}
