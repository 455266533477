export default {
  FilterInfo: {
    EN: "Utilize the filters in the left navigation menu to precisely tailor the content displayed in your report.",
    NO: "Bruk filtrene i venstre navigasjonsmeny for å tilpasse innholdet som vises i rapporten dine.",
    SE: "Använd filtren i den vänstra navigationsmenyn för att exakt anpassa innehållet som visas i din rapport.",
  },
  NoFilterDefaultInfo: {
    EN: "If no filters are set, the report will contain critical and issue defects.",
    NO: "Hvis ingen filtre er satt, vil rapporten inneholde kritiske og problematiske feil.",
    SE: "Om inga filter är inställda kommer rapporten att innehålla kritiska och problematiska fel.",
  },
} as const;
