import { Link } from "@mui/material";

type Props = {
  title: string;
  active?: boolean;
  onClick: () => void;
};

export default function ProfileMenuTabButton({
  title,
  active,
  onClick,
}: Props) {
  return (
    <Link
      onClick={() => {
        onClick();
      }}
      sx={{
        cursor: "pointer",
        textDecoration: "none",
        color: "#040404",
        background: active ? "#E8E8E8" : "transparent",
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        px: 2,
        py: 1,
        "&:hover": {
          background: "#DEDEDE",
        },
      }}
    >
      {title}
    </Link>
  );
}
