import { ReactElement, useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { useLocation, useSearchParams } from "react-router-dom";
import { Typography, Button, Alert } from "@mui/material";
import translations from "translations";
import { authorizedGet, axiosInstance } from "utils/request";
import { RoleWrapper } from "components";
import {
  useLanguage,
  useCurrentProject,
  useSelector,
  useDetectionCount,
} from "hooks";
import { dateTimeToString } from "utils/utils";
import TypeSelectFilter from "components/Filter/FilterComponents/TypeSelectFilter";

const languageOptions = [
  { label: "English", value: "EN" },
  { label: "Swedish", value: "SE" },
];

export default function InventoryReport(): ReactElement {
  const demoMode = useSelector((state) => state.ui.demoMode);
  const { language } = useLanguage();
  const { setActive } = useDetectionCount();

  const project = useCurrentProject();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedLanguage, setSelectedLanguage] = useState(
    languageOptions[0].value
  );
  const [count, setCount] = useState(0);

  useEffect(() => {
    updateCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.toString()]);

  useEffect(() => {
    setActive(true);
  }, []);

  const updateCount = () => {
    // @ts-ignore
    authorizedGet<{ count }>(`/report/inventory/count${location.search}`).then(
      (response) => {
        setCount(response.count);
      }
    );
  };

  async function downloadReport(reportType: "excel" | "csv") {
    const message =
      reportType === "excel"
        ? translations.Menu.Tools.InventoryReport.GeneratingExcel[language]
        : translations.Menu.Tools.InventoryReport.GeneratingCSV[language];
    const toastPointer = toast.info(message, {
      autoClose: false,
    });

    // Send the request to the lambda endpoint
    // instead of the normal API endpoint
    // to avoid timeout issues
    const response = await axiosInstance.get(
      `/report/inventory/${reportType}${location.search}`,
      {
        // @ts-ignore
        headers: { MissionID: project.id },
        params: {
          demo: demoMode,
          lang: selectedLanguage,
        },
        responseType: "blob",
      }
    );

    const responseData = response.data;

    const updateMessage =
      reportType === "excel"
        ? translations.Menu.Tools.InventoryReport.ExcelGenerated[language]
        : translations.Menu.Tools.InventoryReport.CSVGenerated[language];
    toast.update(toastPointer, {
      render: updateMessage,
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
    });

    const url = window.URL.createObjectURL(
      new Blob([responseData], { type: "application/octet-stream" })
    );

    const link = document.createElement("a");
    link.href = url;
    const d = new Date();
    const timeName = dateTimeToString(d);
    const fileExtension = reportType === "excel" ? "xlsx" : "csv";
    // @ts-ignore
    const fileName = `object_report_project_${project.name}_${project.year}_${timeName}.${fileExtension}`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  }

  async function downloadExcel() {
    try {
      await downloadReport("excel");
    } catch (e) {
      console.error(e);
      toast.error(
        translations.Menu.Tools.InventoryReport.ExcelFailed[language]
      );
    }
  }

  async function downloadCSV() {
    try {
      await downloadReport("csv");
    } catch (e) {
      console.error(e);
      toast.error(translations.Menu.Tools.InventoryReport.CSVFailed[language]);
    }
  }

  return (
    <RoleWrapper keyName="inventoryReport">
      <div style={{ margin: 10 }}>
        <h2>{translations.Menu.Tools.InventoryReport.Download[language]}</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h3>{translations.Menu.Tools.InventoryReport.Types[language]}</h3>
        </div>
        {searchParams.has("area") && (
          <Alert
            action={
              <Button
                color="info"
                size="small"
                variant="contained"
                onClick={() => {
                  searchParams.delete("area");
                  setSearchParams(searchParams, { replace: true });
                }}
              >
                Remove area filter
              </Button>
            }
            severity="info"
          >
            An area filter is active. Only the defects within the area will be
            added to the report.
          </Alert>
        )}
        <TypeSelectFilter issue={false} vegetation={false} />
        <br />
        <hr />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Button
              onClick={downloadExcel}
              variant="contained"
              className="primaryButton"
              style={{ marginLeft: 5, textTransform: "none" }}
            >
              Excel
            </Button>

            <Button
              onClick={downloadCSV}
              variant="contained"
              className="secondaryButton"
              style={{ marginLeft: 5, textTransform: "none" }}
            >
              CSV
            </Button>
          </div>
          <Typography style={{ textAlign: "right" }}>
            {count} {translations.Menu.Tools.InventoryReport.Rows[language]}
          </Typography>
          <div style={{ width: 300 }}>
            <Select
              options={languageOptions}
              value={languageOptions.find(
                (lo) => lo.value === selectedLanguage
              )}
              // @ts-ignore
              onChange={(option) => setSelectedLanguage(option.value)}
            />
          </div>
        </div>
      </div>
    </RoleWrapper>
  );
}
