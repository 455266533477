import { useSearchParams } from "react-router-dom";
import { useSelector, useCurrentProject } from "hooks";
import { useFlags } from "launchdarkly-react-client-sdk";

function getFilterIds(searchParams: URLSearchParams): string[] {
  return searchParams.get("boxIds")?.split(",") || [];
}

export function useBboxIdsOnly() {
  const [searchParams] = useSearchParams();
  const annotationsData = useSelector((state) => state.image.annotations);
  const project = useCurrentProject();
  const { onlyShowSelectedBbsFilterOption } = useFlags();

  const active = project?.detailed_steelwork && onlyShowSelectedBbsFilterOption;
  const filterIds = active ? getFilterIds(searchParams) : [];

  const annotationsFiltered = [...annotationsData].filter((a) =>
    filterIds.includes(a.id)
  );

  const boxIdsOnlyActive = active;

  return {
    annotationsFiltered,
    boxIdsOnlyActive,
  };
}
