import { useContext, useRef } from "react";
import ItemColumn from "../ItemColumn";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { context } from "./ComponentOptionsProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFlags } from "launchdarkly-react-client-sdk";

export default function AddComponent() {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const { createComponent, valueSystems, componentCategories } =
    useContext(context);
  const { structureTemplateEditorCategories } = useFlags();
  // @ts-ignore
  function onFormSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name") as string;

    const valueSystemID = formData.get("valueSystem") as string;
    const categoryID = formData.get("category") as string;
    const sorting = Number(formData.get("sorting"));

    if (!name || name === "") {
      toast.error("The new name has to be at least 1 character long");
      return;
    }

    createComponent({
      name,
      gradingSystemID: parseInt(valueSystemID),
      categoryID: categoryID ? parseInt(categoryID) : null,
      sorting: sorting ? sorting : null,
    }).then((newComponentID) => {
      navigate(`/options/pole/status/component/${newComponentID}`);
    });
  }

  return (
    <ItemColumn
      title="Create a new component"
      description="Enter the properties of this component below"
      onSave={() =>
        onFormSubmit({ target: formRef.current, preventDefault: () => {} })
      }
    >
      <form onSubmit={onFormSubmit} ref={formRef}>
        <Stack spacing={2}>
          <FormControl>
            <Typography>Name</Typography>
            <TextField
              inputRef={nameRef}
              name="name"
              variant="standard"
              inputProps={{ "data-testid": "component-name-input" }}
            />
          </FormControl>
          <FormControl>
            <Typography>Value system</Typography>
            <Select
              name="valueSystem"
              variant="standard"
              fullWidth
              label="Value system"
              data-testid="value-system-select"
            >
              {valueSystems.map((system) => (
                <MenuItem value={system.id}>{system.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {structureTemplateEditorCategories && (
            <>
              <FormControl>
                <Typography>Category</Typography>
                <Select
                  name="category"
                  variant="standard"
                  fullWidth
                  label="Category"
                  data-testid="category-select"
                >
                  {componentCategories.map((category) => (
                    <MenuItem value={category.id}>{category.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <Typography>Sorting Order</Typography>
                <TextField
                  name="sorting"
                  type="number"
                  variant="standard"
                  inputProps={{
                    "data-testid": "component-sorting-input",
                  }}
                />
              </FormControl>
            </>
          )}
        </Stack>

        <Stack mt={2} spacing={1} direction="row">
          <Button
            type="submit"
            variant="contained"
            color="success"
            data-testid="create-component-button"
          >
            Create
          </Button>
        </Stack>
      </form>
    </ItemColumn>
  );
}
