import { useContext } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Image, ThreeDRotation, Whatshot } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import {
  useCurrentProject,
  useDispatch,
  useSelector,
  useTranslation,
} from "hooks";
import { CanvasContext } from "views/AnnotationTool/provider";
import "../style.scss";
import { openLidarWindow } from "utils/lidar";

interface IProps {
  currentImage: number;
}
export default function SupervisorReview(props: IProps) {
  const { currentImage } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentProject = useCurrentProject();
  const [searchParams, setSearchParams] = useSearchParams();

  const RGBImageTranslation = useTranslation("RGBImage");
  const thermalImageTranslation = useTranslation("ThermalImage");
  const lidarImageTranslation = useTranslation("LidarImage");

  const poleID = useSelector((state) => state.image?.current?.pole_id);
  const imageLidar = useSelector((state) => state.image?.current?.lidar || "");
  const imageLidarProjectId = useSelector(
    (state) => state.image?.current?.lidar_project_id
  );
  const lidarWindow = useSelector((state) => state.imageViewer.lidarWindow);
  const demoMode = useSelector((state) => state.ui.demoMode);
  const selectedPreviousDefectImage = useSelector(
    (state) => state.imageViewer.selectedPreviousDefectImage
  );

  const { imageTopLeft, imageBottomRight, canvasWidth } =
    useContext(CanvasContext);

  const connectedImages = useSelector(
    (state) => state.image?.current?.connected
  );
  const thermalImage = connectedImages?.find((im) => im.thermal);
  const rgbImage = connectedImages?.find((im) => im.rgb);

  if (selectedPreviousDefectImage) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        zIndex: 5,
        position: "absolute",
        imageRendering: "pixelated",
        backgroundColor: "rgba(0,0,0,0.8)",
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        overflow: "hidden",
        left: Math.min(imageBottomRight.x, canvasWidth - 45.0) || 0, // Clamp inside the view.
        top: Math.max(imageTopLeft.y, 0.0) || 0,
      }}
    >
      <Tooltip
        title={RGBImageTranslation}
        arrow
        placement="left"
        disableInteractive
      >
        <span>
          <IconButton
            disabled={!rgbImage}
            onClick={() =>
              // @ts-ignore
              navigate(`/${currentProject.id}/${rgbImage.id}${location.search}`)
            }
            className="connectedButton"
          >
            <Image htmlColor={rgbImage ? "white" : "#777"} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip
        title={thermalImageTranslation}
        arrow
        placement="left"
        disableInteractive
      >
        <span>
          <IconButton
            disabled={!thermalImage}
            onClick={() =>
              navigate(
                // @ts-ignore
                `/${currentProject.id}/${thermalImage.id}${location.search}`
              )
            }
            className="connectedButton"
          >
            <Whatshot htmlColor={thermalImage ? "white" : "#777"} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={"Structure"} arrow placement="left" disableInteractive>
        <span>
          <IconButton
            disabled={!poleID}
            onClick={() => {
              if (searchParams.has("pole")) {
                searchParams.delete("pole");
              } else {
                searchParams.set("pole", JSON.stringify(poleID));
                searchParams.set("poleStatus", "true");
              }
              setSearchParams(searchParams);
            }}
            className="connectedButton"
          >
            <div className="pole" style={{ transform: "none" }}>
              <div className="insulatorRow">
                <div className="insulator" />
                <div className="insulator" />
                <div className="insulator" />
              </div>
              <div className="crossarm" />
              <div className="beam" />
            </div>
          </IconButton>
        </span>
      </Tooltip>
      {!!currentImage && (
        <Tooltip
          title={lidarImageTranslation}
          arrow
          placement="left"
          disableInteractive
        >
          <span>
            <IconButton
              disabled={!imageLidar}
              onClick={() => {
                if (imageLidarProjectId) {
                  openLidarWindow(imageLidarProjectId, imageLidar, {
                    imageId: currentImage,
                    demoMode,
                  });
                }
              }}
              className="connectedButton"
            >
              <ThreeDRotation htmlColor={imageLidar ? "white" : "#777"} />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </div>
  );
}
