import { Divider, Stack } from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import ClientNameSwitch from "components/Settings/ClientNameSwitch";
import ClusterSwitch from "components/Settings/ClusterSwitch";
import LanguageSelector from "components/Settings/LanguageSelector";
import MapType from "components/Settings/MapType";
import ProximitySwitch from "components/Settings/ProximitySwitch";
import ThumbnailSorting from "components/Settings/ThumbnailSorting";
import { ReactElement } from "react";
import AutoSpeedZoomInReviewModes from "./AutoSpeedZoomInReviewModes";
import ClusterDistance from "./ClusterDistance";
import DateBeforeProjectName from "./DateBeforeProjectName";
import RoleOverride from "./RoleOverride";
import ShowDsoTso from "./ShowDsoTso";
import SkyqraftHiddenSettings from "./SkyqraftHiddenSettings";
import ZoomSpeed from "./ZoomSpeed";
import "./style.scss";
import MaxImageDistanceToMoveMap from "./MaxImageDistanceToMoveMap";
import { useFlags } from "launchdarkly-react-client-sdk";

export default function SettingsList(): ReactElement {
  const { userProfileInNavbar } = useFlags();
  const switchSize = 9;
  const optionSize = 3;

  return (
    <div id="settingsList">
      <Stack spacing={2} pr={2}>
        {!userProfileInNavbar && <LanguageSelector size={optionSize} />}
        {!userProfileInNavbar && (
          <RoleWrapper keyName="editClientNaming">
            <ClientNameSwitch size={3} />
          </RoleWrapper>
        )}
        {!userProfileInNavbar && <MapType size={optionSize} />}
        {!userProfileInNavbar && <ZoomSpeed size={optionSize} />}
        {!userProfileInNavbar && (
          <MaxImageDistanceToMoveMap size={switchSize} />
        )}
        {!userProfileInNavbar && <DateBeforeProjectName size={switchSize} />}
        {!userProfileInNavbar && (
          <RoleWrapper keyName="clusterSwitch">
            <ClusterSwitch size={switchSize} />
            <ThumbnailSorting size={optionSize} />
          </RoleWrapper>
        )}
      </Stack>
      <RoleWrapper keyName="advancedSettings">
        <ProximitySwitch />
      </RoleWrapper>
      <RoleWrapper keyName="advancedSettings">
        <ClusterDistance />
      </RoleWrapper>
      <RoleWrapper keyName="roleOverride" noOverride>
        <RoleOverride />
      </RoleWrapper>
      <RoleWrapper keyName="advancedFilter">
        <Divider sx={{ mx: 2, mt: 1 }}>Arkion Hidden on Images</Divider>
        <SkyqraftHiddenSettings />
      </RoleWrapper>
      <RoleWrapper keyName="changeReviewModeSettings">
        <AutoSpeedZoomInReviewModes />
      </RoleWrapper>
      <RoleWrapper keyName="internalSettings">
        <ShowDsoTso />
      </RoleWrapper>
    </div>
  );
}
