import {
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  FormControl,
  Select as MuiSelect,
} from "@mui/material";
import { IUser, useAdminProjects, useLanguage } from "hooks";
import Select from "react-select";
import React, { useState } from "react";
import { useAdminGroups } from "hooks/projects/useAdminGroups";
import { Loading } from "views";
import { useOutletContext, useNavigate } from "react-router-dom";
import { Add, Delete, Save } from "@mui/icons-material";
import { toast } from "react-toastify";
import { groupBy } from "lodash";
import { authorizedPut } from "utils/request";
import RoleWrapper from "components/RoleHOC/wrapper";
import translations from "translations";
import { accessLevels, accessLevelsHidden } from "./accessLevels";
import ProjectDropdown from "./ProjectDropdown";

interface IOption {
  value: number;
  label: string;
  group?: boolean;
}

interface IAccess {
  target?: IOption;
  group: boolean;
  accessLevel?: number;
}

export default function NewAccess() {
  const { language } = useLanguage();
  const { updateUsers, updateUser, user } = useOutletContext<{
    updateUsers: () => void;
    updateUser: () => void;
    user: IUser;
  }>();
  const [projects, projectsLoading] = useAdminProjects();
  const adminGroups = useAdminGroups();
  const groups = adminGroups.groups;
  const [loading, setLoading] = useState<boolean>(false);
  const [newAccess, setNewAccess] = useState<IAccess[]>([
    {
      // @ts-ignore
      target: null,
      group: false,
      accessLevel: 8,
    },
  ]);
  const navigate = useNavigate();

  async function giveAccess() {
    // Notify the user that we are starting a request
    setLoading(true);
    // Add the user (nothing happens if they exist)
    try {
      // Loop over the access levels
      for (let i = 0; i < newAccess.length; i++) {
        try {
          const access = newAccess[i];
          // Extract if we are working with a group or project
          const entity = access.group ? "group" : "project";
          // Prepare the API call
          const endpoint = `/admin/user/${user.id}/invite/${entity}`;
          const payload: Record<string, number> = {
            // @ts-ignore
            accessLevel: access.accessLevel,
          };
          if (access.group) {
            // @ts-ignore
            payload.group = access.target.value;
          } else {
            // @ts-ignore
            payload.project = access.target.value;
          }

          // Give user access
          await authorizedPut(endpoint, payload);
        } catch (err) {
          console.error("Failed to invite user", err);
        }
      }

      // Give the new user access
      updateUsers();
      updateUser();
      setLoading(false);
      navigate(`/users/${user.id}`);
    } catch (err) {
      toast.error(
        `${translations.UserAdmin.FailedToInvite[language]} ${user.name}`
      );
      console.error("Failed to invite user", err);
    }
  }

  if (projectsLoading || adminGroups.loading) {
    return (
      <>
        <Divider sx={{ my: 2 }} />
        <Loading relative />
      </>
    );
  }
  const filteredProjects = projects.filter((p) => p?.deleted === false);
  const groupedProjects = groupBy(filteredProjects, (p) => p.group?.name);

  return (
    <RoleWrapper keyName="userAdmin">
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5" component="h2">
          {translations.UserAdmin.GiveAccess[language]}
        </Typography>
        <Typography color="text.secondary">
          {translations.UserAdmin.SelectMissions[language]} {user.name}{" "}
          {translations.UserAdmin.AccessTo[language]}.
        </Typography>
        <Grid container sx={{ mt: 2 }} columnSpacing={2} rowSpacing={1}>
          <Grid item xs={3} alignItems="center" display="flex">
            {translations.UserAdmin.GroupProject[language]}
          </Grid>
          <Grid item xs={4} alignItems="center" display="flex">
            {translations.UserAdmin.AccessTarget[language]}
          </Grid>
          <Grid item xs={4} alignItems="center" display="flex">
            {translations.words.AccessLevel[language]}
          </Grid>
          <Grid item xs={1} alignItems="center" display="flex" />
          {newAccess.map((a, i) => (
            <React.Fragment key={`${i}`}>
              <Grid item xs={3} alignItems="center" display="flex">
                <Checkbox
                  checked={a.group}
                  onChange={(e) => {
                    const updatedAccess = [...newAccess];
                    //@ts-ignore
                    updatedAccess[i].group = e.target.checked;
                    // @ts-ignore
                    updatedAccess[i].target = null;
                    setNewAccess(updatedAccess);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ m: 1 }}>
                  {a.group ? (
                    <Select
                      options={groups
                        .map((g) => ({
                          label: g.name,
                          value: g.id,
                        }))
                        .sort((a, b) => (a.label > b.label ? 1 : -1))}
                      value={a.target}
                      onChange={(e) => {
                        const updatedAccess = [...newAccess];
                        // @ts-ignore
                        updatedAccess[i].target = e;
                        setNewAccess(updatedAccess);
                      }}
                    />
                  ) : (
                    <MuiSelect
                      native
                      defaultValue=""
                      id="grouped-native-select"
                      onChange={(e) => {
                        const name = projects.find(
                          (project) => project.id === parseInt(e.target.value)
                        )?.name;

                        const updatedAccess = [...newAccess];
                        updatedAccess[i].target = {
                          // @ts-ignore
                          label: name,
                          value: parseInt(e.target.value),
                        };
                        setNewAccess(updatedAccess);
                      }}
                    >
                      {Object.keys(groupedProjects)
                        .sort()
                        .map((groupName) => (
                          <optgroup
                            label={
                              groupName === "undefined"
                                ? translations.UserAdmin.NoGroup[language]
                                : groupName
                            }
                          >
                            {groupedProjects[groupName]
                              .sort((a, b) => (a.name > b.name ? 1 : -1))
                              .map((project) => (
                                <ProjectDropdown projectID={project.id} />
                              ))}
                          </optgroup>
                        ))}
                    </MuiSelect>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={4} mt={1}>
                <Select
                  value={{
                    // @ts-ignore
                    label: accessLevelsHidden[a.accessLevel][language],
                    value: a.accessLevel,
                  }}
                  onChange={(e) => {
                    const updatedAccess = [...newAccess];
                    // @ts-ignore
                    updatedAccess[i].accessLevel = e.value;
                    setNewAccess(updatedAccess);
                  }}
                  options={Object.keys(accessLevels).map((key) => ({
                    // @ts-ignore
                    label: accessLevels[parseInt(key)][language],
                    value: parseInt(key),
                  }))}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  onClick={() => {
                    setNewAccess(newAccess.filter((a, j) => j !== i));
                  }}
                >
                  <Delete />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Stack
              width="100%"
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setNewAccess([
                  // @ts-ignore
                  ...newAccess,
                  // @ts-ignore
                  { group: false, target: null, accessLevel: 8 },
                ]);
              }}
            >
              <Add />
              <Typography>
                {translations.UserAdmin.AddAccessLevel[language]}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            variant="contained"
            color="success"
            disabled={
              loading || !newAccess.every((a) => !!a.target && !!a.accessLevel)
            }
            onClick={giveAccess}
            endIcon={loading ? <Loading relative size={20} /> : <Save />}
          >
            {translations.words.Confirm[language]}
          </Button>
        </Stack>
      </Paper>
    </RoleWrapper>
  );
}
