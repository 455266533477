import { Delete, Edit } from "@mui/icons-material";
import { Chip, IconButton, Paper, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

type Props = {
  link: string;
  active?: boolean;
  children: ReactNode;
  chip?: number;
  editLink?: string;
  onDelete?: () => void;
  linkReplace?: boolean;
  dataTestID?: string;
};

export default function ItemColumnButton({
  link,
  active,
  children,
  chip,
  editLink,
  onDelete,
  linkReplace = true,
}: Props) {
  return (
    <Link
      to={link}
      replace={!!linkReplace}
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
    >
      <Paper
        sx={{
          backgroundColor: active ? "primary.main" : "white",
          color: active ? "white" : "inherit",
        }}
      >
        <Stack
          py={1}
          px={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {chip !== undefined && (
              <Chip
                sx={{
                  backgroundColor: active ? "rgba(0,0,0,0.2)" : undefined,
                  color: active ? "white" : "inherit",
                }}
                label={chip}
              />
            )}
            <Typography>{children}</Typography>
          </Stack>
          {editLink && (
            <Link
              to={editLink}
              replace={true}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <IconButton>
                <Edit htmlColor={active ? "white" : "inherit"} />
              </IconButton>
            </Link>
          )}
          {onDelete && (
            <IconButton onClick={onDelete}>
              <Delete htmlColor={active ? "white" : "inherit"} />
            </IconButton>
          )}
        </Stack>
      </Paper>
    </Link>
  );
}
