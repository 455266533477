import { useState } from "react";
import { Avatar, Stack } from "@mui/material";
import { useSelector } from "hooks";
import ProfileMenu from "./ProfileMenu";
import { getUserInitials } from "./utils";

export default function Profile() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const userName = useSelector((state) => state.user.name);
  const userNameInitials = getUserInitials(userName);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Stack
        sx={{
          width: 50,
          height: 50,
          background: anchorEl ? "white" : "transparent",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
        direction="column"
        onClick={handleClick}
        alignItems="center"
        justifyContent="center"
      >
        <Avatar sx={{ width: 32, height: 32, fontSize: "small" }}>
          {userNameInitials}
        </Avatar>
      </Stack>
      <ProfileMenu anchorEl={anchorEl} onClose={handleClose} />
    </>
  );
}
