import {
  Box,
  IconButton,
  Paper,
  Stack,
  Typography,
  Card,
  Button,
} from "@mui/material";
import { useContext, useState, useRef } from "react";
import { SummaryContext } from "views/PoleViewer/provider";
import PoleComponentItem from "./PoleComponentItem";
import PolePlacementItem from "./PolePlacementItem";
import Templates from "./Templates";
import { Add } from "@mui/icons-material";
import AddPoleComponentPopup from "./AddPoleComponentPopup";
import AddComponentPlacementPopup from "./AddComponentPlacementPopup";
import PoleComponentCategories from "./PoleComponentCategories";
import PoleComment from "../PoleComment";
import { usePoleNavigation } from "../../hooks";
import CircleDot from "./CircleDot";
import { RoleWrapper } from "components";
import { useFlags } from "launchdarkly-react-client-sdk";
import { IPoleStatus } from "views/PoleViewer/api";
import PoleContentOverview from "./PoleContentOverview";

export default function PoleContents() {
  const [addNewComponentDialog, setAddNewComponentDialog] = useState(false);
  const [filteredComponents, setFilteredComponents] = useState<IPoleStatus[]>(
    []
  );
  const { structureTemplateEditorCategories } = useFlags();

  const [addNewPlacementDialog, setAddNewPlacementDialog] = useState(false);
  const {
    components,
    selectedComponentID,
    selectedPlacementID,
    placementClick,
    componentClick,
  } = useContext(SummaryContext);
  const { poleLabel } = usePoleNavigation();

  const selectedComponent = components.find(
    (c) => c.id === selectedComponentID
  );

  const itemSummary = components
    .sort((a, b) => a.id - b.id)
    .map((c) => {
      return {
        id: c.id,
        name: c.name,
        status: c.placements.sort((a, b) => a.id - b.id),
      };
    });

  // Create a ref for the placement section
  const placementRef = useRef<HTMLDivElement>(null);

  // Function to scroll to the placement section
  const scrollToPlacement = () => {
    if (placementRef.current) {
      placementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Paper>
      <Typography margin={"15px 15px 0px 15px"}>
        <b>Structure: {poleLabel}</b>
      </Typography>

      <Box>
        <Box p={2} height="fit-content">
          <Box>
            <Card>
              {structureTemplateEditorCategories && (
                <PoleComponentCategories
                  setFilteredComponents={setFilteredComponents}
                />
              )}
              <Stack spacing={1}>
                <Stack
                  sx={{
                    zIndex: 90,
                    p: 1,
                    cursor: "pointer",
                    background: "rgba(255,255,255,0.5)",
                  }}
                  alignItems="flex-start"
                >
                  {itemSummary.length === 0 ? (
                    <Typography variant="caption">Click to report</Typography>
                  ) : (
                    <>
                      {!structureTemplateEditorCategories ? (
                        <>
                          {itemSummary.map((item) => (
                            <Stack
                              key={item.id}
                              direction="row"
                              alignItems="center"
                              sx={{
                                width: "100%",
                                minHeight: "40px",
                                background:
                                  selectedComponentID === item.id
                                    ? "rgba(254, 247, 221, 0.7)"
                                    : "transparent",
                                borderRadius: 1,
                                padding: "4px",
                                transition: "background 0.3s ease",
                              }}
                            >
                              <Typography sx={{ width: 100 }} variant="caption">
                                <Button
                                  size="small"
                                  onClick={() =>
                                    componentClick(
                                      selectedComponentID === item.id
                                        ? null
                                        : item.id
                                    )
                                  }
                                  sx={{
                                    color: "black",
                                    fontSize: "11px",
                                    textTransform: "none",
                                    minHeight: "40px",
                                  }}
                                  fullWidth={true}
                                >
                                  {item.name}:
                                </Button>
                              </Typography>
                              <Stack
                                direction="row"
                                spacing={1}
                                flexWrap={"wrap"}
                              >
                                {item.status.map((status, index) => {
                                  return (
                                    <CircleDot
                                      status={status}
                                      itemID={item.id}
                                    />
                                  );
                                })}
                              </Stack>
                            </Stack>
                          ))}
                        </>
                      ) : (
                        <PoleContentOverview
                          filteredComponents={filteredComponents}
                        />
                      )}
                    </>
                  )}
                </Stack>
              </Stack>
            </Card>
          </Box>
        </Box>

        <Box p={2} height="fit-content">
          <RoleWrapper keyName="structureTemplateEditor">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Stack direction="row" alignItems="center">
                <Templates />
              </Stack>
            </Stack>
          </RoleWrapper>

          <Box>
            <RoleWrapper keyName="structureViewerAnnotator">
              <Card sx={{ padding: 2, marginBottom: 2 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography>
                    <b>Component</b>
                  </Typography>
                  <RoleWrapper keyName="structureTemplateEditor">
                    <IconButton
                      data-testid="add-pole-component"
                      onClick={(e) => {
                        e.stopPropagation();
                        setAddNewComponentDialog(true);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </RoleWrapper>
                </Stack>
                <Stack spacing={1}>
                  {(structureTemplateEditorCategories
                    ? filteredComponents
                    : components
                  )
                    .sort((a, b) => a.id - b.id)
                    .map((component) => (
                      <PoleComponentItem
                        key={component.id}
                        onClick={() => {
                          componentClick(
                            selectedComponentID === component.id
                              ? null
                              : component.id
                          );
                          scrollToPlacement();
                        }}
                        component={component}
                        selected={component.id === selectedComponentID}
                      />
                    ))}
                </Stack>
              </Card>
            </RoleWrapper>
            {!!selectedComponentID && (
              <Card sx={{ padding: 2, marginBottom: 2 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Typography sx={{ marginBottom: 1 }}>
                    <b>Placement</b>
                  </Typography>
                  <RoleWrapper keyName="structureTemplateEditor">
                    <IconButton
                      data-testid="add-pole-placement"
                      onClick={() => {
                        if (selectedComponentID) {
                          setAddNewPlacementDialog(true);
                        }
                      }}
                    >
                      <Add />
                    </IconButton>
                  </RoleWrapper>
                </Stack>
                <Stack justifyContent="space-between" flex={1} spacing={2}>
                  <Stack spacing={1}>
                    {selectedComponent
                      ? selectedComponent.placements
                          .sort((a, b) => a.id - b.id)
                          .map((placement) => (
                            <PolePlacementItem
                              key={placement.id}
                              placement={placement}
                              // @ts-ignore
                              componentID={selectedComponentID}
                              placementID={selectedPlacementID}
                              valueSystem={selectedComponent?.value_system_id}
                              onClick={(e) => {
                                placementClick(e, null, placement);
                              }}
                            />
                          ))
                      : null}
                  </Stack>
                </Stack>
              </Card>
            )}

            <Card sx={{ padding: 2, marginBottom: 2 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Typography>
                  <b>Structure Comment</b>
                </Typography>
              </Stack>
              <Stack justifyContent="space-between" flex={1} spacing={2}>
                <Stack spacing={1}>
                  <PoleComment />
                </Stack>
              </Stack>
            </Card>
          </Box>
        </Box>
        <AddPoleComponentPopup
          open={addNewComponentDialog}
          onClose={(value: number | null) => {
            setAddNewComponentDialog(false);
            if (!!value) {
              componentClick(value);
            }
          }}
        />
        {!!selectedComponentID && (
          <AddComponentPlacementPopup
            open={addNewPlacementDialog}
            onClose={() => {
              setAddNewPlacementDialog(false);
            }}
            componentID={selectedComponentID}
          />
        )}
      </Box>
    </Paper>
  );
}
