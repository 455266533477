import { useCurrentProject, useSelector } from "hooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface ImageHistoryItem {
  id: number;
  projectId: number;
  lat: number;
  lng: number;
}

export function useImageHistory() {
  const currentProject = useCurrentProject();
  const params = useParams();
  const image = params.image;
  const currentImage = useSelector((state) => state.image.current);
  const [imageHistory, setImageHistory] = useState<ImageHistoryItem[]>([]);

  useEffect(() => {
    if (!image) {
      setImageHistory([]);
      return;
    }
    if (!currentImage) {
      // Image has not loaded to redux yet. Wait
      return;
    }
    if (!currentImage?.lat || !currentProject) {
      // Image is not loaded, or project is not loaded
      setImageHistory([]);
      return;
    }

    const latestHistoryItem = imageHistory[0];
    if (latestHistoryItem && latestHistoryItem.id === currentImage.id) {
      // The current image is already in the history
      return;
    }

    // Create a new history item
    const newImageHistoryItem = {
      id: currentImage.id,
      projectId: currentProject.id,
      lat: currentImage.lat,
      lng: currentImage.lng,
    };

    // Add the new history item to the history
    setImageHistory([newImageHistoryItem, ...imageHistory]);
  }, [currentImage, image, currentProject]);

  return imageHistory;
}
