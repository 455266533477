import { buffer, point } from "@turf/turf";
import InfoWindow from "components/InfoWindow";
import PoleComponent from "components/Poles/Pole";
import GoogleMapReact from "google-map-react";
import { ImageMeta, Marker, Mission, Pole } from "interfaces";
import { useEffect, useMemo } from "react";
import { GOOGLE_API_KEY } from "config";
import {
  useCurrentProject,
  useDispatch,
  useFilterPlay,
  useSelector,
} from "hooks";
import { useParams, useSearchParams } from "react-router-dom";
import GpsPositionMarker from "views/map/GpsPositionMarker";
import MapMarker from "views/map/marker";
import MenuView from "views/map/menu";
import { spreadOutAlgorithm } from "views/map/spreadOutAlgo";
import Loading from "views/Loading";
import ClearanceMarker from "../ClearanceMarker";
import LidarMarker from "../LidarMarker";
import "../style.scss";
import { updateFeedIdentifiers } from "state/actions";
import { FilterKey } from "hooks/filter/search_parameters/constants";
import { HEATMAP_MARKER_THRESHOLD } from "state/actions/map/markers";
import { useFlags } from "launchdarkly-react-client-sdk";
import AutomaticMapMove from "components/AutomaticMapMove";

interface IClearanceMarker {
  id: string;
  lat: number;
  lng: number;
  severity: number;
  scene: string;
  clearanceAxes: number[];
}

interface ILidarMarker {
  id: number;
  lat: number;
  lng: number;
  severity: number;
  scene: string;
  clearanceAxes: number[];
}

interface IProps {
  onApiLoaded: (props: {
    map: google.maps.Map;
    maps: typeof google.maps;
  }) => void;
  markers: Marker[];
  loadPowerlines: (
    map: google.maps.Map,
    updateBounds: boolean,
    mission: number
  ) => void;
  loadZones: (map: google.maps.Map, mission: number) => void;
  showMenu: boolean;
  currentImage: ImageMeta | null;
  setMissionID: (mission: string | number) => void;
  showMarkers: boolean;
  clusterDistance: number;
  clusterMethod: string;
  clusterVisible: boolean;
  poles: Pole[];
  showPoles: boolean;
  showZones: boolean;
  missions: Mission[];
  hideElements?: boolean;
  demoMode: boolean;
  visibleImageTypes: number[];
  updateAssignments: (mission: number) => void;
  supervisorMode: boolean;
  onMapClick: () => void;
  inspectorMode: boolean;
  updateAnnotatorAssignments: (mission: number) => void;
  mapLoading: boolean;
  clearanceMarkers: IClearanceMarker[];
  showClearanceMarkers: boolean;
  setFilterSelectorStatus: (
    starting: boolean,
    setSearchParams: (params: URLSearchParams) => void
  ) => void;
  clearFilterArea: () => void;
  updateMarkers: () => void;
  lidarMarkers: ILidarMarker[];
  showLidarMarkers: boolean;
  showListView: boolean;
  openedMarkers: number[];
}
// @ts-ignore
function getPixelOffset(oldPoint, newPoint, map) {
  if (map) {
    const projection = map.getProjection();
    const bounds = map.getBounds();
    const topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
    const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
    const scale = 2 ** map.getZoom();
    const oldProjection = projection.fromLatLngToPoint({
      lat: oldPoint[1],
      lng: oldPoint[0],
    });
    const newProjection = projection.fromLatLngToPoint({
      lat: newPoint[1],
      lng: newPoint[0],
    });
    const oldPosition = [
      Math.floor((oldProjection.x - bottomLeft.x) * scale),
      Math.floor((oldProjection.y - topRight.y) * scale),
    ];
    const newPosition = [
      Math.floor((newProjection.x - bottomLeft.x) * scale),
      Math.floor((newProjection.y - topRight.y) * scale),
    ];
    return [newPosition[0] - oldPosition[0], newPosition[1] - oldPosition[1]];
  }
}

export default function MapView({
  onApiLoaded,
  markers,
  loadPowerlines,
  loadZones,
  showMenu,
  currentImage,
  setMissionID,
  showMarkers,
  clusterDistance,
  clusterMethod,
  clusterVisible,
  poles,
  showPoles,
  showZones,
  hideElements,
  visibleImageTypes,
  updateAssignments,
  supervisorMode,
  onMapClick,
  inspectorMode,
  updateAnnotatorAssignments,
  mapLoading,
  clearanceMarkers,
  showClearanceMarkers,
  setFilterSelectorStatus,
  clearFilterArea,
  updateMarkers,
  lidarMarkers,
  showLidarMarkers,
  showListView,
  openedMarkers,
}: IProps) {
  const map = useSelector((state) => state.map.gmap);
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { filterActive } = useFilterPlay();
  const dispatch = useDispatch();
  const currentProject = useCurrentProject();
  const heatmap = useSelector((state) => state.map.heatmap);
  const area = searchParams.has("area");

  const bayFeedURLValue = searchParams.get(FilterKey.FEED_BAY);

  // https://app.launchdarkly.com/projects/default/flags/DisableMarkerSpread
  const { disableMarkerSpread } = useFlags();

  useEffect(() => {
    dispatch(updateFeedIdentifiers(bayFeedURLValue, filterActive));
  }, [bayFeedURLValue, filterActive]);

  useEffect(() => {
    if (map) {
      if (!area) {
        clearFilterArea();
      } else {
        setFilterSelectorStatus(false, setSearchParams);
      }
    }
  }, [
    area,
    params.mission,
    map,
    setFilterSelectorStatus,
    clearFilterArea,
    updateMarkers,
    hideElements,
  ]);

  useEffect(() => {
    if (map) {
      if (!hideElements) {
        updateMarkers();
      }
    }
  }, [
    filterActive,
    params.mission,
    map,
    clearFilterArea,
    updateMarkers,
    hideElements,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: If showZones is false we don't loadZones, added as dependency to load zones if it's toggled on.
  useEffect(() => {
    // @ts-ignore
    loadZones(map, parseInt(params.mission));
  }, [loadZones, map, params.mission, showZones]);

  useEffect(() => {
    if (!!map && !!params.mission) {
      const fitBounds = !searchParams.has("zoomOut");
      loadPowerlines(map, fitBounds, parseInt(params.mission));
      if (!fitBounds) {
        searchParams.delete("zoomOut");
        setSearchParams(searchParams);
      }

      onMapClick();
      if (supervisorMode) {
        updateAssignments(parseInt(params.mission));
      } else if (inspectorMode) {
        updateAnnotatorAssignments(parseInt(params.mission));
      }
    }
  }, [
    params.mission,
    map,
    loadPowerlines,
    loadZones,
    inspectorMode,
    supervisorMode,
    updateAssignments,
    updateAnnotatorAssignments,
    onMapClick,
  ]);

  useEffect(() => {
    // @ts-ignore
    const projectID = parseInt(params.mission);
    setMissionID(projectID);
  }, [params.mission, setMissionID]);

  const spreadMarkers = map?.getZoom() ?? 15;

  const memoMetas = useMemo(() => {
    let metas = [
      ...markers.filter((marker) => {
        return visibleImageTypes.includes(marker.image_type);
      }),
    ];

    if (spreadMarkers >= 19 && !disableMarkerSpread) {
      if (metas.length <= HEATMAP_MARKER_THRESHOLD) {
        const oldLocations = metas.map((m) => [m.lng, m.lat, m.compass_dir]);
        // @ts-ignore
        const newLocations = spreadOutAlgorithm(oldLocations, 3);
        metas = metas.map((m, i) => {
          const offset = getPixelOffset(oldLocations[i], newLocations[i], map);
          return {
            ...m, // @ts-ignore
            marginTop: offset[1], // @ts-ignore
            marginLeft: offset[0],
          };
        });
      }
    }
    return metas;
  }, [markers, spreadMarkers, map, visibleImageTypes]);

  const memoPoles = useMemo(() => {
    return poles.map((pole) => (
      <PoleComponent key={pole.id} lat={pole.lat} lng={pole.lng} pole={pole} />
    ));
  }, [poles]);
  const memoLidarMarkers = useMemo(() => {
    if (!currentProject) return [];
    return lidarMarkers.map((marker) => (
      <LidarMarker
        key={marker.id}
        severity={marker.severity}
        scene={marker.scene}
        project={currentProject?.id || 0}
        lat={marker.lat}
        lng={marker.lng}
        markerID={marker.id}
        clearanceAxes={marker.clearanceAxes}
      />
    ));
  }, [lidarMarkers, currentProject?.id]);

  const memoClearanceMarkers = useMemo(
    () => [...clearanceMarkers],
    [clearanceMarkers]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Re-trigger on showListView. TODO: Oskar, is this needed?
  useEffect(() => {
    if (map) {
      // biome-ignore lint/complexity/noForEach: forEach is built in to google maps
      map.data.forEach((feature) => {
        if (feature.getProperty("radius")) {
          map.data.remove(feature);
        }
      });
      if (!!currentImage && !!currentImage.lat && !!currentImage.lng) {
        if (
          clusterDistance > 0 &&
          clusterMethod === "proximity" &&
          clusterVisible
        ) {
          const radius = buffer(
            point([currentImage.lng, currentImage.lat]),
            clusterDistance,
            { units: "meters" }
          ); // @ts-ignore
          radius.properties.radius = true;
          map.data.addGeoJson(radius);
        }
      }
    }
  }, [
    currentImage,
    map,
    currentImage?.id,
    clusterDistance,
    clusterMethod,
    clusterVisible,
    showListView,
  ]);

  const currentMarker =
    !!currentImage?.id && memoMetas.find((m) => m.id === currentImage?.id);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        id="map"
      >
        {/*@ts-ignore*/}
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{
            key: GOOGLE_API_KEY,
            libraries: ["visualization", "drawing"],
            // @ts-ignore
            authReferrerPolicy: "origin",
            loading: "async",
          }}
          // something
          center={{
            lat: 59.33422,
            lng: 18.05808,
          }}
          defaultZoom={5}
          options={{
            disableDefaultUI: true,
            zoomControl: !hideElements,
            mapTypeId: "hybrid",
            keyboardShortcuts: false,
            scaleControl: true,
            streetViewControl: !hideElements,
          }}
          onGoogleApiLoaded={onApiLoaded}
          heatmap={heatmap}
        >
          {showPoles && memoPoles.length < 100 && memoPoles}
          {showClearanceMarkers &&
            memoClearanceMarkers.length > 0 &&
            memoClearanceMarkers.length < 500 &&
            memoClearanceMarkers.map((marker) => (
              // @ts-ignore
              <ClearanceMarker
                key={marker.id}
                boxID={marker.id}
                lat={marker.lng}
                lng={marker.lat}
                severity={marker.severity}
                scene={marker.scene}
                project={currentProject?.id || -1}
                clearanceAxes={marker.clearanceAxes}
              />
            ))}
          {showLidarMarkers && memoLidarMarkers.length > 0 && memoLidarMarkers}

          {showMarkers &&
            !hideElements &&
            memoMetas.map(
              (p) =>
                p.id !== currentImage?.id && (
                  <MapMarker
                    key={p.id} // @ts-ignore
                    lat={p.lat} // @ts-ignore
                    lng={p.lng}
                    filterActive={filterActive}
                    openedMarkers={openedMarkers}
                    {...p}
                  />
                )
            )}
          {currentMarker ? (
            <MapMarker
              key={currentMarker.id} // @ts-ignore
              lat={currentMarker.lat} // @ts-ignore
              lng={currentMarker.lng}
              filterActive={filterActive}
              openedMarkers={openedMarkers}
              {...currentMarker}
            />
          ) : (
            !!currentImage?.id && (
              <MapMarker
                id={currentImage.id}
                weight={1000}
                is_filtered={true}
                cluster_id={0}
                pole_id={currentImage.pole_id}
                compass_dir={45} // @ts-ignore
                image_type={2}
                isPreview={false}
                filterActive={filterActive}
                marginLeft={0}
                marginTop={0}
                viewed={0}
                lat={currentImage.lat}
                lng={currentImage.lng}
                openedMarkers={[]}
                hideMarker={true}
              />
            )
          )}

          {!!currentImage && showMarkers && spreadMarkers >= 19 && (
            <GpsPositionMarker lat={currentImage.lat} lng={currentImage.lng} />
          )}
        </GoogleMapReact>
        {mapLoading && <Loading size={50} />}
        {showMenu && <MenuView />}
        {/*@ts-ignore*/}
        {!hideElements && <InfoWindow />}
      </div>
      <AutomaticMapMove />
    </>
  );
}
