import { useFlags } from "launchdarkly-react-client-sdk";
import MapPanels from "./MapPanels";
import MapView from "./MapView/index";
import OldMapView from "./index";

interface IProps {
  hideElements?: boolean;
}
export default function ToggledMapView({ hideElements }: IProps) {
  // Render conditionally on feature flags
  // https://app.launchdarkly.com/projects/default/flags/map-panels-refactor/targeting?env=test&env=production&selected-env=test
  const { mapPanelsRefactor } = useFlags();

  if (!mapPanelsRefactor) {
    // @ts-ignore
    return <OldMapView hideElements={hideElements} />;
  }
  if (hideElements) {
    // @ts-ignore
    return <MapView hideElements />;
  }
  // @ts-ignore
  return <MapPanels />;
}
