import React, { ReactElement, useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import translations from "translations";
import RoleWrapper from "components/RoleHOC/wrapper";
import FullPage from "components/FullPage";
import { useLanguage } from "hooks";
import { Outlet, useNavigate } from "react-router";

export default function ToolPanel(): ReactElement {
  const { language } = useLanguage();

  let currentTab = 0;
  if (location.pathname.endsWith("blur")) {
    currentTab = 0;
  }

  return (
    <Box
      sx={{
        direction: "row",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        zIndex: 100,
      }}
    >
      <FullPage
        title="Image Tools"
        tab={currentTab}
        tabs={[
          {
            label: translations.Menu.Tools.BlurImages[language],
            link: "blur",
          },
        ]}
      >
        <Outlet />
      </FullPage>
    </Box>
  );
}
