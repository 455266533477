import { Stack } from "@mui/material";
import { Chip } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import RoleWrapper from "components/RoleHOC/wrapper";
import {
  useCurrentProject,
  useDispatch,
  useSelector,
  useTranslation,
} from "hooks";
import { ImageMeta, PreviousDefectImages } from "interfaces";
import { useEffect, useRef, useState, useCallback } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { getImageMeta, setImageViewerWidth } from "state/actions";
import AnnotationPreview from "views/image/AnnotationPreview";
import { reviewModes } from "constants/imageReview";
import { useWidthObserver } from "hooks";
import ViewerController from "views/image/ViewerController";
import PoleViewListener from "./PoleViewListener";
import "./style.scss";

const minEditorWidth = 740;

type IProps = {
  clearImageMeta: () => void;
  resetThermalData: () => void;
  gmap: google.maps.Map;
  imageMeta: ImageMeta;
  addOpenedMarker: (marker_id: number) => void;
  initialURL: string;
  setInitialURL: (value: string) => void;
  selectedPreviousDefectImage: PreviousDefectImages | null;
  setSelectedPreviousDefectImage: (image: PreviousDefectImages | null) => void;
};

export default function ImageViewer({
  clearImageMeta,
  gmap,
  imageMeta,
  resetThermalData,
  addOpenedMarker,
  initialURL,
  setInitialURL,
  selectedPreviousDefectImage,
  setSelectedPreviousDefectImage,
}: IProps) {
  const params = useParams();
  const location = useLocation();
  const ref = useRef<HTMLDivElement>();
  // @ts-ignore
  const image = parseInt(params.image);
  const currentProject = useCurrentProject();
  const dispatch = useDispatch();
  const previewImageTranslation = useTranslation("PreviewImagePrevDefect");

  // https://app.launchdarkly.com/projects/default/flags/map-panels-refactor/targeting?env=test&env=production&selected-env=test
  const { mapPanelsRefactor } = useFlags();
  const showMap = useSelector((state) => state.ui.showMap);

  useEffect(() => {
    image && addOpenedMarker(image);
  }, [image, addOpenedMarker]);

  useEffect(() => {
    if (currentProject?.id) {
      if (imageMeta?.id !== image) {
        clearImageMeta();
        resetThermalData();
      }
      if (selectedPreviousDefectImage) {
        dispatch(
          getImageMeta(
            currentProject.id,
            selectedPreviousDefectImage.id,
            selectedPreviousDefectImage.project
          )
        );
      }
      // Don't try to fetch image meta for image 0 that doesn't exist
      else if (image) {
        dispatch(getImageMeta(currentProject.id, image));
      }
      return () => {
        clearImageMeta();
        resetThermalData();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getImageMeta,
    currentProject?.id,
    image,
    clearImageMeta,
    resetThermalData,
    selectedPreviousDefectImage,
  ]);

  const annotationToolOpen = location.pathname.includes("/annotate");
  const halfWindow = Math.ceil(window.innerWidth * 0.5);
  const defaultWidth =
    annotationToolOpen && halfWindow < minEditorWidth
      ? minEditorWidth
      : halfWindow;

  const [containerWidth, setContainerWidth] = useState<number>(defaultWidth);
  const containerWidthRef = useRef<number>(defaultWidth);
  const [widthEditor, setWidthEditor] = useState<number>(0.8);
  const [canvasWidth, setCanvasWidth] = useState<number>(defaultWidth);
  const containerElementRef = useRef<HTMLDivElement>(null);

  const onWidthChange = useCallback((width) => {
    setCanvasWidth(width);
  }, []);

  useWidthObserver({
    ref: containerElementRef,
    onWidthChange,
  });

  let reviewMode = reviewModes.NONE;
  if (location.pathname.includes("/super-verify-tp-bbs")) {
    reviewMode = reviewModes.TRUE_POSITIVE_BBS;
  } else if (location.pathname.includes("/verify-ml")) {
    reviewMode = reviewModes.MACHINE_OUTPUT;
  } else if (location.pathname.includes("/super-verify-fp-images")) {
    reviewMode = reviewModes.FALSE_POSITIVE_IMAGES;
  } else if (location.pathname.includes("/super-verify-fp-bbs")) {
    reviewMode = reviewModes.FALSE_POSITIVE_BBS;
  }
  const modeOpen = reviewMode !== reviewModes.NONE;
  const width = useSelector((state) => state.ui.imageViewerWidth);
  function setWidth(width: number) {
    dispatch(setImageViewerWidth(width));
  }

  useEffect(() => {
    if (initialURL === window.location.pathname) {
      setInitialURL("");
    } else {
      setInitialURL("");
    }
  }, [initialURL, setInitialURL]);

  useEffect(() => {
    if (modeOpen) {
      setWidth(0.8);

      if (containerWidthRef.current < minEditorWidth) {
        setContainerWidth(minEditorWidth);
      }
    }
  }, [modeOpen]);

  useEffect(() => {
    if (annotationToolOpen && containerWidthRef.current < minEditorWidth) {
      setContainerWidth(minEditorWidth);
    }
  }, [annotationToolOpen]);

  useEffect(() => {
    containerWidthRef.current = containerWidth;
  }, [containerWidth]);

  // @ts-ignore
  function resizeViewer(e) {
    if (mapPanelsRefactor) {
      const container = document.getElementById("imageViewerContainer");

      if (!container) return;

      const box = container.getBoundingClientRect();
      const newWidth = e.x - box.x;
      annotationToolOpen
        ? setContainerWidth(
            newWidth > minEditorWidth ? newWidth : minEditorWidth
          )
        : setContainerWidth(newWidth);
    } else {
      const mainView = document.getElementById("map");
      let pageX;

      if (e.type === "touchmove") {
        pageX = e.touches[0].pageX;
      } else {
        pageX = e.pageX;
      }

      // @ts-ignore
      const box = mainView.getBoundingClientRect();

      const MARGIN = box.width > 768 ? 150 : 50;

      if (box.width - (pageX - box.x) < MARGIN) {
        pageX = box.width + box.x - MARGIN;
      } else if (pageX - box.x < MARGIN) {
        pageX = box.x + MARGIN;
      }

      const x_percent = (pageX - box.x) / box.width;

      annotationToolOpen ? setWidthEditor(x_percent) : setWidth(x_percent);
    }
  }

  function onResizeDown() {
    window.addEventListener("mousemove", resizeViewer);
    window.addEventListener("mouseup", onResizeUp);
    window.addEventListener("blur", onResizeUp);
  }

  function onResizeUp() {
    window.removeEventListener("mousemove", resizeViewer);
    window.removeEventListener("mouseup", onResizeUp);
    window.removeEventListener("blur", onResizeUp);
  }

  function onResizeDownTouch() {
    window.addEventListener("touchmove", resizeViewer);
    window.addEventListener("touchend", onResizeUpTouch);
    window.addEventListener("blur", onResizeUpTouch);
  }
  function onResizeUpTouch() {
    window.removeEventListener("touchmove", resizeViewer);
    window.removeEventListener("touchend", onResizeUpTouch);
    window.removeEventListener("blur", onResizeUpTouch);
  }

  const viewerWidth = (window.innerWidth - 50) * width;

  function exitPreviouseDefectImage() {
    setSelectedPreviousDefectImage(null);
  }

  return (
    <>
      <Stack
        id="imageViewerContainer"
        direction="row"
        sx={{
          ...(!mapPanelsRefactor && {
            position: "absolute",
            width: "100%",
            top: "52px",
            bottom: 0,
            zIndex: 100,
          }),
          ...(mapPanelsRefactor && {
            ...(showMap && { width: `${containerWidth}px` }),
            ...(!showMap && { flex: 1 }),
          }),
        }}
        style={{ pointerEvents: "none" }}
        {...(mapPanelsRefactor && { ref: containerElementRef })}
      >
        <div
          id="imageViewer"
          style={{
            position: "relative",
            ...(!mapPanelsRefactor && {
              width: `${
                100 *
                (annotationToolOpen
                  ? widthEditor < 0.5
                    ? 0.5
                    : widthEditor
                  : width)
              }%`,
              backgroundColor: "rgba(0, 0, 0, 0.25)",
            }),
            ...(mapPanelsRefactor && {
              paddingTop: "5px",
              boxSizing: "border-box",
              display: "flex",
              paddingRight: "10px",
              backgroundColor: "#2d2d2d",
              ...(showMap && { width: "100%" }),
              ...(!showMap && { width: `${defaultWidth}px`, flex: 1 }),
            }),
            pointerEvents: "all",
          }}
          // @ts-ignore
          ref={ref}
          onMouseUp={(e) => e.stopPropagation()}
        >
          {!annotationToolOpen ? (
            <>
              <AnnotationPreview
                widthScale={mapPanelsRefactor ? canvasWidth : width}
              />
              {!modeOpen && selectedPreviousDefectImage === null && (
                <ViewerController
                  width={mapPanelsRefactor ? canvasWidth : viewerWidth}
                />
              )}
              {selectedPreviousDefectImage && (
                <Chip
                  sx={{ position: "absolute", bottom: 20, left: 200 }}
                  label={previewImageTranslation}
                  color="info"
                  onDelete={exitPreviouseDefectImage}
                />
              )}
            </>
          ) : (
            <Outlet />
          )}
        </div>
        <div
          className="divider noselect nodrag"
          {...(mapPanelsRefactor && {
            style: {
              position: "absolute",
              top: "0",
              bottom: "0",
              right: 0,
              zIndex: 500,
              borderRight: "5px solid white",
              ...(!showMap && { display: "none" }),
            },
          })}
          onMouseDown={onResizeDown}
          onMouseUp={onResizeUp}
          onTouchStart={onResizeDownTouch}
          onTouchEnd={onResizeUpTouch}
        />
        <RoleWrapper keyName="updatePoleVisibility">
          <PoleViewListener />
        </RoleWrapper>
        {Boolean(!annotationToolOpen && !mapPanelsRefactor) && (
          <div
            style={{
              display: "flex",
              pointerEvents: "all",
              height: "fit-content",
            }}
          />
        )}
      </Stack>
    </>
  );
}
