import { Stack, Tooltip, Typography } from "@mui/material";

type Props = {
  timestamp: string;
};

function formatDate(dateString: string) {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "2-digit",
    month: "short",
  };
  return date.toLocaleDateString(undefined, options);
}

function formatTime(dateString: string) {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  return date.toLocaleTimeString(undefined, options);
}

function formatToUTC(dateString: string) {
  const date = new Date(dateString);
  return date.toUTCString();
}

export default function TimeStamp({ timestamp }: Props) {
  const date = timestamp ? formatDate(timestamp) : "";
  const time = timestamp ? formatTime(timestamp) : "";
  const utc = timestamp ? formatToUTC(timestamp) : "";

  return (
    <Tooltip title={utc}>
      <Stack direction="column">
        <Typography fontSize="x-small">{date}</Typography>
        <Typography fontSize="x-small">{time}</Typography>
      </Stack>
    </Tooltip>
  );
}
