import { useProjectStatistics } from "./hooks";
import { SortingOptions } from "./SortingButton";

export function filterProjects(
  stats: ReturnType<typeof useProjectStatistics>["projectStatistics"],
  searchString: string
) {
  return stats.filter((stats) => {
    if (stats.n_defects === 0) {
      return false;
    }
    if (stats.project.name.toLowerCase().includes(searchString.toLowerCase())) {
      return true;
    }
    if (stats.project.year.toString().includes(searchString)) {
      return true;
    }
    return false;
  });
}

export function sortProjects(
  stats: ReturnType<typeof useProjectStatistics>["projectStatistics"],
  sortMethod: SortingOptions
) {
  stats.sort((a, b) => {
    // If we are sorting on defects, do that
    if (sortMethod === SortingOptions.defects) {
      return a.n_defects - b.n_defects;
    }
    // If we are sorting on processed defects, do that
    if (sortMethod === SortingOptions.processed) {
      return a.n_processed_defects - b.n_processed_defects;
    }
    // If we are sorting on unprocessed defects, do that
    if (sortMethod === SortingOptions.unprocessed) {
      return (
        a.n_defects -
        a.n_processed_defects -
        (b.n_defects - b.n_processed_defects)
      );
    }
    // If we have no sorting, sort by project id
    return a.project.id - b.project.id;
  });
  return stats;
}
