import { Input } from "@mui/material";
import { setMaxImageDistanceToMoveMap } from "state/actions";
import { useDispatch, useLanguage, useSelector } from "hooks";
import translations from "translations";
import SettingsContainer from "./SettingsContainer";

interface IProps {
  size: number;
}

export default function MaxImageDistanceToMoveMap({ size }: IProps) {
  const dispatch = useDispatch();
  const maxImageDistance = useSelector(
    (state) => state.user.maxImageDistanceToMoveMap
  );
  const { language } = useLanguage();

  return (
    <SettingsContainer
      size={size}
      title={translations.Menu.Settings.MaxDistanceBeforeMoving[language]}
    >
      <Input
        style={{
          width: 66,
          marginRight: 10,
        }}
        type="number"
        value={maxImageDistance}
        onChange={(e) => {
          const value = e.target.value;
          const numberValue = parseInt(value);
          dispatch(setMaxImageDistanceToMoveMap(numberValue));
        }}
      />
    </SettingsContainer>
  );
}
