import { Typography, Stack, Paper, Tooltip, Box } from "@mui/material";
import { Google, Home, Microsoft, Badge } from "@mui/icons-material";
import { useUserAdmin } from "hooks";
import ArkionLogo from "./ArkionLogo";

type Props = {
  user: ReturnType<typeof useUserAdmin>["users"][number];
  currentUser: boolean;
};

type SSOType = NonNullable<
  ReturnType<typeof useUserAdmin>["users"][number]["sso"]
>;
type SSOProviderType = SSOType["provider"];

const GoogleProvider: SSOProviderType = "google";
const AzureProvider: SSOProviderType = "azure";

export default function UserListCard({ user, currentUser }: Props) {
  return (
    <Paper
      sx={{
        p: 2,
        backgroundColor: currentUser ? "#D6E7FF" : undefined,
        position: "relative",
      }}
    >
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={0.5} alignItems="center" m={0}>
          <Tooltip
            title={user.visitor ? "Visitor" : "Home domain"}
            placement="top"
            arrow
            disableInteractive
          >
            {user.visitor ? (
              <Badge fontSize="small" />
            ) : (
              <Home fontSize="small" />
            )}
          </Tooltip>

          <Typography>{user.name}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          {user.employee && (
            <Tooltip
              title="Arkion Employee"
              placement="right"
              arrow
              disableInteractive
            >
              <Box position="absolute" top={-1} right={4}>
                <ArkionLogo />
              </Box>
            </Tooltip>
          )}
          {user.sso?.provider === GoogleProvider && (
            <Tooltip
              title={`Authentication Provider: ${user.sso.name}`}
              placement="top"
              arrow
              disableInteractive
            >
              <Google />
            </Tooltip>
          )}
          {user.sso?.provider === AzureProvider && (
            <Tooltip
              title={`Authentication Provider: ${user.sso.name}`}
              placement="top"
              arrow
              disableInteractive
            >
              <Microsoft />
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
}
