import { useEffect, useRef } from "react";
import {
  useLocation,
  useSearchParams,
  useParams,
  useNavigate,
} from "react-router-dom";
import { useCurrentProject, useSelector, useDispatch } from "hooks";
import { setShowMap } from "state/actions";
import { axiosInstance } from "utils/request";

export default function useAutoSelectFirstImage() {
  const location = useLocation();
  const currentProject = useCurrentProject();
  const showMap = useSelector((state) => state.ui.showMap);
  const { image } = useParams();
  const inImageWithoutMapRef = useRef(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (showMap || !currentProject?.id) {
      inImageWithoutMapRef.current = false;
      return;
    }
    if (image) {
      inImageWithoutMapRef.current = true;
      return;
    }

    (async () => {
      // Support entering a project without map enabled by auto navigating to a random image if not already set
      if (!inImageWithoutMapRef.current) {
        const { data } = await axiosInstance.get(
          `/project/${currentProject?.id}/image/any`
        );

        if (data?.id) {
          const searchString = new URLSearchParams(searchParams).toString();
          const queryString = searchString.length ? `?${searchString}` : "";

          navigate(`/${currentProject.id}/${data.id}${queryString}`, {
            replace: true,
          });
        }
      } else {
        // If the user however decides to close an image with the map still disabled we should enable the map to avoid a blank screen
        dispatch(setShowMap(true));
      }
    })();
  }, [location, showMap, currentProject, image]);
}
