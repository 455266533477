import { axiosInstance } from "utils/request";
import { dateToString } from "hooks/filter/search_parameters/utils";
import {
  FilterKey,
  FilterType,
} from "hooks/filter/search_parameters/constants";

export interface IHeatmapNode {
  lat: number;
  lng: number;
}
export interface IHeatmap {
  positions: IHeatmapNode[];
}

export interface IUnfilteredMarkers {
  markers: {
    id: number;
    lat: number;
    lng: number;
    weight: number;
    cluster_id: number | null;
    pole_id: number | null;
    compass_dir: number;
    image_type: number | null;
    is_filtered: boolean;
  }[];
}

interface IImageMarker {
  id: number;
  lat: number;
  lng: number;
  weight: number;
  cluster_id: number | null;
  pole_id: number | null;
  compass_dir: number | null;
  is_filtered: boolean;
  image_type: number | null;
  viewed: boolean;
  // The values below are active when list is requested
  src?: string;
  client_pole_id?: string;
  severity_count?: number;
  defects_processed_count?: number;
}

export interface IProps {
  projectID: number;
  west?: number;
  east?: number;
  south?: number;
  north?: number;
  // The following values are filters
  actor?: FilterType["ACTORS"]; // annotator
  severity?: FilterType["SEVERITY"]; // severity
  detection?: FilterType["DETECTION"]; // detection
  flightIDs?: FilterType["FLIGHT"]; // flight
  imageLists?: FilterType["LIST"]; // list
  area?: FilterType["AREA"]; // area
  flagged?: FilterType["FLAGGED"]; // flagged
  skyqraftHidden?: FilterType["SKYQRAFT_HIDDEN"]; // hidden
  workflowStatus?: FilterType["WORKFLOW"]; // workflow
  dateImageCapture?: FilterType["DATE_IMAGE_CAPTURE"];
  dateImageUploaded?: FilterType["DATE_IMAGE_UPLOADED"];
  dateDefectAdded?: FilterType["DATE_DEFECT_ADDED"];
  dateDefectProcessed?: FilterType["DATE_DEFECT_PROCESSED"];
  dateDefectFixed?: FilterType["DATE_DEFECT_FIXED"];
  dateDefectReported?: FilterType["DATE_DEFECT_REPORTED"];
  clearanceAxes?: FilterType["CLEARANCE_AXES"];
  clearanceRules?: FilterType["CLEARANCE_RULES"];
  showDsoTso?: FilterType["DSO_TSO"];
  defectNotReported?: FilterType["DEFECT_NOT_REPORTED"];
  defectNotProcessed?: FilterType["DEFECT_NOT_PROCESSED"];
  filteredOnly?: boolean;
  filterActive?: boolean;
  // The values below are for list view
  page?: number;
}

type StringifyInput = Date | number | boolean | string;

function stringify(value: StringifyInput) {
  // Convert the value to string
  // If you need a special conversion of a type, do it here
  if (value instanceof Date) {
    return dateToString(value);
  }
  return value.toString();
}

interface IBounds {
  west: number;
  east: number;
  south: number;
  north: number;
}

export async function getUnfilteredMarkers(
  projectID: number,
  bounds: IBounds,
  controller: AbortController
) {
  const response = await axiosInstance.get<IUnfilteredMarkers>(
    "/image/markers_thin",
    {
      headers: {
        missionID: projectID,
      },
      params: bounds,
      signal: controller.signal,
    }
  );
  return response.data;
}

export async function getHeatmap(
  projectID: number,
  bounds: IBounds,
  controller: AbortController
) {
  const response = await axiosInstance.get<IHeatmap>("/image/heatmap", {
    headers: {
      missionID: projectID,
    },
    params: bounds,
    signal: controller.signal,
  });
  return response.data;
}

function setParam(
  key: string,
  value: StringifyInput | StringifyInput[],
  searchParams: URLSearchParams
) {
  // Add the value to the searchParams
  // only if it is not null or undefined
  if (value === undefined || value === null) return;
  if (Array.isArray(value)) {
    if (value.length === 0) return;
    searchParams.set(key, value.map(stringify).join(","));
  } else {
    if (value === "") return;
    searchParams.set(key, stringify(value));
  }
}

export async function getImageMarkers(
  payload: IProps,
  controller: AbortController
) {
  const searchParams = new URLSearchParams();
  // Yes we can collect all these filters
  // directly from window.location.search
  // but we are not doing that

  // We want to have control over the filters
  // and we want to enable typescript to help us
  // with the filters
  // @ts-ignore
  setParam("west", payload.west, searchParams);
  // @ts-ignore
  setParam("east", payload.east, searchParams);
  // @ts-ignore
  setParam("south", payload.south, searchParams);
  // @ts-ignore
  setParam("north", payload.north, searchParams);
  // @ts-ignore
  setParam(FilterKey.ACTORS, payload.actor, searchParams);
  // @ts-ignore
  setParam(FilterKey.SEVERITY, payload.severity, searchParams);
  // @ts-ignore
  setParam(FilterKey.DETECTION, payload.detection, searchParams);
  // @ts-ignore
  setParam(FilterKey.FLIGHT, payload.flightIDs, searchParams);
  // @ts-ignore
  setParam(FilterKey.WORKFLOW, payload.workflowStatus, searchParams);
  // @ts-ignore
  setParam(FilterKey.LIST, payload.imageLists, searchParams);
  // @ts-ignore
  setParam(FilterKey.AREA, payload.area, searchParams);
  // @ts-ignore
  setParam(FilterKey.FLAGGED, payload.flagged, searchParams);
  // @ts-ignore
  setParam(FilterKey.SKYQRAFT_HIDDEN, payload.skyqraftHidden, searchParams);
  setParam(
    FilterKey.DATE_IMAGE_CAPTURE,
    // @ts-ignore
    payload.dateImageCapture,
    searchParams
  );
  setParam(
    FilterKey.DATE_IMAGE_UPLOADED,
    // @ts-ignore
    payload.dateImageUploaded,
    searchParams
  );
  // @ts-ignore
  setParam(FilterKey.DATE_DEFECT_ADDED, payload.dateDefectAdded, searchParams);
  setParam(
    FilterKey.DATE_DEFECT_PROCESSED,
    // @ts-ignore
    payload.dateDefectProcessed,
    searchParams
  );
  // @ts-ignore
  setParam(FilterKey.DATE_DEFECT_FIXED, payload.dateDefectFixed, searchParams);
  setParam(
    FilterKey.DATE_DEFECT_REPORTED,
    // @ts-ignore
    payload.dateDefectReported,
    searchParams
  );
  // @ts-ignore
  setParam(FilterKey.CLEARANCE_AXES, payload.clearanceAxes, searchParams);
  // @ts-ignore
  setParam(FilterKey.CLEARANCE_RULES, payload.clearanceRules, searchParams);
  // @ts-ignore
  setParam(FilterKey.DSO_TSO, payload.showDsoTso, searchParams);
  // @ts-ignore
  setParam("filteredOnly", payload.filteredOnly, searchParams);
  // @ts-ignore
  setParam(FilterKey.FILTER_ACTIVE, payload.filterActive, searchParams);
  setParam(
    FilterKey.DEFECT_NOT_REPORTED,
    // @ts-ignore
    payload[FilterKey.DEFECT_NOT_REPORTED],
    searchParams
  );
  setParam(
    FilterKey.DEFECT_NOT_PROCESSED,
    // @ts-ignore
    payload[FilterKey.DEFECT_NOT_PROCESSED],
    searchParams
  );

  const response = await axiosInstance.get<{
    data: IImageMarker[];
    pages: number;
  }>("/image/markers", {
    headers: {
      missionID: payload.projectID,
    },
    params: searchParams,
    signal: controller.signal,
  });
  if (response.status !== 200) {
    throw new Error("Failed to fetch image markers");
  }
  return response.data;
}
