import React from "react";

type Props = {
  active?: boolean;
  children?: React.ReactNode;
};

export default function ProfileMenuTabContent({ active, children }: Props) {
  if (!active) {
    return null;
  }
  return <div>{children}</div>;
}
