export function getUserInitials(name: string): string {
  // If there are no names, return an empty string
  if (!name) {
    return "";
  }

  // Split the name into an array of names
  const names = name.split(" ").filter((n) => n.trim() !== "");

  // Count the number of names
  const nameCount = names.length;
  switch (nameCount) {
    case 0: {
      return "";
    }
    case 1: {
      const nameOne = names[0].charAt(0).toUpperCase();
      return nameOne;
    }
    case 2: {
      const nameOne = names[0].charAt(0).toUpperCase();
      const nameTwo = names[1].charAt(0).toUpperCase();
      return `${nameOne}${nameTwo}`;
    }
    default: {
      const nameOne = names[0].charAt(0).toUpperCase();
      const nameTwo = names[nameCount - 1].charAt(0).toUpperCase();
      return `${nameOne}${nameTwo}`;
    }
  }
}
