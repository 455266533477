import { Stack } from "@mui/material";
import ProfileMenuTabButton from "./ProfileMenuTabButton";
import { useState } from "react";

import { MyAccount, MySettings, Other, Language } from "./Tabs";
import ProfileMenuTabContent from "./ProfileMenuTabContent";

enum ProfileMenuTabs {
  MyAccount = 1,
  MySettings = 2,
  Language = 3,
  Other = 4,
}

export default function ProfileMenuContent() {
  const [selectedTab, setSelectedTab] = useState(1);
  return (
    <Stack data-testid="profile-menu-content" direction="row">
      <Stack
        data-testid="profile-menu-tabs"
        sx={{ background: "#F8F8F8" }}
        flex={3}
        pl={1}
        py={1}
      >
        <ProfileMenuTabButton
          title="My Account"
          active={selectedTab === ProfileMenuTabs.MyAccount}
          onClick={() => setSelectedTab(ProfileMenuTabs.MyAccount)}
        />

        <ProfileMenuTabButton
          title="My Settings"
          active={selectedTab === ProfileMenuTabs.MySettings}
          onClick={() => setSelectedTab(ProfileMenuTabs.MySettings)}
        />
        <ProfileMenuTabButton
          title="Language"
          active={selectedTab === ProfileMenuTabs.Language}
          onClick={() => setSelectedTab(ProfileMenuTabs.Language)}
        />
        <ProfileMenuTabButton
          title="Other"
          active={selectedTab === ProfileMenuTabs.Other}
          onClick={() => setSelectedTab(ProfileMenuTabs.Other)}
        />
      </Stack>
      <Stack
        flex={5}
        data-testid="profile-menu-tab-content"
        sx={{ background: "white" }}
        minHeight={500}
        p={1}
      >
        <ProfileMenuTabContent
          active={selectedTab === ProfileMenuTabs.MyAccount}
        >
          <MyAccount />
        </ProfileMenuTabContent>
        <ProfileMenuTabContent
          active={selectedTab === ProfileMenuTabs.MySettings}
        >
          <MySettings />
        </ProfileMenuTabContent>
        <ProfileMenuTabContent
          active={selectedTab === ProfileMenuTabs.Language}
        >
          <Language />
        </ProfileMenuTabContent>
        <ProfileMenuTabContent active={selectedTab === ProfileMenuTabs.Other}>
          <Other />
        </ProfileMenuTabContent>
      </Stack>
    </Stack>
  );
}
