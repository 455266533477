export default {
  NumberOfDefects: {
    EN: "Number of defects",
    NO: "Antall avvik",
    SE: "Antal defekter",
  },
  StructureMode: {
    EN: "Switch to structure list",
    NO: "Bytt til strukturliste",
    SE: "Byt till strukturlista",
  },
  ImageMode: {
    EN: "Switch to image list",
    NO: "Bytt til bildeliste",
    SE: "Byt till bildlista",
  },
  PrevMode: {
    EN: "View last years project to see structures with unresolved defects from the same geographical area",
    NO: "Se fjorårets prosjekt for å se strukturer med uløste avvik fra samme geografiske område",
    SE: "Visa förra årets projekt för att se strukturer med olösta defekter från samma geografiska område",
  },
  RefreshList: {
    EN: "Refresh list",
    NO: "Oppdater listen",
    SE: "Uppdatera listan",
  },
  GoToNextImage: {
    EN: "Go to next image",
    NO: "Gå til neste bilde",
    SE: "Gå till nästa bild",
  },
  GoToPreviousImage: {
    EN: "Go to previous image",
    NO: "Gå til forrige bilde",
    SE: "Gå till föregående bild",
  },
  Loading: {
    EN: "Loading...",
    NO: "Laster...",
    SE: "Laddar...",
  },
  Images: {
    EN: "images",
    NO: "bilder",
    SE: "bilder",
  },
  NoImages: {
    EN: "No images matches current project and filters",
    NO: "Ingen bilder samsvarer med nåværende prosjekt og filtre",
    SE: "Inga bilder matchar nuvarande projekt och filter",
  },
  FilterNotActive: {
    EN: "Activate a filter to use list view",
    NO: "Aktiver et filter for å bruke listevisning",
    SE: "Aktivera ett filter för att använda listvyn",
  },
  NoStructure: {
    EN: "No structure",
    NO: "Ingen struktur",
    SE: "Ingen struktur",
  },
};
