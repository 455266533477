import axios from "axios";
import { toast } from "react-toastify";
import { axiosInstance } from "utils/request";
import {
  ArkionGeoJson,
  getPowerlineUploadSignedURL as getCeleryPowerlineUploadSignedURL,
} from "components/CeleryKmlUploader";

async function startTask(
  geoJson: ArkionGeoJson,
  projectId: number
): Promise<string> {
  const signedUrl = await getCeleryPowerlineUploadSignedURL(
    projectId,
    "geojson"
  );

  // Upload the GeoJSON to the signed URL
  const geoJsonString = JSON.stringify(geoJson);
  const geoJsonBlob = new Blob([geoJsonString], { type: "application/json" });
  const geoJsonFile = new File([geoJsonBlob], "geojson.json", {
    type: "application/json",
  });
  await axios.put(signedUrl, geoJsonFile);

  const response = await axiosInstance.post<{ task_id: string }>(
    `/project/${projectId}/geojson/import?s3_url=${signedUrl}`
  );
  return response.data.task_id;
}

function checkStatus(taskId: string, projectId: number): Promise<void> {
  // Create a promise that we can control manually
  return new Promise((resolve, reject) => {
    // Add a toast handler to show the progress of the task
    const toastHandle = toast.info("Processing powerlines...", {
      autoClose: false,
      progress: 0,
    });

    // Poll the status of the task every 5 seconds
    function pollStatus() {
      axiosInstance
        .get<{
          status: "WAITING" | "PROGRESS" | "COMPLETE" | "FAILED";
          progress?: number;
        }>(`/project/${projectId}/geojson/import/status/${taskId}`)
        .then((response) => {
          if (response.data.status === "COMPLETE") {
            // If the task is complete, resolve the promise
            toast.update(toastHandle, {
              type: toast.TYPE.SUCCESS,
              autoClose: 5000,
              progress: 1,
            });
            resolve();
          } else if (response.data.status === "FAILED") {
            // If the task failed, reject the promise
            toast.update(toastHandle, {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              progress: 1,
            });
            reject(new Error("Task failed"));
          } else {
            // If the task is still in progress, update the progress bar and poll again
            const progress = response.data.progress ?? 0;
            toast.update(toastHandle, {
              progress,
            });
            setTimeout(pollStatus, 5000);
          }
        })
        .catch((error) => {
          // If an error occurs, reject the promise
          reject(error);
        });
    }

    pollStatus();
  });
}

export async function onCelerySave(
  geoJson: ArkionGeoJson,
  projectId: number
): Promise<void> {
  try {
    const taskId = await startTask(geoJson, projectId);
    await checkStatus(taskId, projectId);
  } catch (error) {
    // @ts-ignore
    throw new Error(`Failed to save GeoJSON: ${error.message}`);
  }
}
