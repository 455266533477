import { Mission } from "interfaces";
import MenuItem from "components/MenuItem";
import translations from "translations";
import MissionItem from "./MissionItem";
// @ts-ignore
import md5 from "md5-hash";
import { groupBy } from "lodash";
import RoleWrapper from "components/RoleHOC/wrapper";
import { Link } from "react-router-dom";
import RegionItem from "./RegionItem";
import { compareYearAndName } from "utils/utils";
import { useLanguage, useSelector, useSidebar } from "hooks";
import {
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackItem from "views/Sidebar/ArrowBackItem";
import ProjectMenuTitle from "./ProjectMenuTitle";
import "./style.scss";
import { useState } from "react";
import { Clear, Search } from "@mui/icons-material";

export default function Missions() {
  const projects = useSelector((state) => state.user.missions);
  const demoMode = useSelector((state) => state.ui.demoMode);
  const { language } = useLanguage();
  const [searchString, setSearchString] = useState("");
  const { closeSidebar } = useSidebar();
  const filteredProjects = projects.filter((p) => {
    if (p.name.toLowerCase().includes(searchString.toLowerCase())) {
      return true;
    }
    if (p.region?.name.toLowerCase().includes(searchString.toLowerCase())) {
      return true;
    }
    if (p.group?.name.toLowerCase().includes(searchString.toLowerCase())) {
      return true;
    }
    if (p.id.toString().includes(searchString)) {
      return true;
    }
    if (p.year.toString().includes(searchString)) {
      return true;
    }
    return false;
  });
  const groups = groupBy(filteredProjects, (m: Mission) => m?.group?.name);

  const seenMissions = new Set();
  const otherProjects = [...filteredProjects].filter((m) => !m.group);
  return (
    <Stack px={1} alignItems="center" maxHeight="100%" overflow="auto">
      <ArrowBackItem>
        <RoleWrapper keyName="newMission">
          <Link
            onClick={closeSidebar}
            to="/order"
            style={{ textDecoration: "none", color: "inherit" }}
            data-testid="order-project-button"
          >
            <Button color="success">
              {translations.Menu.Missions.AddMission[language]}
            </Button>
          </Link>
        </RoleWrapper>
      </ArrowBackItem>
      <ProjectMenuTitle />
      <Stack width="100%" mt={1}>
        <Stack direction="row" alignItems="flex-end" flex={1} px={2}>
          <TextField
            id="project-search"
            className={`${demoMode ? "hidden-input" : ""}`}
            sx={{ textAlign: "center" }}
            label="Search for project"
            variant="standard"
            autoComplete="off"
            autoFocus
            fullWidth
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />

          <IconButton
            disabled={!searchString}
            onClick={() => {
              setSearchString("");
            }}
          >
            {searchString ? <Clear /> : <Search />}
          </IconButton>
        </Stack>
      </Stack>
      <Stack width="100%" alignItems="flex-start" mt={2} spacing={1}>
        {filteredProjects.length === 0 && (
          <Typography>No projects found</Typography>
        )}
        {Object.keys(groups)
          .sort()
          .map((groupName) => {
            if (!groupName || groupName === "undefined") {
              return null;
            }
            const regions = groupBy(groups[groupName], (m) => m?.region?.name);
            const regionNames = Object.keys(regions).sort();
            return (
              <Stack
                key={`group-${groupName}`}
                data-testid={`group-container-${groupName}`}
                spacing={1}
                width="100%"
              >
                <Divider>
                  <Typography
                    fontWeight="bold"
                    data-testid={`group-title-${groupName}`}
                  >
                    {demoMode
                      ? `Customer ${md5(groupName).substring(0, 4)}`
                      : groupName}
                  </Typography>
                </Divider>

                {regionNames.map((regionName, j) => {
                  if (regionName === "undefined") {
                    return null;
                  }
                  const missions = regions[regionName].filter(
                    (m) => !m.deleted
                  );
                  if (missions.length === 0) {
                    return null;
                  }

                  return (
                    <RegionItem
                      key={`Group-region-${regionName}`}
                      name={regionName}
                      missions={missions}
                      desiredExpansionState={searchString !== ""} // Expand if searching
                    />
                  );
                })}
                {[...groups[groupName]]
                  .filter((m) => !m.region)
                  .sort(compareYearAndName)
                  .map((mission, j) => {
                    if (!mission.deleted) {
                      if (seenMissions.has(mission.id)) {
                        return null;
                      } else {
                        seenMissions.add(mission.id);
                        return (
                          <MissionItem
                            key={`Group-Mission-${mission.id}`}
                            mission={mission}
                          />
                        );
                      }
                    } else {
                      return null;
                    }
                  })}
              </Stack>
            );
          })}
        {otherProjects.length > 0 && (
          <>
            <MenuItem className="section" passive>
              <p>{translations.Menu.Missions.OtherMissions[language]}</p>
            </MenuItem>
            {otherProjects.sort(compareYearAndName).map((mission, j) => {
              if (!mission.deleted && !seenMissions.has(mission.id)) {
                return (
                  <MissionItem
                    key={`mission-${mission.id}`}
                    mission={mission}
                  />
                );
              } else {
                return null;
              }
            })}
          </>
        )}
      </Stack>
    </Stack>
  );
}
