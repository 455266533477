import { AccountInfo, PublicClientApplication } from "@azure/msal-browser";
import { SCOPES } from "./constants";
import type { AzureDataType } from "./types";
import { axiosInstance } from "utils/request";
import { EmailAlreadyExistsError } from "./errors";
import { captureException } from "@sentry/react";
import { toast } from "react-toastify";

export async function azureLogin(
  azureData: AzureDataType,
  forceUpdateUsername: boolean,
  configId: number
) {
  const msalInstance = getMsalInstance(azureData);
  await msalInstance.initialize();
  const account = await getMsalAccount(msalInstance);
  const token = await getIdToken(msalInstance, account);
  try {
    return await axiosInstance.post("/auth/oauth/azure", {
      configId,
      token,
      overwrite: forceUpdateUsername,
    });
  } catch (err: any) {
    if (err?.response?.status === 409) {
      throw EmailAlreadyExistsError;
    } else {
      const exceptionHint = {
        event_id: "azureloginbutton.onerror",
        data: {
          token,
        },
      };
      captureException(new Error("Failed to login with Azure"), exceptionHint);
      toast.error("Failed to login with Azure");
      return;
    }
  }
}

export function getMsalInstance({
  clientId,
  authority,
  redirectUri,
}: AzureDataType) {
  const msalConfig = {
    auth: {
      clientId,
      authority,
      redirectUri,
    },
  };
  const msalInstance = new PublicClientApplication(msalConfig);

  return msalInstance;
}

export async function getMsalAccount(msalInstance: PublicClientApplication) {
  const accounts = msalInstance.getAllAccounts();
  let account: AccountInfo | null = null;
  if (accounts.length > 0) {
    // If we are already logged in with an account (happens if you spam the button)
    account = accounts[0];
  } else {
    const loginResponse = await msalInstance.loginPopup({
      scopes: SCOPES,
    });
    account = loginResponse.account;
  }
  return account;
}

export async function getIdToken(
  msalInstance: PublicClientApplication,
  account: AccountInfo
) {
  const tokenResponse = await msalInstance.acquireTokenSilent({
    scopes: SCOPES,
    account,
  });
  return tokenResponse.idToken;
}
