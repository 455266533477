import { Mission } from "interfaces";
// @ts-ignore
import md5 from "md5-hash";
import MissionItem from "./MissionItem";
import { compareYearAndName } from "utils/utils";
import { useEffect, useState } from "react";
import { useSelector } from "hooks";
import { Collapse, Stack, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

type Props = {
  name: string;
  missions: Mission[];
  desiredExpansionState: boolean;
};

export default function RegionItem({
  name,
  missions,
  desiredExpansionState,
}: Props) {
  const demoMode = useSelector((state) => state.ui.demoMode);
  const [expanded, setExpanded] = useState(desiredExpansionState);

  // Expand the region if it's desired by parent.
  // We want this so we can force expand when searching for projects. See TASK-2923 in notion.
  useEffect(() => {
    if (desiredExpansionState !== expanded) {
      setExpanded(desiredExpansionState);
    }
  }, [desiredExpansionState]);

  return (
    <Stack sx={{ position: "relative", pl: 0.5 }}>
      <Stack
        sx={{
          position: "absolute",
          top: 4,
          left: 4,
          width: 2,
          height: "calc( 100% - 8px )",
          borderLeft: "1px solid #E3E3E3",
        }}
      />

      <button
        type="button"
        style={{
          width: "100%",
          textAlign: "left",
          border: "none",
          backgroundColor: "transparent",
          padding: 0,
          margin: 0,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <ExpandMore
          style={{
            transform: expanded ? "rotate(0deg)" : "rotate(-90deg)",
            transition: "transform 0.3s",
          }}
        />
        <Typography sx={{ ml: 1 }} fontWeight="bold">
          {demoMode ? `Region ${md5(name).substring(0, 4)}` : name}
        </Typography>
      </button>

      <Collapse in={expanded}>
        {missions.sort(compareYearAndName).map((mission) => (
          <MissionItem
            key={`mission-${mission.id}`}
            mission={mission}
            inRegion
          />
        ))}
      </Collapse>
    </Stack>
  );
}
