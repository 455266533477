import Select from "react-select";
import {
  Stack,
  Box,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import {
  useNavigate,
  useSearchParams,
  useOutletContext,
  useLocation,
} from "react-router-dom";
import Preview from "./Preview";
import { Mission } from "interfaces";
import { groupBy } from "lodash";
import translations from "translations";
import pdfReportTranslations from "./translations";
import { useCurrentProject, useLanguage, useSelector } from "hooks";
import { getProjectName } from "utils/utils";
import PDFReportPopup from "./PDFReportPopup";

type IOption = {
  label: string;
  value: number;
};

export default function NewPDFReport() {
  const projects = useSelector((state) => state.user.missions);
  const { language } = useLanguage();
  const location = useLocation();
  const navigate = useNavigate();
  const context = useOutletContext<{ updateTrigger?: () => void }>();
  const currentProject = useCurrentProject();
  const [searchParams] = useSearchParams();

  const [newReportId, setNewReportId] = useState<number | null>(null);

  const updateTrigger = context?.updateTrigger;

  const onReportCreated = (reportId: number) => {
    setNewReportId(reportId);
  };

  const selectedMission = currentProject
    ? {
        label: getProjectName(currentProject.id)?.fullname,
        value: currentProject.id,
        fixable: currentProject.fixable_defects,
        processable_defects: currentProject.processable_defects,
      }
    : null;

  function onMissionSelect(option: IOption) {
    navigate(`/${option.value}/report/defect${location.search}`);

    updateTrigger?.();
  }

  const groups = groupBy(projects, (m: Mission) => m?.group?.name);
  const options = [];
  const groupKeys = Object.keys(groups).sort((a, b) => (a > b ? 1 : -1));
  for (const groupName of groupKeys) {
    const groupMissions: Mission[] = groups[groupName];
    let thisName = groupName;
    if (!groupName || groupName === "undefined") {
      thisName = translations.Inbox.Reports.NoGroup[language];
    }
    options.push({
      label: thisName,
      options: groupMissions.map((gm) => {
        const projectName = getProjectName(gm.id);
        const region = gm.region ? `${gm.region.name} -` : "";
        return {
          label: `${region} ${projectName?.fullname}`,
          value: gm.id,
        };
      }),
    });
  }

  return (
    <>
      <Box>
        <Container>
          <Typography component="h2" variant="h4" sx={{ p: 2 }}>
            {translations.Inbox.Reports.CreateNewReport[language]}
          </Typography>

          <Typography component="p" sx={{ mb: 3, ml: 2 }}>
            {pdfReportTranslations.FilterInfo[language]}
            <br />
            {pdfReportTranslations.NoFilterDefaultInfo[language]}
          </Typography>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography component="h3" variant="h6">
                {translations.words.Mission[language]}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ mb: 1 }}>
                {translations.Inbox.Reports.SelectProject[language]}
              </Typography>
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: selectedMission
                      ? "rgba(0,0,0,0)"
                      : undefined,
                  }),
                }}
                value={{
                  label: selectedMission?.label,
                  value: selectedMission?.value,
                }}
                onChange={(e) => onMissionSelect(e as IOption)}
                options={options}
              />
            </AccordionDetails>
          </Accordion>

          <Stack spacing={2} mt={2}>
            {selectedMission && (
              <Preview
                mission={selectedMission}
                projectID={selectedMission?.value}
                onReportCreated={onReportCreated}
              />
            )}
          </Stack>
        </Container>
      </Box>
      {newReportId && (
        <PDFReportPopup
          reportId={newReportId}
          onClose={() => setNewReportId(null)}
        />
      )}
    </>
  );
}
