import { useFlags } from "launchdarkly-react-client-sdk";
import ListViewContainer from "./ListViewContainer";
import OldListView from "../OldListView";

export default function FlagProtectedFlowDirectionArrow() {
  // Render conditionally on feature flags
  const { refactorMarkersList } = useFlags();

  // https://app.launchdarkly.com/projects/default/flags/refactor-markers-list
  if (refactorMarkersList) {
    return <ListViewContainer />;
  }

  // @ts-ignore
  return <OldListView />;
}
