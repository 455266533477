// import { DomainSettings, domainSettingsSchema } from "hooks";
import { EventHint, captureException } from "@sentry/react";
import { axiosInstance } from "utils/request";
import { z } from "zod";
import { Dispatch } from "redux";

// Define Zod schema for API validation
const domainSettingsSchema = z.object({
  object_type_creation: z.boolean().default(false), //Ensures default at runtime
});

// Type inferred from schema
export type DomainSettings = z.infer<typeof domainSettingsSchema>;

// Generate a TypeScript-safe initial state
export const defaultDomainSettings: DomainSettings = domainSettingsSchema.parse(
  {}
);

export function getDomainSettingsData() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axiosInstance.get("/domains/settings");
      const data = response.data;
      const validatedData = domainSettingsSchema.parse(data);
      dispatch(setDomainSettings(validatedData));
    } catch (error) {
      console.error("Error fetching domain settings:", error);
      const exceptionHint: EventHint = {
        event_id: "useDomainSettings.fetchSettings",
        originalException: error,
      };
      captureException(error, exceptionHint);
      dispatch(setDomainSettings(defaultDomainSettings));
    }
  };
}

export function setDomainSettings(settings: DomainSettings) {
  return {
    type: "SET_DOMAIN_SETTINGS",
    payload: settings,
  };
}
