import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./state/store";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { storyblokInit, apiPlugin } from "@storyblok/react";
import * as Sentry from "@sentry/react";
import {
  CMSMenu as menu,
  CMSPage as page,
  CMSMenuGroup as menu_group,
  CMSMenuItem as menu_item,
  CMSVideo as video,
} from "cms";
import {
  asyncWithLDProvider,
  basicLogger,
} from "launchdarkly-react-client-sdk";

import { LAUNCHDARKLY_CLIENT_ID } from "config";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import routes from "routes";
import { provider as MenuProvider } from "providers/MenuProvider";
import { SocketProvider } from "providers/SocketProvider";

storyblokInit({
  accessToken: "PcGGQFI47WlukN6rcKl2gQtt",
  use: [apiPlugin],
  components: {
    menu,
    page,
    menu_group,
    menu_item,
    video,
  },
});
const commitHash = process.env.REACT_APP_COMMIT_HASH || "unknown";

const getLDConfig = () => {
  if (window.mockFlags) {
    return {
      clientSideID: "",
      context: {
        anonymous: true,
      },
      options: {
        bootstrap: window.mockFlags,
        logger: basicLogger({ level: "none" }),
        streaming: false,
        sendEvents: false,
      },
      flags: window.mockFlags,
    };
  }
  return {
    clientSideID: LAUNCHDARKLY_CLIENT_ID,
    context: {
      anonymous: true,
    },
    options: {
      bootstrap: "localStorage" as const,
      logger: basicLogger({ level: "error" }),
    },
  };
};

if (!window.location.host.includes("localhost")) {
  Sentry.init({
    dsn: "https://e953d235d66545bb9a41f0a7fa406a6e@o4504530781732864.ingest.sentry.io/4504530785271808",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          /^https:\/\/map\.skyqraft\.com/,
          /^https:\/\/hawkeye\.skyqraft\.com/,
          /^https:\/\/atom\.skyqraft\.com/,
          /^https:\/\/dev\.skyqraft\.com/,
          /^https:\/\/api\.dev\.skyqraft\.com/,
          /^https:\/\/api\.hawkeye\.skyqraft\.com/,
          /^https:\/\/api\.atom\.skyqraft\.com/,
          /^https:\/\/api\.skyqraft\.com/,
          /^https:\/\/us\.skyqraft\.com/,
          /^https:\/\/api\.us\.skyqraft\.com/,
          /^https:\/\/app\.arkion\.co/,
          /^https:\/\/hawkeye\.arkion\.co/,
          /^https:\/\/atom\.arkion\.co/,
          /^https:\/\/dev\.arkion\.co/,
          /^https:\/\/api\.dev\.arkion\.co/,
          /^https:\/\/api\.hawkeye\.arkion\.co/,
          /^https:\/\/api\.atom\.arkion\.co/,
          /^https:\/\/api\.arkion\.co/,
          /^https:\/\/us\.arkion\.co/,
          /^https:\/\/api\.us\.arkion\.co/,
        ],
      }),
      new Sentry.Replay(),
    ],
    environment: window.location.host,
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    release: commitHash,
  });
}
(async () => {
  const loadApp = async () => {
    const LDProvider = await asyncWithLDProvider(getLDConfig());

    const router = createBrowserRouter(routes);

    ReactDOM.render(
      <LDProvider>
        <Provider store={store}>
          <SocketProvider>
            <MenuProvider>
              <RouterProvider router={router} />
            </MenuProvider>
          </SocketProvider>
        </Provider>
      </LDProvider>,
      document.getElementById("root")
    );
  };

  if (document.visibilityState === "visible") {
    await loadApp();
  } else {
    document.addEventListener(
      "visibilitychange",
      async function onVisibilityChange() {
        if (document.visibilityState === "visible") {
          document.removeEventListener("visibilitychange", onVisibilityChange);
          await loadApp();
        }
      }
    );
  }
})();
