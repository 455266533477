import { Box } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useRafState } from "react-use";
import { useInputListener } from "../../hooks";
import { useNewBoxListener } from "../../hooks/newBoxListener";
import { AnnotationContext, CanvasContext } from "../../provider";
import CanvasBox from "../CanvasBox";
import SpeedImage from "../SpeedImage";
import { compose, scale } from "transformation-matrix";
import { useEventCallback } from "utils";
import { getTopLeft } from "../../utils";
import { useSelector } from "hooks";
import { toast } from "react-toastify";
import { captureException, EventHint } from "@sentry/react";
import objectPasteValidation from "./ObjectPasteValidation";
import { useUserCanEditDetections } from "views/AnnotationTool/hooks/useUserCanEditDetections";

interface IBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

export function Canvas() {
  const user = useSelector((state) => state.user);
  const cdn = useSelector((state) => state.image?.current?.cdn);
  const currentImage = useSelector((state) => state.image.current);
  const imageId = useSelector((state) => state.image?.current?.id);
  const {
    annotations,
    deleteAnnotation,
    setSelectedAnnotation,
    setAnnotations,
    isSelectedAnnotation,
  } = useContext(AnnotationContext);
  const { setMode, mode, modeOptions } = useContext(CanvasContext);
  const { matrix, imageDimensions, changeImageDimensions, setMatrix } =
    useContext(CanvasContext);

  const canEditDetections = useUserCanEditDetections();

  const canvasEl = useRef<HTMLCanvasElement | null>(null);

  //Use effect to listen for shortcut keys
  useEffect(() => {
    const handleShortcutKeys = (e: KeyboardEvent) => {
      // If the target element is a comment or itemID input, don't trigger the shortcut keys
      const targetElement = e.target as HTMLElement;
      if (targetElement.classList) {
        if (targetElement.classList.contains("commentInput")) {
          return;
        }
        if (targetElement.classList.contains("MuiInputBase-input")) {
          return;
        }
      }

      if (e.key === "1") {
        setSelectedAnnotation(null);
        mode === modeOptions.ADD_DETECTION
          ? setMode(modeOptions.VIEW)
          : setMode(modeOptions.ADD_DETECTION);
      }
      if (e.key === "2") {
        setSelectedAnnotation(null);
        mode === modeOptions.ADD_DEFECT
          ? setMode(modeOptions.VIEW)
          : setMode(modeOptions.ADD_DEFECT);
      }
      if (e.key === "Delete" && canEditDetections) {
        let hasSomeAiNotification = false;
        for (const annotation of annotations) {
          const hasSomeAi = annotation.creator_is_ai.some(
            (ai: boolean) => ai === true
          );
          if (hasSomeAi) {
            hasSomeAiNotification = true;
          } else if (isSelectedAnnotation(annotation.id)) {
            deleteAnnotation(annotation.id);
          }
        }
        // Place outside loop to only trigger one
        // notification if there are multiple AI annotations
        if (hasSomeAiNotification) {
          toast.error("Cannot delete AI annotations");
        }
      }
    };

    const handlePaste = (e: ClipboardEvent) => {
      setSelectedAnnotation(null);
      // Read from clipboard and deserialize
      navigator.clipboard
        .readText()
        .then((text) => {
          const copiedAnnotation = JSON.parse(atob(text));
          if (objectPasteValidation(copiedAnnotation)) {
            // This is to get a clean copy of the annotation
            const adjustedX = Math.min(
              copiedAnnotation.x + 0.03,
              1 - copiedAnnotation.w
            );
            const adjustedY = Math.min(
              copiedAnnotation.y + 0.03,
              1 - copiedAnnotation.h
            );
            const newAnnotation = {
              ...copiedAnnotation,
              x: adjustedX,
              y: adjustedY,
              id: `n${Math.random().toString(36)}`,
              objectHasNoDefect: false,
              steelwork: copiedAnnotation?.steelwork?.id
                ? {
                    ...copiedAnnotation.steelwork,
                    pole_id: currentImage?.pole_id || null,
                    id: -1,
                    parent_link: null,
                    child_link: null,
                  }
                : null,
            };
            setAnnotations([...annotations, newAnnotation]);
          } else {
            toast.error("Failed to read annotation from clipboard");
            //add a sentry error logg
            const exceptionHint: EventHint = {
              event_id:
                "annotationTool.Canvas.handlePaste.objectPasteValidation.failed",
              data: {
                image_id: imageId,
                object: copiedAnnotation,
              },
            };
            captureException(
              new Error("Failed to read annotation from clipboard"),
              exceptionHint
            );
          }
        })
        .catch((err) => {
          toast.error("Failed to read annotation from clipboard");
          console.error("Failed to read annotation from clipboard", err);
          //add a sentry error logg
          const exceptionHint: EventHint = {
            event_id: "annotationTool.Canvas.handlePaste",
            originalException: err,
            data: {
              image_id: imageId,
            },
          };
          captureException(err, exceptionHint);
        });
    };
    // @ts-ignore
    window.addEventListener("keydown", handleShortcutKeys);
    // @ts-ignore
    window.addEventListener("paste", handlePaste);
    return () => {
      // @ts-ignore
      window.removeEventListener("keydown", handleShortcutKeys);
      // @ts-ignore
      window.removeEventListener("paste", handlePaste);
    };
  }, [mode, annotations, deleteAnnotation, setSelectedAnnotation, setMode]);

  const [currentBox, setCurrentBox] = useRafState<IBox>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  // Let the custom hook manage matrix
  // manipulations so we can navigate the image
  useInputListener({
    canvasRef: canvasEl,
  });
  useNewBoxListener({
    canvasRef: canvasEl,
    setCurrentBox,
  });

  const canvas = canvasEl.current;
  const canvasWidth = canvas?.clientWidth ?? 0;
  const canvasHeight = canvas?.clientHeight ?? 0;

  const [clientWidthBefore, setClientWidthBefore] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      const newClientWidth = entries[0].contentRect.width;
      // @ts-ignore
      const widthScale = imageDimensions.naturalWidth / newClientWidth;
      const newScale = Math.min(widthScale);
      const newMatrix = compose(scale(newScale));

      if (
        clientWidthBefore !== newClientWidth &&
        imageDimensions.naturalWidth
      ) {
        setMatrix(newMatrix);
        setClientWidthBefore(newClientWidth);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (canvasEl.current) {
      resizeObserver.observe(canvasEl.current);
    }

    return () => {
      // Disconnect the observer when the component is unmounted
      resizeObserver.disconnect();
    };
  }, [imageDimensions.naturalWidth, clientWidthBefore, setMatrix]);
  // @ts-ignore
  const onVideoOrImageLoaded = useEventCallback((_ref2) => {
    if (_ref2.naturalHeight !== 10) {
      const naturalWidth = _ref2.naturalWidth;
      const naturalHeight = _ref2.naturalHeight;
      const duration = _ref2.duration;
      const dims = {
        naturalWidth: naturalWidth,
        naturalHeight: naturalHeight,
        duration: duration,
      };
      changeImageDimensions(dims);
      setImageLoaded(true);
    }
  });

  const topLeft = getTopLeft(matrix);

  return (
    <Box
      sx={{
        height: "calc(100% - 22px)",
        overflow: "hidden",
        position: "relative",
        margin: "10px",
      }}
    >
      <canvas
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          zIndex: 2,
          touchAction: "none",
          cursor:
            mode === modeOptions.ADD_DEFECT ||
            mode === modeOptions.ADD_DETECTION ||
            mode === modeOptions.ADD_STEELWORK
              ? "crosshair"
              : "grab",
        }}
        id="imageCanvas"
        data-testid={`annotation-tool-canvas-image-loaded-${imageLoaded}`}
        ref={canvasEl}
      />

      {canvas &&
        canvasWidth &&
        canvasHeight &&
        annotations &&
        annotations.map((annotation) => (
          <CanvasBox
            key={annotation.id}
            annotation={annotation}
            canvasWidth={canvasWidth}
            canvasRef={canvasEl}
            imageDimensions={imageDimensions}
          />
        ))}

      {currentBox.width > 0 &&
        currentBox.height > 0 &&
        currentBox.y &&
        currentBox.x && (
          <CanvasBox
            imageDimensions={imageDimensions}
            canvasRef={canvasEl}
            annotation={{
              id: `n${currentBox.width}`,
              x: currentBox.x,
              y: currentBox.y,
              w: currentBox.width,
              h: currentBox.height,
              isLocked: false,
              creator: [user.name],
              creator_is_ai: [false],
              updated_by: [user.name],
              origin: null,
              fixed: null,
              reported: null,
              ring_count: null,
              processed: [],
              types: [null],
              severities: [null],
              workflow_status: [1],
              skyqraft_hidden: [false],
              objectHasNoDefect: true,
              type_comment: [""],
              type_id: [null],
              confidence: [1],
              active: true,
              is_defect: [false],
              categories: [null],
              // highlighted: true,
              client_types: [null],
              visible: true,
              editIndex: null,
              editType: null,
              hover: false,
              rotation: 0,
              item_id: null,
              steelwork: null,
            }}
            canvasWidth={canvasWidth}
          />
        )}

      {!!imageId && (
        <SpeedImage
          imagePosition={{
            topLeft: topLeft,
            bottomRight: {
              // @ts-ignore
              x: topLeft.x + imageDimensions.naturalWidth / matrix.a,
              // @ts-ignore
              y: topLeft.y + imageDimensions.naturalHeight / matrix.d,
            },
          }}
          // @ts-ignore
          canvas={canvasEl.current}
          onLoad={onVideoOrImageLoaded}
          image={imageId}
          // @ts-ignore
          cdn={cdn}
        />
      )}
    </Box>
  );
}
export default Canvas;
