import React, { useState, useEffect, ReactNode } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Stack,
  Typography,
  Menu,
  Chip,
  MenuItem,
  Box,
  SxProps,
} from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import { Clear, Cloud } from "@mui/icons-material";
import MapListButton from "./MapListButton";
import { useSelector, useDispatch, useCurrentProject } from "hooks";
import {
  setInspectorMode,
  changeAnnotatorColor,
  toggleDemoMode,
  closeStreetView,
  setSupervisorMode,
  updatePowerlineStyle,
  setTopologyMode,
} from "state/actions";
import Profile from "./Profile";

type Props = {
  title: ReactNode;
  link?: string;
  sx?: SxProps;
};

enum Mode {
  Normal = "Normal",
  Demo = "Demo",
  Inspector = "Inspector",
  Color = "Color",
  Supervisor = "Supervisor",
  Topology = "Topology",
}

export default function StatusBar({ title, link, sx = {} }: Props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [modeAnchorEl, setModeAnchorEl] = useState<null | HTMLElement>(null);
  const demoMode = useSelector((state) => state.ui.demoMode);
  const inspectorMode = useSelector((state) => state.map.inspectorMode);
  const topologyMode = useSelector((state) => state.map.topologyMode);
  const supervisorMode = useSelector((state) => state.ui.supervisorMode);
  const showZones = useSelector((state) => state.ui.showZones);
  const annotatorObjectColor = useSelector(
    (state) => state.user.annotator_color
  );
  const streetViewVisible = useSelector((state) => state.ui.inStreetView);
  const domains = useSelector((state) => state.user.domains);
  let project = useCurrentProject();
  const params = useParams();
  if (!params.mission) {
    project = null;
  }

  const open = Boolean(anchorEl);
  const modeOpen = Boolean(modeAnchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  function handleModeClick(event: React.MouseEvent<HTMLElement>) {
    setModeAnchorEl(event.currentTarget);
  }

  function handleModeClose() {
    setModeAnchorEl(null);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  let currentMode = "Normal";

  if (demoMode) {
    currentMode = "Demo";
  } else if (inspectorMode) {
    currentMode = "Inspector";
  } else if (annotatorObjectColor) {
    currentMode = "Color";
  } else if (supervisorMode) {
    currentMode = "Supervisor";
  } else if (topologyMode) {
    currentMode = "Topology";
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: Trigger updates based on dependencies.
  useEffect(() => {
    dispatch(updatePowerlineStyle());
  }, [annotatorObjectColor, updatePowerlineStyle, currentMode, showZones]);

  const domainName =
    domains.find((domain) => domain.url === window.location.hostname)?.name ??
    window.location.hostname.split(".")[0];

  function setMode(mode: Mode) {
    const newInspectorMode = mode === Mode.Inspector;
    if (inspectorMode !== newInspectorMode)
      dispatch(setInspectorMode(newInspectorMode));

    const newSupervisorMode = mode === Mode.Supervisor;
    if (supervisorMode !== newSupervisorMode)
      dispatch(setSupervisorMode(newSupervisorMode));

    const newDemoMode = mode === Mode.Demo;
    if (demoMode !== newDemoMode) dispatch(toggleDemoMode(newDemoMode));

    const newTopologyMode = mode === Mode.Topology;
    if (topologyMode !== newTopologyMode)
      dispatch(setTopologyMode(newTopologyMode));

    const newAnnotatorObjectColor = mode === Mode.Color;
    if (annotatorObjectColor !== newAnnotatorObjectColor)
      dispatch(changeAnnotatorColor(newAnnotatorObjectColor));
  }

  return (
    <Stack
      direction="row"
      alignContent="center"
      justifyContent="space-between"
      width="100%"
      sx={{
        backgroundColor: "white",
        boxShadow: "0px 0px 4px #777",
        height: "50px",
        ...sx,
      }}
    >
      <Stack
        direction="row"
        alignContent="center"
        spacing={2}
        justifyContent={{ xs: "space-between" }}
        alignItems="center"
        pr={1}
        width="100%"
      >
        <Stack alignItems="center" justifyContent="center">
          {link ? (
            <Link
              to={link ? link : "#"}
              style={{
                display: "grid",
                placeItems: "center",
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <Typography variant="h6" sx={{ ml: 1, placeItems: "center" }}>
                {title}
              </Typography>
            </Link>
          ) : (
            <Typography variant="h6" sx={{ ml: 1, placeItems: "center" }}>
              {title}
            </Typography>
          )}
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          pr={0}
        >
          <Stack direction="row" spacing={1}>
            <Stack display={{ xs: project ? "flex" : "none", md: "flex" }}>
              <MapListButton />
            </Stack>
            {streetViewVisible && (
              <Chip
                clickable
                deleteIcon={<Clear />}
                sx={{ alignSelf: "center" }}
                label="Streetview"
                onDelete={() => dispatch(closeStreetView())}
              />
            )}
            <Box
              display={{
                xs: "none",
                sm: "block",
              }}
            >
              <RoleWrapper keyName="modeSelector">
                <Stack display={{ xs: project ? "flex" : "none", md: "flex" }}>
                  <Chip
                    data-testid="statusBar.selectMode"
                    onClick={handleModeClick}
                    aria-controls={modeOpen ? "mode-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={modeOpen ? "true" : undefined}
                    label={currentMode}
                    sx={{ alignSelf: "center", pointer: "cursor" }}
                  />
                </Stack>
              </RoleWrapper>
            </Box>
            <Box
              display={{
                xs: "none",
                md: "block",
              }}
            >
              <Chip
                clickable
                onClick={handleClick}
                icon={<Cloud />}
                label={domainName}
                sx={{ pl: 0.5 }}
              />
            </Box>
          </Stack>
          <Profile />
        </Stack>
      </Stack>
      <Menu
        anchorEl={modeAnchorEl}
        id="mode-menu"
        open={modeOpen}
        onClose={handleModeClose}
        onClick={handleModeClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            setMode(Mode.Normal);
            handleModeClose();
          }}
        >
          Normal
        </MenuItem>
        <div>
          <RoleWrapper keyName="demonstrationMode">
            <MenuItem
              onClick={() => {
                setMode(Mode.Demo);
                handleModeClose();
              }}
            >
              Demo mode
            </MenuItem>
          </RoleWrapper>
        </div>
        <div>
          <MenuItem
            onClick={() => {
              setMode(Mode.Topology);
              handleModeClose();
            }}
          >
            Topology mode
          </MenuItem>
        </div>
        <div>
          <RoleWrapper keyName="inspectorMode">
            <MenuItem
              onClick={() => {
                setMode(Mode.Inspector);
                handleModeClose();
              }}
              data-testid="statusBar.selectMode.inspector"
            >
              Inspector Mode
            </MenuItem>
          </RoleWrapper>
        </div>
        <div>
          <RoleWrapper keyName="supervisorMode">
            <MenuItem
              onClick={() => {
                setMode(Mode.Supervisor);
                handleModeClose();
              }}
            >
              Supervisor Mode
            </MenuItem>
          </RoleWrapper>
        </div>
        <div>
          <RoleWrapper keyName="inspectorMode">
            <MenuItem
              onClick={() => {
                setMode(Mode.Color);
                handleModeClose();
              }}
            >
              Color Mode
            </MenuItem>
          </RoleWrapper>
        </div>
      </Menu>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {domains.map((domain) => (
          <a
            key={domain.id}
            href={
              domain.url === "localhost"
                ? `http://${domain.url}:3000`
                : `https://${domain.url}`
            }
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <MenuItem key={domain.id}>{domain.name}</MenuItem>
          </a>
        ))}
      </Menu>
    </Stack>
  );
}
