import { EventHint, captureException } from "@sentry/browser";
import { getAnnotations } from "api/image/annotation";
import { ISkyqraftHiddenSetting } from "api/user/userSettings";
import { ImageFeedback, ImageFeedbackItem, ImageMeta } from "interfaces";
import { toast } from "react-toastify";
import {
  setReviewLoading,
  setSelectedPreviousDefectImage,
  updateMarkers,
} from "state/actions";
import { RootState } from "state/store";
import { authorizedGet, authorizedPost, axiosInstance } from "utils/request";

type IAnnotation = Awaited<ReturnType<typeof getAnnotations>>[number];
// @ts-ignore
export const setImageList = (list) => ({
  type: "SET_IMAGE_LISTS",
  payload: list,
});

export const flagImageForReannotation = (
  image: number,
  value: boolean, // @ts-ignore
  callback: () => void = null
) => {
  // @ts-ignore
  return async (dispatch, getState) => {
    const endpoint = value ? "flag" : "unflag";
    authorizedPost(`/image/${image}/${endpoint}`, {})
      .then(() => {
        const state: RootState = getState();
        const imageMeta = state.image.current;
        // @ts-ignore
        if (image === imageMeta.id) {
          dispatch(
            // @ts-ignore
            setImageMeta({
              ...imageMeta,
              flagged: value,
            })
          );
        }

        callback?.();
      })
      .catch(() => {
        toast.error(`Flagging image #${image} failed`);
      });
  };
};

export function blurAllImages(
  projectID: number,
  events: {
    callback?: () => void; // @ts-ignore
    onError?: (e) => void;
    finally?: () => void;
  } = {}
) {
  return async () => {
    try {
      await axiosInstance.post(`/mission/blur_images/${projectID}`, null);
      events.callback?.();
    } catch (error) {
      const exceptionHint: EventHint = {
        event_id: "actions.image.blurAllImages.request",
        originalException: error,
      };
      captureException(error, exceptionHint);
      // @ts-ignore
      events?.onError(error);
    }
    // @ts-ignore
    events?.finally();
  };
}

export function setImageMeta(meta: ImageMeta) {
  return {
    type: "SET_CURRENT_IMAGE",
    payload: meta,
  };
}

export function setAnnotations(
  annotations: IAnnotation[],
  image: number,
  current: boolean
) {
  return {
    type: "SET_ANNOTATIONS",
    payload: annotations,
    image,
    current,
  };
}

export function setFilteredAnnotations(
  filteredAnnotations: IAnnotation[],
  image: number,
  current: boolean
) {
  return {
    type: "SET_FILTERED_ANNOTATIONS",
    payload: {
      annotations: filteredAnnotations,
      image: image,
      current: current,
    },
  };
}

export function updateAnnotations(
  projectID: number,
  imageID: number,
  showSkyqraftHidden: ISkyqraftHiddenSetting,
  showDsoTso: boolean,
  altProject: number | null = null
) {
  // @ts-ignore
  return async (dispatch, getState) => {
    if (!imageID) {
      return;
    }
    const state: RootState = getState();
    const projects = state.user.missions;
    const project = projects.find((p) => p.id === projectID);
    if (!project) {
      return;
    }
    dispatch(setReviewLoading(true));
    let annotations: null | IAnnotation[] = null;
    try {
      annotations = await getAnnotations(
        projectID,
        imageID,
        showSkyqraftHidden,
        showDsoTso,
        altProject
      );
    } catch (error) {
      const exceptionHint: EventHint = {
        event_id: "actions.image.updateAnnotations.request",
        originalException: error,
        data: {
          projectID,
          imageID,
          showSkyqraftHidden,
        },
      };
      captureException(error, exceptionHint);
      toast.error(
        "Failed to get annotations. Arkion have been notified of the issue."
      );
      return;
    }
    if (annotations !== null) {
      dispatch(setReviewLoading(false));
      dispatch(setAnnotations(annotations, imageID, true));
    }
  };
}

export function getImageMeta(
  projectID: number,
  imageID: number,
  altProject: number | null = null
) {
  // @ts-ignore
  return async (dispatch, getState) => {
    const state: RootState = getState();
    let query = "";
    if (altProject !== null) {
      query = `?mission=${altProject}`;
    } else {
      dispatch(setSelectedPreviousDefectImage(null));
    }
    const projects = state.user.missions;
    const project = projects.find((p) => p.id === projectID);
    if (!project) {
      return;
    }
    try {
      const response = await axiosInstance.get<ImageMeta>(
        `/image/${imageID}/meta${query}`,
        {
          headers: {
            MissionID: projectID.toString(),
          },
        }
      );
      const imageMeta = response.data;
      dispatch(setImageMeta(imageMeta));
      const skyqraftHiddenSetting = state.user.skyqraft_hidden;
      const showDsoTso = state.user.show_dso_tso;

      // Only update annotations from here if in previous year defect mode
      // Otherwise we fetch based on the image in the url
      if (
        state.map.previousDefectPoles.length ||
        state.imageViewer.previousDefectImages.length
      ) {
        dispatch(
          updateAnnotations(
            projectID,
            imageID,
            skyqraftHiddenSetting,
            showDsoTso,
            altProject
          )
        );
      }
    } catch (error) {
      console.error(error);
      const exceptionHint: EventHint = {
        event_id: "actions.image.getImageMeta.request",
        originalException: error,
        data: {
          projectID,
          imageID,
        },
      };
      captureException(error, exceptionHint);
      toast.error(`Failed to get image meta for image ${imageID}`);
    }
  };
}

export function clearImageMeta() {
  return {
    type: "SET_CURRENT_IMAGE",
    payload: null,
  };
}

export function hideImage(mission: number | string, image: number | string) {
  // TODO: Mission is never used as it's included in the header but it can't be removed from parameters due to dependencies elsewhere.
  const ALERT_STRING =
    "Are you sure you want to permanently hide the image for all users? This can only be undone by marking a region on the map (in inspector mode) and then select to show all images in that region again.";
  // @ts-ignore
  return async (dispatch, getState) => {
    //Send data to database
    if (window.confirm(ALERT_STRING)) {
      authorizedPost(`/image/hidden?imageId=${image}&hidden=true`)
        .then(() => {
          dispatch(updateMarkers());
        })
        .catch(() => {
          toast.error("Unable to hide image");
        });
    }
  };
}

export function setImageFeedback(feedbacks: ImageFeedbackItem[]) {
  return {
    type: "SET_IMAGE_FEEDBACK",
    payload: feedbacks,
  };
}

export function getImageFeedback(
  // @ts-ignore
  image_id,
  // @ts-ignore
  callback: () => void = null,
  // @ts-ignore
  onError: () => void = null
) {
  // @ts-ignore
  return async (dispatch) => {
    const response = await authorizedGet<ImageFeedback>(
      `/image/${image_id}/feedback`
    );
    if (response) {
      dispatch(setImageFeedback(response.feedback));
      callback?.();
    } else {
      onError?.();
    }
  };
}

export function sendFeedback(
  image_id: number,
  feedback_type: number,
  // @ts-ignore
  comment: string = null,
  // @ts-ignore
  callback: (response) => void = null,
  // @ts-ignore
  onError: () => void = null
) {
  // @ts-ignore
  return async (dispatch) => {
    const response = await authorizedPost<string>(
      `/image/${image_id}/feedback`,
      { feedback_type, comment }
    );
    if (response) {
      dispatch(
        getImageFeedback(
          image_id,
          () => {
            callback?.(response);
          },
          onError
        )
      );
    } else {
      onError?.();
    }
  };
}

export function resolveFeedback(
  image_id: number,
  feedback_id: number,
  // @ts-ignore
  callback: () => void = null,
  // @ts-ignore
  onError: () => void = null
) {
  // @ts-ignore
  return async (dispatch) => {
    const response = await authorizedPost<ImageFeedback>(
      `/image/${image_id}/feedback/${feedback_id}/resolve`
    );
    if (response) {
      dispatch(getImageFeedback(image_id, callback, onError));
    } else {
      onError?.();
    }
  };
}

export function setThumbnailRegexPattern(pattern: string | null) {
  return {
    type: "SET_THUMBNAIL_REGEX_PATTERN",
    payload: pattern,
  };
}

export function setThumbnailPoleItemPlacement(id: number | null) {
  return {
    type: "SET_THUMBNAIL_POLE_ITEM_PLACEMENT",
    payload: id,
  };
}

export function setSavedObjectTypeId(id: number | null) {
  return {
    type: "SET_SAVED_OBJECT_TYPE_ID",
    payload: id,
  };
}

export function setSavedDefectTypeId(id: number | null) {
  return {
    type: "SET_SAVED_DEFECT_TYPE_ID",
    payload: id,
  };
}
