import { Divider, Stack, Typography } from "@mui/material";
import { RoleWrapper } from "components";
import ClientNameSwitch from "components/Settings/ClientNameSwitch";
import ClusterSwitch from "components/Settings/ClusterSwitch";
import DateBeforeProjectName from "components/Settings/DateBeforeProjectName";
import MapType from "components/Settings/MapType";
import MaxImageDistanceToMoveMap from "components/Settings/MaxImageDistanceToMoveMap";
import ThumbnailSorting from "components/Settings/ThumbnailSorting";
import ZoomSpeed from "components/Settings/ZoomSpeed";

export default function MySettings() {
  const optionSize = 4;
  const switchSize = 9;
  return (
    <Stack spacing={1} height="100%" overflow="auto">
      <Typography pl={1} fontWeight="bold">
        My Settings
      </Typography>
      <Typography pl={1} variant="body2">
        These settings modify how the app behaves for you. You can change your
        settings at any time.
      </Typography>
      <Divider>Saved settings</Divider>
      <ZoomSpeed size={optionSize} />
      <RoleWrapper keyName="editClientNaming">
        <ClientNameSwitch size={optionSize} />
      </RoleWrapper>

      <Divider>Temporary settings</Divider>
      <MaxImageDistanceToMoveMap size={switchSize} />
      <DateBeforeProjectName size={switchSize} />
      <RoleWrapper keyName="clusterSwitch">
        <ClusterSwitch size={switchSize} />
        <ThumbnailSorting size={optionSize} />
      </RoleWrapper>
      <MapType size={optionSize} />
    </Stack>
  );
}
