import { useEffect } from "react";
import debounce from "lodash/debounce";

interface IProps {
  ref: React.RefObject<HTMLDivElement>;
  onWidthChange: (width: number) => void;
}
export function useWidthObserver(props: IProps) {
  const { ref, onWidthChange } = props;

  useEffect(() => {
    if (!ref?.current) return;

    const handleResize = debounce((entries: ResizeObserverEntry[]) => {
      const entry = entries[0]; // We only observe 1 element

      requestAnimationFrame(() => {
        onWidthChange(entry.contentRect.width);
      });
    }, 100);

    const resizeObserver = new ResizeObserver(handleResize);

    resizeObserver.observe(ref.current);

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [ref, onWidthChange]);
}
