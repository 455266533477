import * as ActionsTranslations from "./Actions";
import * as ImageTranslations from "./Images";
import * as OtherTranslations from "./Other";
import * as PoleTranslations from "./Pole";
import { Translated } from "./utils";

export type ILanguages = "EN" | "SE" | "NO";

const translations = {
  ...OtherTranslations,
  ...ActionsTranslations,
  ...ImageTranslations,
  ...PoleTranslations,
  Analysis: Translated({ EN: "Analysis", SE: "Analys", NO: "Analyse" }),
  CriticalAnalysis: Translated({
    EN: "Critical Analysis",
    SE: "Kritisk Analys",
    NO: "Kritisk Analyse",
  }),
  Of: Translated({ EN: "of", SE: "av", NO: "av" }),
  NotAssigned: Translated({
    EN: "Not assigned",
    SE: "Inte tilldelad",
    NO: "Ikke tildelt",
  }),
  EditClientSeverities: Translated({
    EN: "Edit Client Severities",
    SE: "Redigera klient allvarlighetsgrader",
    NO: "Rediger klient alvorlighetsgrader",
  }),
  EditClientIssueType: Translated({
    EN: "Edit client defect type for id",
    SE: "Redigera klient skadetyp för id",
    NO: "Rediger klient defekttype for id",
  }),
  Edit: Translated({ EN: "Edit", SE: "Redigera", NO: "Rediger" }),
  Disconnect: Translated({
    EN: "Disconnect",
    SE: "Ta bort koppling",
    NO: "Koble fra",
  }),
  CustomizeDefectTypes: Translated({
    EN: "Customize defect types",
    SE: "Anpassa skadekategorier",
    NO: "Tilpass defekttyper",
  }),
  ClientDefectType: Translated({
    EN: "Client defect type",
    SE: "Klient skadetyper",
    NO: "Klient defekttype",
  }),
  ClientSeverityTypes: Translated({
    EN: "Client severity types",
    SE: "Klient allvarlighetsgrad typer",
    NO: "Klient alvorlighetsgrad typer",
  }),
  ClientObjectTypeCategory: Translated({
    EN: "Client object type category",
    SE: "Klient objekttyp kategori",
    NO: "Klient objekttype kategori",
  }),
  AddANewClientObjectTypeCategory: Translated({
    EN: "Add a new client object type category",
    SE: "Lägg till en ny klient objekttyps kategori",
    NO: "Legg til en ny klient objekttype kategori",
  }),
  DidYouMissAnyCategories: Translated({
    EN: "Did you miss any categories in the list? Please, add it",
    SE: "Saknade du några kategorier i listan? Lägg gärna till den",
    NO: "Manglet du noen kategorier i listen? Vennligst legg til",
  }),
  CategoryName: Translated({
    EN: "Category name",
    SE: "Kategori namn",
    NO: "Kategorinavn",
  }),
  CategoryId: Translated({
    EN: "Category id",
    SE: "Kategori id",
    NO: "Kategori id",
  }),
  CategoryInternalId: Translated({
    EN: "Category internal id",
    SE: "Kategori internt id",
    NO: "Kategori internt id",
  }),
  AddClientDefectType: Translated({
    EN: "Select a defect type or type in a new one",
    SE: "Välj en skadetyp eller skriv in en ny",
    NO: "Velg en defekttype eller skriv inn en ny",
  }),
  AddSeverity: Translated({
    EN: "Select a severity or type in a new one",
    SE: "Välj en allvarlighetsgrad eller skriv in en ny",
    NO: "Velg en alvorlighetsgrad eller skriv inn en ny",
  }),
  AddANewClientDefectType: Translated({
    EN: "Add a new client defect type",
    SE: "Lägg till en ny klient skadetyp",
    NO: "Legg til en ny klient defekttype",
  }),
  AddANewClientSeverityType: Translated({
    EN: "Add a new client severity type",
    SE: "Lägg till en ny klient allvarlighetsgradstyp",
    NO: "Legg til en ny klient alvorlighetsgradstype",
  }),
  TypeName: Translated({
    EN: "type name",
    SE: "typ namn",
    NO: "type navn",
  }),
  Color: Translated({
    EN: "Color",
    SE: "Färg",
    NO: "Farge",
  }),
  ClientInternaldefectCode: Translated({
    EN: "Client internal defect code that can be included in all excel reports",
    SE: "Klient internt id för skadetyp som kan inkluderas i excelrapporter",
    NO: "Klient internt defektkode som kan inkluderes i alle excelrapporter",
  }),
  InternalId: Translated({
    EN: "Internal id",
    SE: "Internt id",
    NO: "Internt id",
  }),
  Client: Translated({
    EN: "Client",
    SE: "Klient",
    NO: "Klient",
  }),
  Name: Translated({
    EN: "Name",
    SE: "Namn",
    NO: "Navn",
  }),
  SeverityName: Translated({
    EN: "severity name",
    SE: "allvarlighetsgrad namn",
    NO: "alvorlighetsgrad navn",
  }),
  DidYouMissAnyTypes: Translated({
    EN: "Did you miss any types in the list? Please, add it",
    SE: "Saknade du några typer i listan? Lägg gärna till den",
    NO: "Manglet du noen typer i listen? Vennligst legg til",
  }),
  AdvancedFilter: Translated({
    EN: "Advanced",
    SE: "Avancerat",
    NO: "Avansert",
  }),
  AdvancedFilterToolTip: Translated({
    EN: "Advanced settings for Arkion employees",
    SE: "Avancerade inställningar för Arkion-anställda",
    NO: "Avanserte innstillinger for Arkion-ansatte",
  }),
  Flagged: Translated({
    EN: "Flagged images",
    SE: "Flaggade bilder",
    NO: "Flaggede bilder",
  }),
  FlaggedToolTip: Translated({
    EN: "Filter images flagged for reannotation",
    SE: "Filtrera bilder flaggade för om-annotering",
    NO: "Filtrer bilder flagget for om-annotering",
  }),
  ShowSkyqraftHidden: Translated({
    EN: "Show Arkion hidden",
    SE: "Visa Arkion dolda",
    NO: "Vis Arkion skjult",
  }),
  ShowSkyqraftHiddenTooltip: Translated({
    EN: "Show hidden objects in the filter result",
    SE: "Visa dolda objekt i filter resultatet",
    NO: "Vis skjulte objekter i filterresultatet",
  }),
  Confidence: Translated({
    EN: "Confidence",
    SE: "Förtroende",
    NO: "Tillit",
  }),
  ConfidenceToolTip: Translated({
    EN: "Filter customizable confidence values of the machine learning ",
    SE: "Filtera anpassad förtroendenivån för machine learning",
    NO: "Filtrer tilpassede tillitsverdier for maskinlæring",
  }),
  AnnotatorID: Translated({
    EN: "Annotator ID",
    SE: "AnnoterarID",
    NO: "AnnotererID",
  }),
  AnnotatorIDToolTip: Translated({
    EN: "Filter the work done by any annotator",
    SE: "Filtrera ID av användare som annoterat en bild",
    NO: "Filtrer arbeidet gjort av en annotator",
  }),
  Date: Translated({
    EN: "Date",
    SE: "Datum",
    NO: "Dato",
  }),
  DateToolTip: Translated({
    EN: "Filter images by date",
    SE: "Filtrera bilder efter datum",
    NO: "Filtrer bilder etter dato",
  }),
  DateFilter: Translated({
    EN: "Date Filter",
    SE: "Datumfilter",
    NO: "Dato Filter",
  }),
  DateFilterTooltip: Translated({
    EN: "Filter found images based on the date",
    SE: "Filtrera bilder baserat på datum",
    NO: "Filtrer bilder basert på dato",
  }),
  DatePlaced: Translated({
    EN: "Date defect placed",
    SE: "Datum defekt placerad",
    NO: "Dato defekt plassert",
  }),
  DatePlacedTooltip: Translated({
    EN: "Find all defects created during a specific time window",
    SE: "Hitta alla defekter skapade under ett specifikt tidsfönster",
    NO: "Finn alle avvik opprettet i et spesifikt tidsvindu",
  }),
  Processed: Translated({
    EN: "Date processed",
    SE: "Datum bearbetad",
    NO: "Dato behandlet",
  }),
  ProcessedTooltip: Translated({
    EN: "Find all defects processed within a time period",
    SE: "Hitta alla defekter bearbetade inom en tidsperiod",
    NO: "Finn alle avvik behandlet innen en tidsperiode",
  }),
  StartDate: Translated({
    EN: "Start date",
    SE: "Startdatum",
    NO: "Startdato",
  }),
  EndDate: Translated({
    EN: "End date",
    SE: "Slutdatum",
    NO: "Sluttdato",
  }),
  Remarks: Translated({
    EN: "Remarks",
    SE: "Upplysningar",
    NO: "Merknader",
  }),
  Defects: Translated({
    EN: "Defects",
    SE: "Skador",
    NO: "Avvik",
  }),
  Object: Translated({
    EN: "Object",
    SE: "Objekt",
    NO: "Objekt",
  }),
  FlightFilter: Translated({
    EN: "Flight filter",
    SE: "Flygfilter",
    NO: "Flyfilter",
  }),
  FlightFilterToolTip: Translated({
    EN: "Filter different flight ID's. Most commonly used by drone pilots for root causing in case of issues with the flight and/or image upload",
    SE: "Filtrera olika flight ID. Används oftast av drönarpiloter vid eventuell felsökning av flygningar och/eller vid bilduppladdning",
    NO: "Filtrer forskjellige fly-ID-er. Mest brukt av dronepiloter for å finne årsaken til problemer med flygingen og/eller bildeopplasting",
  }),
  ImageLists: Translated({
    EN: "Image Lists",
    SE: "Bildlistor",
    NO: "Bilde lister",
  }),
  ImageListsToolTip: Translated({
    EN: "Manage image lists or activate an image list to use it as a filter",
    SE: "Hantera bildlistor eller aktivera en bildlista för att använda den som ett filter",
    NO: "Administrer bilde lister eller aktiver en bilde liste for å bruke den som et filter",
  }),
  ImageFilter: Translated({
    EN: "Image Filter",
    SE: "Bildfilter",
    NO: "Bilde filter",
  }),
  ImageFilterTooltip: Translated({
    EN: "Filter images based on the image ID",
    SE: "Filtrera bilder baserat på bild ID",
    NO: "Filtrer bilder basert på bilde ID",
  }),
  Distance: Translated({
    EN: "Distance",
    SE: "Avstånd",
    NO: "Avstand",
  }),
  LidarTooltip: Translated({
    EN: "Filter distance in 3D data of a project. The filter will create a heatmap of all the areas where the distance between the wire and surrounding objects is lower than the value set",
    SE: "Filtrera avstånd i 3D datan tillhörande ett projekt. Filtret skapar en heatmap över områden där avståndet mellan linan och kringliggande objekt är kortare än valt värde",
    NO: "Filtrer avstand i 3D-data for et prosjekt. Filteret vil lage et varmekart over alle områder der avstanden mellom ledningen og omkringliggende objekter er lavere enn verdien satt",
  }),
  Lidar: Translated({
    EN: "Lidar",
    SE: "Lidar",
    NO: "Lidar",
  }),
  Workflow: Translated({
    EN: "Workflow",
    SE: "Arbetsstatus",
    NO: "Arbeidsflyt",
  }),
  WorkflowTooltip: Translated({
    EN: "Filter stages of the annotation flow",
    SE: "Filtrera olika typer av stadier i annoteringsflödet",
    NO: "Filtrer stadier i annoteringsflyten",
  }),
  OriginFilter: Translated({
    EN: "Creator Filter",
    SE: "Skaparfilter",
    NO: "Skaperfilter",
  }),
  OriginFilterToolTip: Translated({
    EN: "Filter the creator of a bounding box. Sometimes called the origin of a bounding box.",
    SE: "Filtrera skaparen av en bounding box. Ibland kallat ursprunget av en bounding box",
    NO: "Filtrer skaperen av en avgrensningsboks. Noen ganger kalt opprinnelsen til en avgrensningsboks",
  }),
  ListDeleteConfirm: Translated({
    EN: "Are you sure you want to delete list",
    SE: "Är du säker att du vill ta bort listan",
    NO: "Er du sikker på at du vil slette listen",
  }),
  ListRenamePrompt: Translated({
    EN: "Enter new title for list.",
    SE: "Ange en nytt titel för lista",
    NO: "Skriv inn ny tittel for listen",
  }),
  ListFilterActive: Translated({
    EN: "Active",
    SE: "Aktiv",
    NO: "Aktiv",
  }),
  List: Translated({
    EN: "List",
    SE: "Lista",
    NO: "Liste",
  }),
  Images: Translated({
    EN: "Images",
    SE: "Bilder",
    NO: "Bilder",
  }),
  Info: Translated({
    EN: "Info",
    SE: "Info",
    NO: "Info",
  }),
  Public: Translated({
    EN: "Public",
    SE: "Publik",
    NO: "Offentlig",
  }),
  Locked: Translated({
    EN: "Locked",
    SE: "Låst",
    NO: "Låst",
  }),
  Unlocked: Translated({
    EN: "Unlocked",
    SE: "Olåst",
    NO: "Opplåst",
  }),
  Rename: Translated({
    EN: "Rename",
    SE: "Döp om",
    NO: "Endre navn",
  }),
  Delete: Translated({
    EN: "Delete",
    SE: "Radera",
    NO: "Slett",
  }),
  Share: Translated({
    EN: "Share",
    SE: "Dela",
    NO: "Dele",
  }),
  LinkCopiedToClipboard: Translated({
    EN: "Link was copied to clipboard",
    SE: "Länk kopierades till urlipp",
    NO: "Lenken ble kopiert til utklippstavlen",
  }),
  NewList: Translated({
    EN: "New list",
    SE: "Ny lista",
    NO: "Ny liste",
  }),
  ListFilterAdd: Translated({
    EN: "Add",
    SE: "Skapa",
    NO: "Legg til",
  }),
  Project: Translated({
    EN: "Project",
    SE: "Projekt",
    NO: "Prosjekt",
  }),
  JumpToImage: Translated({
    EN: "Jump to image",
    SE: "Hoppa till bild",
    NO: "Hopp til bilde",
  }),
  Cancel: Translated({
    EN: "Cancel",
    SE: "Avbryt",
    NO: "Avbryt",
  }),
  Confirm: Translated({
    EN: "Confirm",
    SE: "Bekräfta",
    NO: "Bekreft",
  }),
  SetSeverityForIssue: Translated({
    EN: "Set severity for issue",
    SE: "Sätt allvarlighetsgrad för skadetyp",
    NO: "Angi alvorlighetsgrad for defekttype",
  }),
  ApplyFilterFromPreviousSession: Translated({
    EN: "Apply filter from previous session?",
    SE: "Vill du applicera filter från tidigare session?",
    NO: "Vil du gjenopprette filteret fra forrige økt?",
  }),
};

type IKeys = keyof typeof translations;

function getTranslation(translationKey: IKeys, language: ILanguages) {
  // Return translation key if it does not exist
  // This should not happen due to typescript
  if (!(translationKey in translations)) {
    return translationKey;
  }

  // Return translation key if language does not exist
  // This can happen if the language is not supported
  if (!(language in translations[translationKey])) {
    return translationKey;
  }

  // Return translation key if translation is null
  // or undefined
  if (!translations[translationKey][language]) {
    return translationKey;
  }

  // Return a successful translation
  return translations[translationKey][language];
}

export { getTranslation };
