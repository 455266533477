import { Avatar, Button, Stack, Typography } from "@mui/material";

import { getUserInitials } from "./utils";
import { useSelector } from "hooks";
import { Logout } from "@mui/icons-material";

export default function ProfileMenuHeader() {
  const userName = useSelector((state) => state.user.name);
  const userEmail = useSelector((state) => state.user.email);
  return (
    <Stack
      data-testid="profile-menu-header"
      pt={1}
      pb={2}
      px={2}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Avatar>{getUserInitials(userName)}</Avatar>
        <Stack>
          <Typography>{userName}</Typography>
          <Typography variant="caption" color="text.secondary">
            {userEmail}
          </Typography>
        </Stack>
      </Stack>
      <Button variant="text" startIcon={<Logout />} sx={{ color: "#040404" }}>
        Logout
      </Button>
    </Stack>
  );
}
