import { useEffect } from "react";
import Sidebar from "views/Sidebar";
import { Stack } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { Outlet, useParams } from "react-router-dom";
import {
  getAllTypes,
  getClearanceSystems,
  getImageTypes,
  getLists,
  getMissionAnnotators,
} from "state/actions";
import { FilterProvider } from "providers/FilterProvider/FilterProvider";
import { useCurrentProject, useDispatch, useSelector } from "hooks";
import { LocationHistoryProvider } from "providers/LocationHistoryProvider";
import "./style.scss";
import PopupManager from "views/PopupManager";
import LaunchDarklyIdentifier from "components/LaunchDarklyIdentifier";
import ModalBlocker from "features/ModalBlocker";

export default function MainLayout(): JSX.Element {
  const params = useParams();
  const currentProject = useCurrentProject();
  const email = useSelector((state) => state.user.email);
  const dispatch = useDispatch();

  useEffect(() => {
    if (email) {
      dispatch(getAllTypes());
    }
  }, [getAllTypes, email]);

  useEffect(() => {
    if (email && currentProject) {
      dispatch(getLists(currentProject.id));
      dispatch(getImageTypes(currentProject.id));
      dispatch(getMissionAnnotators(currentProject.id));
      dispatch(getClearanceSystems(currentProject.id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject?.id, getLists, getImageTypes, email]);

  return (
    <LocationHistoryProvider>
      <FilterProvider>
        <PopupManager />
        <div id="layout">
          <Sidebar />
          <ToastContainer newestOnTop theme="colored" />
          <Stack
            id="mainView"
            position="relative"
            overflow="auto"
            flex={1}
            height={{
              xs: "calc( 100% - 50px )",
              sm: "calc( 100% - 50px )",
              md: "100%",
              lg: "100%",
            }}
          >
            <Outlet />
          </Stack>
        </div>
      </FilterProvider>
      <LaunchDarklyIdentifier />
      {/* @ts-ignore */}
      <ModalBlocker />
    </LocationHistoryProvider>
  );
}
