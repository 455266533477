import { useState, useEffect } from "react";
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
  Link,
} from "react-router-dom";
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import { useLanguage, useUser } from "hooks";
import Loading from "views/Loading";
import { Add, Clear, Delete, Edit, Save, Search } from "@mui/icons-material";
import { authorizedPut, axiosInstance } from "utils/request";
import { toast } from "react-toastify";
import translations from "translations";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { accessLevelsHidden } from "../accessLevels";
import MFA from "./MFA";
import ChangePassword from "./ChangePassword";
import { getProjectName } from "utils/utils";

export default function UserPage() {
  const { language } = useLanguage();
  const params = useParams();
  const navigate = useNavigate();
  const context = useOutletContext<{ updateUsers: () => void }>();
  // @ts-ignore
  const currentUserID = parseInt(params.user);
  // @ts-ignore
  const currentMissionID = parseInt(params.user_mission);
  // @ts-ignore
  const currentGroupID = parseInt(params.user_group);
  const [user, updateUser, loading] = useUser(currentUserID);
  const [searchValue, setSearchValue] = useState<string>("");
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>(user?.name);
  // @ts-ignore
  const [newNote, setNewNote] = useState<string>(user?.skyqraftNote);

  const [newPhoneNumber, setNewPhoneNumber] = useState<string>(
    user?.phone_number
  );
  const [isSuperuser, setSuperuser] = useState<boolean>(user?.superuser);
  const [isEmployee, setEmployee] = useState<boolean>(user?.skyqraftEmployee);
  const [isAnnotator, setAnnotator] = useState<boolean>(user?.annotator);

  useEffect(() => {
    if (!!user && !editing) {
      setNewName(user.name);
      setNewPhoneNumber(user.phone_number);
      setSuperuser(user.superuser);
      setEmployee(user.skyqraftEmployee);
      setAnnotator(user.annotator);
      // @ts-ignore
      setNewNote(user.skyqraftNote);
    }
  }, [user, editing]);

  useEffect(() => {
    setEditing(false);
  }, [currentUserID]);

  if (loading) {
    return (
      <Stack alignItems="center" justifyContent="center" height="50%">
        <Loading relative size={40} />
        <Typography>{translations.UserAdmin.LoadingUser[language]}</Typography>
      </Stack>
    );
  }

  async function deleteUser() {
    const toastHandle = toast.info(`Deleting ${user.name}`, {
      autoClose: false,
    });

    axiosInstance
      .delete(`/admin/user/${user.id}`)
      .then(() => {
        toast.update(toastHandle, {
          render: "User deleted",
          autoClose: 5000,
          type: "success",
        });
        navigate("/users");
        context.updateUsers();
      })
      .catch(() => {
        toast.update(toastHandle, {
          render: "Failed to delete the user",
          autoClose: 5000,
          type: "error",
        });
      });
  }

  function saveUserChanges() {
    setEditing(!editing);
    setUpdateLoading(true);
    authorizedPut(`/admin/user/${user.id}`, {
      annotator: isAnnotator,
      name: newName,
      phone_number: newPhoneNumber,
      skyqraftEmployee: isEmployee,
      superuser: isSuperuser,
      skyqraftNote: newNote,
    })
      .then(() => {
        setUpdateLoading(false);
        setEditing(false);
        updateUser();
        context.updateUsers();
      })
      .catch(() => {
        setUpdateLoading(false);
        toast.error(translations.UserAdmin.FailedToUpdateUser[language]);
      });
  }

  function isIn(source: string, test: string): boolean {
    const string = source.replace(/[^a-zA-Z0-9@. ]/g, "");
    return string.toLowerCase().includes(test.toLowerCase());
  }

  function filterEntity(entity: { id: number; name: string }) {
    const textMatch =
      isIn(JSON.stringify(entity.id), searchValue) ||
      isIn(entity.name, searchValue);
    return textMatch;
  }
  // @ts-ignore
  let filteredProjects = [];
  // @ts-ignore
  let filteredGroups = [];

  if (user) {
    filteredProjects = user.projects.filter(filterEntity);
    filteredGroups = user.groups.filter(filterEntity);
  }

  return (
    <RoleWrapper keyName="userAdmin">
      {!user ? (
        <Typography>{translations.UserAdmin.UserNotFound[language]}</Typography>
      ) : (
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 0, md: 2 }}
          sx={{ height: "100%" }}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Stack spacing={2} pt={2} px={{ xs: 2, md: 0 }}>
            <Link
              to={`/users/${currentUserID}/new`}
              replace
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Stack
                width="100%"
                direction="row"
                alignItems="center"
                spacing={2}
              >
                <Add />
                <Typography>
                  {translations.UserAdmin.GiveAccess[language]}
                </Typography>
              </Stack>
            </Link>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Search sx={{ my: 0.5 }} />
              <TextField
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                placeholder={
                  translations.UserAdmin.SearchForMissionOrGroup[language]
                }
                variant="standard"
                sx={{ flex: 1 }}
              />
            </Stack>
            <Stack
              maxWidth={{ xs: "100%", md: "350px" }}
              sx={{ height: "100%", overflowY: "auto", pb: 2 }}
            >
              {filteredGroups.length > 0 && (
                <>
                  <Divider
                    sx={{
                      my: 2,
                      "&::before": {
                        top: 0,
                      },
                      "&::after": {
                        top: 0,
                      },
                    }}
                  >
                    {translations.words.Customer[language]}
                  </Divider>
                  <Stack spacing={1}>
                    {/* @ts-ignore */}
                    {filteredGroups.map((groupAccess) => (
                      <Link
                        key={groupAccess.id}
                        to={`group/${groupAccess.id}`}
                        replace
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Paper
                          sx={{
                            p: 2,
                            bgcolor:
                              currentGroupID === groupAccess.id
                                ? "#D6E7FF"
                                : "white",
                          }}
                        >
                          <Typography fontWeight="bold">
                            {groupAccess.name}
                          </Typography>
                          <Typography color="color.secondary">
                            {
                              // @ts-ignore
                              accessLevelsHidden[groupAccess.accessLevel][
                                language
                              ]
                            }
                          </Typography>
                        </Paper>
                      </Link>
                    ))}
                  </Stack>
                </>
              )}
              {filteredProjects.length > 0 && (
                <>
                  <Divider
                    sx={{
                      my: 2,
                      "&::before": {
                        top: 0,
                      },
                      "&::after": {
                        top: 0,
                      },
                    }}
                  >
                    {translations.words.Mission[language]}
                  </Divider>
                  <Stack spacing={1}>
                    {/* @ts-ignore */}
                    {filteredProjects.map((missionAccess) => {
                      const projectName = getProjectName(missionAccess.id);
                      return (
                        <Link
                          key={missionAccess.id}
                          to={`mission/${missionAccess.id}`}
                          replace
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Paper
                            sx={{
                              p: 2,
                              bgcolor:
                                currentMissionID === missionAccess.id
                                  ? "#D6E7FF"
                                  : "white",
                            }}
                          >
                            <Typography fontWeight="bold">
                              {projectName?.fullname}
                            </Typography>
                            <Typography color="color.secondary">
                              {
                                // @ts-ignore
                                accessLevelsHidden[missionAccess.accessLevel][
                                  language
                                ]
                              }
                            </Typography>
                          </Paper>
                        </Link>
                      );
                    })}
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>
          <Stack
            flex={1}
            spacing={1}
            pt={{ xs: 2, md: 8 }}
            px={{ xs: 2, md: 0 }}
          >
            <Paper sx={{ p: 2, mt: 2 }}>
              <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between"
                mb={1}
              >
                <Stack>
                  {editing ? (
                    <TextField
                      label={translations.words.Name[language]}
                      variant="standard"
                      value={newName ?? ""}
                      onChange={(e) => setNewName(e.target.value)}
                      sx={{ mb: 1, width: 400 }}
                    />
                  ) : (
                    <Typography component="h2" variant="h5">
                      {user.name}
                    </Typography>
                  )}
                  {editing ? (
                    <Tooltip
                      title={translations.UserAdmin.UnableToEditEmail[language]}
                      arrow
                      disableInteractive
                      placement="right"
                    >
                      <TextField
                        label={translations.words.Email[language]}
                        variant="standard"
                        value={user.email ?? ""}
                        disabled
                        sx={{ mb: 1, width: 400 }}
                      />
                    </Tooltip>
                  ) : (
                    <Typography>{user.email}</Typography>
                  )}

                  {editing ? (
                    <TextField
                      label={translations.words.PhoneNumber[language]}
                      variant="standard"
                      value={newPhoneNumber ?? ""}
                      onChange={(e) => setNewPhoneNumber(e.target.value)}
                      sx={{ width: 400 }}
                    />
                  ) : (
                    user.phone_number && (
                      <Typography>{user.phone_number}</Typography>
                    )
                  )}

                  {!editing && (
                    <Stack direction="row" spacing={1} mt={1}>
                      {user.superuser && <Chip label="Superuser" />}
                      {user.skyqraftEmployee && (
                        <Chip label="Arkion Employee" />
                      )}
                      {user.annotator && <Chip label="Annotator" />}
                    </Stack>
                  )}

                  <RoleWrapper keyName="userAdminSkyqraft">
                    {editing && (
                      <Stack direction="row" spacing={1} mt={1}>
                        {(user.superuser || editing) && (
                          <Chip
                            icon={
                              isSuperuser ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            }
                            label="Superuser"
                            onClick={() => {
                              if (editing) {
                                setSuperuser(!isSuperuser);
                              }
                            }}
                          />
                        )}
                        {(user.skyqraftEmployee || editing) && (
                          <Chip
                            icon={
                              isEmployee ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            }
                            label="Arkion Employee"
                            onClick={() => {
                              if (editing) {
                                setEmployee(!isEmployee);
                              }
                            }}
                          />
                        )}
                        {(user.annotator || editing) && (
                          <Chip
                            icon={
                              isAnnotator ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            }
                            label="Annotator"
                            onClick={() => {
                              if (editing) {
                                setAnnotator(!isAnnotator);
                              }
                            }}
                          />
                        )}
                      </Stack>
                    )}
                  </RoleWrapper>
                </Stack>
                <Stack>
                  {editing ? (
                    <>
                      <Tooltip
                        title={translations.UserAdmin.LeaveEditMode[language]}
                        placement="left"
                        arrow
                        disableInteractive
                      >
                        <IconButton
                          disabled={updateLoading}
                          color="error"
                          onClick={() => setEditing(!editing)}
                        >
                          <Clear />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={translations.UserAdmin.SaveChanges[language]}
                        placement="left"
                        arrow
                        disableInteractive
                      >
                        <Box>
                          <IconButton
                            onClick={saveUserChanges}
                            disabled={
                              (user.name === newName &&
                                user.phone_number === newPhoneNumber &&
                                user.superuser === isSuperuser &&
                                user.skyqraftEmployee === isEmployee &&
                                user.skyqraftNote === newNote &&
                                user.annotator === isAnnotator) ||
                              updateLoading
                            }
                            color="success"
                          >
                            <Save />
                          </IconButton>
                        </Box>
                      </Tooltip>
                      {updateLoading && (
                        <Box mt={1}>
                          <Loading relative size={20} />
                        </Box>
                      )}
                    </>
                  ) : (
                    <>
                      <Tooltip
                        title={
                          translations.UserAdmin.UpdateUserProperties[language]
                        }
                        placement="left"
                        arrow
                        disableInteractive
                      >
                        <IconButton onClick={() => setEditing(!editing)}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip
                    title="Delete this user"
                    placement="left"
                    arrow
                    disableInteractive
                  >
                    <IconButton
                      onClick={() =>
                        window.confirm(
                          "Are you sure you want to delete this user?"
                        ) && deleteUser()
                      }
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
              <Stack spacing={1}>
                <MFA user={user} editing={editing} updateUser={updateUser} />
                <ChangePassword mfaActive={user.mfa_enabled} />
              </Stack>
            </Paper>
            <Outlet context={{ ...context, updateUser, user }} />
          </Stack>
        </Stack>
      )}
    </RoleWrapper>
  );
}
