import translations from "translations";
import { Select, MenuItem } from "@mui/material";
import { setImageSortingMethod } from "state/actions";
import { useLanguage, useSelector, useDispatch } from "hooks";
import SettingsContainer from "../SettingsContainer";

interface IProps {
  size: number;
}

const options = [
  { value: "compass", label: "Compass" },
  { value: "timestamp", label: "Timestamp" },
] as const;

const allowedValues = options.map((o) => o.value);

function ThumbnailSorting({ size }: IProps) {
  const imageSortingMethod = useSelector(
    (state) => state.imageViewer.imageSortingMethod
  );
  const dispatch = useDispatch();
  const { language } = useLanguage();

  function onChange(newValue: (typeof allowedValues)[number]) {
    const isValid = allowedValues.includes(newValue);
    if (isValid) {
      dispatch(setImageSortingMethod(newValue));
    }
  }

  return (
    <SettingsContainer
      size={size}
      title={translations.Menu.Settings.ThumbnailSorting[language]}
    >
      <Select
        sx={{ ml: 2 }}
        fullWidth={true}
        size="small"
        id="thumbnail-method-selector"
        data-testid="settings.thumbnailSorting"
        value={imageSortingMethod}
        onChange={(e) =>
          onChange(e.target.value as (typeof allowedValues)[number])
        }
      >
        {options.map((option, i) => (
          <MenuItem
            key={i.toString()}
            value={option.value}
            data-testid={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </SettingsContainer>
  );
}
export default ThumbnailSorting;
