import { useLocation, useOutlet, useSearchParams } from "react-router-dom";
import { Paper, Stack, Typography } from "@mui/material";
import MissionBreadcrumbs from "components/MissionBreadcrumbs";
import StatusBar from "components/StatusBar";
import { useCurrentProject, useSelector } from "hooks";
import ListView from "./ListView";
import WorkSession from "components/StatusBar/WorkSession";
import WorkSessionProviderWrapper from "components/StatusBar/WorkSession/WorkSessionProviderWrapper";
import Loading from "views/Loading";
import { PoleViewer } from "views/PoleViewer";
import MapView from "./MapView/index";
import { Panels, Panel, MapViewWrapper } from "./StyledComponents";
import useAutoSelectAnyImage from "./hooks/useAutoSelectAnyImage";

export default function MapPanels() {
  const location = useLocation();
  const currentProject = useCurrentProject();
  const projects = useSelector((state) => state.user.missions);
  const showListView = useSelector((state) => state.ui.showListView);
  const showMap = useSelector((state) => state.ui.showMap);

  const [searchParams] = useSearchParams();
  const poleOpen = searchParams.has("pole");

  const outletContent = useOutlet();

  useAutoSelectAnyImage();

  if (projects.length === 0) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        height="100%"
        data-testid="loading-project-dialog"
      >
        <Paper sx={{ p: 4 }}>
          <Typography sx={{ mb: 2 }}>Loading project...</Typography>
          <Loading relative={true} size={40} color="black" />
        </Paper>
      </Stack>
    );
  }

  if (!currentProject && location.pathname !== "/order") {
    return (
      <Stack alignItems="center" justifyContent="center" height="100%">
        <Paper sx={{ p: 4 }}>
          <Typography>Project not found</Typography>
        </Paper>
      </Stack>
    );
  }

  return (
    <WorkSessionProviderWrapper>
      <StatusBar
        title={<MissionBreadcrumbs />}
        sx={{
          zIndex: 150,
          position: "relative",
        }}
      />
      <Panels>
        {outletContent && <Panel grow={!showMap}>{outletContent}</Panel>}
        {showMap && (
          <MapViewWrapper>
            {/* @ts-ignore */}
            <MapView />
          </MapViewWrapper>
        )}

        {poleOpen && (
          <Panel>
            <PoleViewer />
          </Panel>
        )}
        {showListView && (
          <Panel>
            <ListView />
          </Panel>
        )}
      </Panels>
      <WorkSession />
    </WorkSessionProviderWrapper>
  );
}
