import { useContext, useState, useEffect } from "react";
import { Tooltip, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { AnnotationContext } from "../../../provider";
import translations from "translations";
import { authorizedGet } from "utils/request";
import { useLanguage } from "hooks";
import { useUserCanEditDetections } from "views/AnnotationTool/hooks/useUserCanEditDetections";
import { RoleWrapper } from "components";

type Props = {
  // @ts-ignore
  annotation;
  // @ts-ignore
  index;
};

interface ObjectHistory {
  created?: boolean;
  origin: string;
  timestamp: string;
  human: boolean;
  changes?: {
    x?: {
      old: number;
      new: number;
    };
    y?: {
      old: number;
      new: number;
    };
    width?: {
      old: number;
      new: number;
    };
    height?: {
      old: number;
      new: number;
    };
    comment?: {
      old: string;
      new: string;
    };
  };
}

export default function TypeConverterButton({ annotation, index }: Props) {
  const { language } = useLanguage();
  const { updateAnnotation, deleteAnnotation } = useContext(AnnotationContext);
  const canEditDetections = useUserCanEditDetections();

  const [objectHistory, setObjectHistory] = useState<ObjectHistory[]>([]);

  useEffect(() => {
    if (!annotation.id.includes("n")) {
      authorizedGet<{ object: ObjectHistory[] }>(
        `/object/${annotation.id}/history`
      )
        .then((response) => {
          setObjectHistory(response.object);
        })
        .catch(() => {
          console.error("Fetching history failed");
          setObjectHistory([]);
        });
    }
  }, [annotation.id]);
  // @ts-ignore
  const deleteItem = (index) => {
    if (annotation.types.length === 1) {
      deleteAnnotation(annotation.id);
    } else {
      updateAnnotation(annotation.id, {
        ...annotation,
        // @ts-ignore
        types: annotation.types.filter((value, i) => i !== index),
        // @ts-ignore
        severities: annotation.severities.filter((value, i) => i !== index),
        // @ts-ignore
        is_defect: annotation.is_defect.filter((value, i) => i !== index),
        // @ts-ignore
        categories: annotation.categories.filter((value, i) => i !== index),
        // @ts-ignore
        processed: annotation.processed.filter((value, i) => i !== index),
        // @ts-ignore
        type_id: annotation.type_id.filter((value, i) => i !== index),
        workflow_status: annotation.workflow_status.filter(
          // @ts-ignore
          (value, i) => i !== index
        ),
        skyqraft_hidden: annotation.skyqraft_hidden.filter(
          // @ts-ignore
          (value, i) => i !== index
        ),
        // @ts-ignore
        type_comment: annotation.type_comment.filter((value, i) => i !== index),
        editType: undefined,
        editIndex: undefined,
      });
    }
  };

  function hideItem(index: number) {
    updateAnnotation(annotation.id, {
      ...annotation,
      skyqraft_hidden: annotation.skyqraft_hidden.map(
        (hid: boolean, i: number) => (i === index ? !hid : hid)
      ),
    });
  }
  const creatorIsMachine = annotation.creator_is_ai[index];

  return (
    <>
      {(canEditDetections || annotation.is_defect[index]) && (
        <Tooltip
          disableInteractive
          title={translations.AnnotationTool.deleteClass[language]}
          placement="top"
        >
          <RoleWrapper
            keyName="internalAnnotationTools"
            fallback={
              <IconButton
                onClick={() => hideItem(index)}
                style={{
                  padding: 0,
                  marginTop: 0,
                  height: "24px",
                  width: "24px",
                }}
              >
                <Delete
                  htmlColor={creatorIsMachine ? "" : "black"}
                  sx={{ width: "20px" }}
                />
              </IconButton>
            }
          >
            <IconButton
              disabled={creatorIsMachine}
              onClick={() => deleteItem(index)}
              style={{
                padding: 0,
                marginTop: 0,
                height: "24px",
                width: "24px",
              }}
            >
              <Delete
                htmlColor={creatorIsMachine ? "" : "black"}
                sx={{ width: "20px" }}
              />
            </IconButton>
          </RoleWrapper>
        </Tooltip>
      )}
    </>
  );
}
