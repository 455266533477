import { Stack, Typography } from "@mui/material";
import LanguageSelector from "components/Settings/LanguageSelector";

export default function Language() {
  const nameSize = 3;
  return (
    <Stack spacing={1}>
      <Typography pl={1} fontWeight="bold">
        Language
      </Typography>
      <Typography pl={1} variant="body2">
        Change your language settings to customize the language of the app.
      </Typography>
      <LanguageSelector size={nameSize} />
    </Stack>
  );
}
