import { Grid, Typography } from "@mui/material";
import React from "react";

type Props = {
  title: string;
  children: React.ReactNode;
  size: number;
};

export default function SettingsContainer({ title, children, size }: Props) {
  return (
    <Grid container px={2}>
      <Grid item xs={size} sx={{ display: "flex", alignItems: "center" }}>
        <Typography>{title}</Typography>
      </Grid>
      <Grid
        item
        xs={12 - size}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
}
