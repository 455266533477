import { Switch } from "@mui/material";

import translations from "translations";
import { useLanguage, useSelector, useDispatch } from "hooks";
import { setClusterVisible } from "state/actions";
import SettingsContainer from "../SettingsContainer";

interface IProps {
  size: number;
}

function ClusterSwitch({ size }: IProps) {
  const clusterVisible = useSelector((state) => state.ui.clusterVisible);
  const dispatch = useDispatch();
  const { language } = useLanguage();

  function onChange() {
    dispatch(setClusterVisible(!clusterVisible));
  }
  return (
    <SettingsContainer
      size={size}
      title={translations.Menu.Settings.Cluster[language]}
    >
      <Switch color="primary" checked={clusterVisible} onChange={onChange} />
    </SettingsContainer>
  );
}
export default ClusterSwitch;
