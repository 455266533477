import { useEffect } from "react";
import { captureException, EventHint } from "@sentry/react";
import { useDispatch, useSelector } from "hooks";
import { setLoadedKey } from "state/reducers/localStorageReducer";

interface IProps<T> {
  data: T;
  onLoad: (data: T) => void;
  key: string;
}
export function useLocalStorage<T>(props: IProps<T>) {
  const { key, data, onLoad } = props;

  const loadedKeys = useSelector((state) => state.localStorage.loadedKeys);
  const dispatch = useDispatch();

  const loaded = loadedKeys.includes(key);

  useEffect(() => {
    if (!loaded) {
      return;
    }
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      console.error(error);
      const exceptionHint: EventHint = {
        event_id: "useLocalStorage.setItem",
        originalException: error,
        data: {
          key,
        },
      };
      captureException(error, exceptionHint);
    }
  }, [data, loaded]);

  useEffect(() => {
    if (loaded) {
      return;
    }
    try {
      const json = localStorage.getItem(key);

      if (json) {
        onLoad(JSON.parse(json));
      }
    } catch (error) {
      console.error(error);
      const exceptionHint: EventHint = {
        event_id: "useLocalStorage.getItem",
        originalException: error,
        data: {
          key,
        },
      };
      captureException(error, exceptionHint);
    }
    dispatch(setLoadedKey(key));
  }, [loaded]);
}
