import { useRef } from "react";
import { useGesture } from "@use-gesture/react";
import { IImageAnnotation } from "views/AnnotationTool/api";
import { MutableRefObject, useContext } from "react";
import { compose, scale, translate } from "transformation-matrix";
import {
  IDragEvent,
  IPinchState,
  IWheelState,
} from "views/AnnotationTool/hooks/types";
import { getCanvasBox, isPointInBox } from "views/AnnotationTool/hooks/utils";
import { CanvasContext } from "views/AnnotationTool/provider";
import { IContextValue } from "views/AnnotationTool/provider/CanvasProvider";
import { convertPositionToPercentage } from "views/AnnotationTool/utils";
import { reviewModes } from "constants/imageReview";
import { workflow_status } from "AppConstants";
import { useBboxIdsOnly } from "hooks";

const DRAG_THRESHOLD = 5; // pixels

interface IProps {
  canvasRef: MutableRefObject<HTMLCanvasElement | null>;
  annotations?: IImageAnnotation[];
  setLockedRegion?: (region: string | null) => void;
  setHoveredRegion?: (region: string | null) => void;
  reviewMode: (typeof reviewModes)[keyof typeof reviewModes];
  // @ts-ignore
  speedZoom?;
  toggleBox?: (ids: string[], listToggle?: boolean) => void;
  lockedRegion?: string;
}

function findAnnotationAtMousePosition(
  canvas: IContextValue,
  annotations: IImageAnnotation[],
  clientX: number,
  clientY: number,
  canvasTop: number,
  canvasLeft: number
) {
  if (!annotations.length) {
    return;
  }
  // Define it as a percentage of the canvas with respect to scaling matrix
  const { x, y } = convertPositionToPercentage(
    canvasTop,
    canvasLeft, // @ts-ignore
    canvas.imageDimensions,
    clientX,
    clientY,
    canvas.matrix
  );
  const visibleWorkflowStatuses = [
    workflow_status.REVIEW_REQUESTED,
    workflow_status.TRUE_POSITIVE,
    workflow_status.SUPERVISOR_APPROVED,
  ];
  const visibleAnnotations = annotations.filter((annotation) =>
    // @ts-ignore
    annotation.workflow_status.some((s) => visibleWorkflowStatuses.includes(s))
  );

  const annotationsWithinPosition = visibleAnnotations.filter((box) => {
    const rotation = box.rotation;
    return isPointInBox(
      // @ts-ignore
      box.x * canvas.imageDimensions.naturalWidth,
      // @ts-ignore
      box.y * canvas.imageDimensions.naturalHeight,
      // @ts-ignore
      box.w * canvas.imageDimensions.naturalWidth,
      // @ts-ignore
      box.h * canvas.imageDimensions.naturalHeight,
      rotation,
      // @ts-ignore
      x * canvas.imageDimensions.naturalWidth,
      // @ts-ignore
      y * canvas.imageDimensions.naturalHeight
    );
  });

  if (annotationsWithinPosition.length === 0) {
    return null;
  }
  const severityAnnotations = annotationsWithinPosition.filter((annotation) =>
    annotation.severities?.some((severity) => severity)
  );
  const filteredAnnotations = severityAnnotations.length
    ? severityAnnotations
    : annotationsWithinPosition;

  const smallestAnnotation = filteredAnnotations.reduce((prev, curr) => {
    return prev.w * prev.h < curr.w * curr.h ? prev : curr;
  }, filteredAnnotations[0]);

  return smallestAnnotation;
}

export function useInputListener({
  canvasRef,
  annotations = [],
  setLockedRegion = () => {},
  setHoveredRegion = () => {},
  reviewMode,
  speedZoom = () => {},
  toggleBox = () => {},
  lockedRegion,
}: IProps) {
  // Collect variables from context
  const canvas = useContext(CanvasContext);
  const { boxIdsOnlyActive } = useBboxIdsOnly();
  const initialMousePos = useRef([0, 0]);

  // Manage drags across the canvas
  function onDragStart(state: IDragEvent) {
    initialMousePos.current = state.values;
  }

  // Manage drags across the canvas
  function onDrag(state: IDragEvent) {
    const dx = state.values[0] - initialMousePos.current[0];
    const dy = state.values[1] - initialMousePos.current[1];
    const distance = Math.sqrt(dx * dx + dy * dy);

    if (distance > DRAG_THRESHOLD) {
      canvas.setDragged(true);

      const matrixTranslation = translate(-state.delta[0], -state.delta[1]);
      const newMat = compose(canvas.matrix, matrixTranslation);
      canvas.setMatrix(newMat);
    } else {
      canvas.setDragged(false);
    }
  }

  // Manage click events (often clicks are used to deselect annotations)
  // @ts-ignore
  function onClick(state) {
    const { left, top } = getCanvasBox(canvasRef);
    const { clientX, clientY, shiftKey, ctrlKey } = state.event;

    const annotation = findAnnotationAtMousePosition(
      canvas,
      annotations,
      clientX,
      clientY,
      top,
      left
    );
    if (annotation && !canvas.dragged) {
      if (shiftKey || ctrlKey) {
        speedZoom(
          annotation.x,
          annotation.y,
          annotation.w,
          annotation.h,
          annotation.id
        );
      } else {
        if (boxIdsOnlyActive) {
          return;
        }
        if (lockedRegion === annotation.id) {
          toggleBox([annotation.id]);
          setLockedRegion(null);
        } else {
          toggleBox([annotation.id]);
          setLockedRegion(annotation.id);
        }
      }
    } else {
      if (shiftKey || ctrlKey) {
        const { x, y } = convertPositionToPercentage(
          top,
          left, // @ts-ignore
          canvas.imageDimensions,
          clientX,
          clientY,
          canvas.matrix
        );

        speedZoom(x - 0.025, y - 0.025, 0.05, 0.05, "image");
      } else if (!canvas.dragged) {
        if (boxIdsOnlyActive) {
          return;
        }
        toggleBox([]);
      }
    }
  }
  // @ts-ignore
  function onMouseMove(state) {
    const { left, top } = getCanvasBox(canvasRef);
    const { clientX, clientY } = state.event;

    const foundAnnotation = findAnnotationAtMousePosition(
      canvas,
      annotations,
      clientX,
      clientY,
      top,
      left
    );

    if (foundAnnotation) {
      //check if the annotation contains seveirty 1,2,3,4
      if (
        foundAnnotation?.severities.includes(1) ||
        foundAnnotation?.severities.includes(2) ||
        foundAnnotation?.severities.includes(3) ||
        foundAnnotation?.severities.includes(4)
      ) {
        setHoveredRegion(foundAnnotation?.id);
      } else if (reviewMode !== reviewModes.NONE) {
        setHoveredRegion(foundAnnotation?.id);
      } else {
        setHoveredRegion(null);
      }
    } else {
      setHoveredRegion(null);
    }
  }
  // Manage zooming in and out
  function onWheel(state: IWheelState) {
    if (!canvasRef.current) return;
    const { left, top } = getCanvasBox(canvasRef);

    // get mouse position
    const currentMouseX = state.event.clientX - left;
    const currentMouseY = state.event.clientY - top;

    // Define scale value based on Y scroll
    let scaleValue = 1 + (canvas.zoomSpeed / 100) * state.direction[1];

    //check the current scale and limit it to 0.1 - 10
    const currentScale = canvas.matrix.a;
    if (currentScale * scaleValue < 0.01) scaleValue = 0.01 / currentScale;
    if (currentScale * scaleValue > 3) scaleValue = 3 / currentScale;

    // Translate, zoom and then translate back
    const matrixManipulations = [
      translate(currentMouseX, currentMouseY),
      scale(scaleValue),
      translate(-currentMouseX, -currentMouseY),
    ];

    // Save the new matrix
    const newMatrix = compose(canvas.matrix, ...matrixManipulations);
    canvas.setMatrix(newMatrix);
  }

  function onPinch(state: IPinchState) {
    if (!canvasRef.current) return;
    // Extract some mouse values
    const { left, top } = getCanvasBox(canvasRef);
    const currentMouseX = state.origin[0] - left;
    const currentMouseY = state.origin[1] - top;

    // Figure out how much to rescale
    let scaleValue = 1 + (canvas.zoomSpeed / 100) * Math.sign(-state.delta[0]);

    //check the current scale and limit it to 0.1 - 10
    const currentScale = canvas.matrix.a;
    if (currentScale * scaleValue < 0.01) scaleValue = 0.01 / currentScale;
    if (currentScale * scaleValue > 3) scaleValue = 3 / currentScale;

    // Translate, zoom and then translate back
    const matrixManipulations = [
      translate(currentMouseX, currentMouseY),
      scale(scaleValue),
      translate(-currentMouseX, -currentMouseY),
    ];
    const newMatrix = compose(canvas.matrix, ...matrixManipulations);
    canvas.setMatrix(newMatrix);
  }

  useGesture(
    {
      onDragStart,
      onDrag,
      onClick,
      onMouseMove,
    },
    {
      target: canvasRef,
      enabled:
        canvas.mode !== "addDefect" &&
        canvas.mode !== "addDetection" &&
        canvas.mode !== "addSteelwork",
    }
  );
  useGesture(
    {
      onWheel,
      onPinch,
    },
    {
      target: canvasRef,
      enabled: true,
    }
  );
}
