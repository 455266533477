export default {
  MapToggleEnable: {
    EN: "Enable map",
    NO: "Aktiver kart",
    SE: "Aktivera karta",
  },
  MapToggleDisable: {
    EN: "Disable map",
    NO: "Deaktiver kart",
    SE: "Inaktivera karta",
  },
  ListToggleEnable: {
    EN: "Enable list",
    NO: "Aktiver liste",
    SE: "Aktivera lista",
  },
  ListToggleDisable: {
    EN: "Disable list",
    NO: "Deaktiver liste",
    SE: "Inaktivera lista",
  },
  ListView: {
    ActivateFilter: {
      EN: "Select a filter to activate list view toggle",
      NO: "Velg et filter for å aktivere listevisning",
      SE: "Välj ett filter för att aktivera listvy knappen",
    },
  },
};
