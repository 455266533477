import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getFilterActive } from "hooks/filter/useFilterPlay";
import { useCurrentProject, useSelector } from "hooks";
import { ListViewMarker } from "interfaces";
import { checkAccess } from "components/RoleHOC/wrapper";
import { formatDataGroup, getPrevAreaProject } from "./utils";
import { listModes, IDataGroup } from "./constants";
import { useFetchMarkers, useReviewedImages } from "./hooks";
import ListView from "./ListView";

export default function ListViewContainer() {
  const [data, setData] = useState<ListViewMarker[]>([]);
  const [prevData, setPrevData] = useState<ListViewMarker[]>([]);
  const [dataGroup, setDataGroup] = useState<IDataGroup>({});
  const [dataKeys, setDataKeys] = useState<number[]>([]);
  const [imageCount, setImageCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [prevLoading, setPrevLoading] = useState(false);
  const [listMode, setListMode] = useState(listModes.IMAGES);

  const currentProject = useCurrentProject();
  const projects = useSelector((state) => state.user.missions);
  const prevProject = getPrevAreaProject({ projects, currentProject });

  const user = useSelector((state) => state.user);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const filterActive = getFilterActive(searchParams);

  const prevYearAccess = checkAccess({
    user,
    customerId: currentProject?.id ?? -1,
    keyName: "reviewPrevYear",
  });

  const { updateData } = useFetchMarkers({
    projectId: currentProject?.id,
    setData,
    setLoading,
    loading,
    access: true,
    filterActive,
  });
  const { updateData: updatePrevData } = useFetchMarkers({
    projectId: prevProject?.id,
    setData: setPrevData,
    setLoading: setPrevLoading,
    loading: prevLoading,
    access: prevYearAccess,
    filterActive,
  });
  const {
    loadingReviewedImages,
    updateReviewedImages,
    reviewedImagesGroup,
    addReviewedImage,
    removeReviewedImage,
  } = useReviewedImages({
    projectId: prevProject?.id,
    access: prevYearAccess,
    filterActive,
  });

  useEffect(() => {
    setDataGroup([]);
    setDataKeys([]);

    if (!data?.length) {
      return;
    }
    const dataGroup = formatDataGroup(listMode, data, prevData);
    const dataKeys = Object.keys(dataGroup).map((key) => parseInt(key));
    const imageCount = Object.keys(dataGroup).reduce((acc, key) => {
      const groupImageCount = dataGroup[parseInt(key)]?.imageIds?.length;

      if (groupImageCount) {
        return acc + groupImageCount;
      }
      return acc;
    }, 0);

    setDataGroup(dataGroup);
    setDataKeys(dataKeys);
    setImageCount(imageCount);
  }, [listMode, data, prevData]);

  const onRefresh = async () => {
    await Promise.all([updateData(), updatePrevData(), updateReviewedImages()]);
  };

  const prevYearLoading = prevLoading || loadingReviewedImages;
  const loadingCurrentView =
    (prevYearLoading && listMode === listModes.PREV_YEAR) || loading;

  return (
    <ListView
      dataGroup={loadingCurrentView ? {} : dataGroup}
      dataKeys={loadingCurrentView ? [] : dataKeys}
      imageCount={imageCount}
      prevProject={prevProject}
      prevYearAccess={prevYearAccess}
      loading={loadingCurrentView}
      listMode={listMode}
      filterActive={filterActive}
      setListMode={setListMode}
      onRefresh={onRefresh}
      reviewedImagesGroup={reviewedImagesGroup}
      addReviewedImage={addReviewedImage}
      removeReviewedImage={removeReviewedImage}
    />
  );
}
