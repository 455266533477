import { useState } from "react";
import { Typography, Stack, Divider } from "@mui/material";
import translations from "translations";
import { useCurrentProject, useLanguage } from "hooks";

import { IKeyPayload } from "components/CeleryKmlUploader/MetaSelector";
import CeleryKMLUploader from "components/CeleryKmlUploader";

import { onCelerySave } from "./utils";

const initialKeys: IKeyPayload = {
  poleClientID: null,
  poleNote: null,
  powerlineClientID: null,
  powerlineFeedBay: null,
  powerlineTopologyColor: null,
  powerlineFeedStation: null,
};

export default function PowerlineUpload() {
  const { language } = useLanguage();
  const currentProject = useCurrentProject();
  const [keys, setKeys] = useState<IKeyPayload>(initialKeys);

  return (
    <Stack spacing={2}>
      <CeleryKMLUploader
        // @ts-ignore
        projectID={currentProject?.id ?? null}
        // @ts-ignore
        onSave={(geoJson) => onCelerySave(geoJson, currentProject.id)}
        keys={keys}
        setKeys={setKeys}
      />

      <Divider sx={{ pt: 4 }} />
      <Typography sx={{ color: "text.secondary" }}>
        {
          translations.MissionLanding.OrderProgress.OrderMission.PowerlineUpload
            .Footnote[language]
        }
      </Typography>
    </Stack>
  );
}
