import { useSelector } from "hooks";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useImageHistory } from "./hooks";
import { zoomToImage, haversine } from "./utils";

export default function AutomaticMapMove() {
  // Extract image history
  const imageHistory = useImageHistory();
  const [shouldZoomIn, setShouldZoomIn] = useState(false);

  // Extract information from redux
  const map = useSelector((state) => state.map.gmap);
  const maxImageDistance = useSelector(
    (state) => state.user.maxImageDistanceToMoveMap
  );
  const imageViewerWidth = useSelector((state) => state.ui.imageViewerWidth);

  // Extract search params
  const [searchParams] = useSearchParams();
  const poleViewerWidth = searchParams.has("pole") ? 500 : 0;

  // https://app.launchdarkly.com/projects/default/flags/map-panels-refactor/targeting?env=test&env=production&selected-env=test
  const { mapPanelsRefactor } = useFlags();

  // React on changes in the history of images
  useEffect(() => {
    // Do nothing if map is not loaded
    if (!map) return;
    // Do nothing if history is empty
    if (imageHistory.length === 0) {
      setShouldZoomIn(true);
      return;
    }
    // Zoom to first image if there is only one image
    if (imageHistory.length === 1) {
      const image = imageHistory[0];
      zoomToImage(
        map,
        image.lat,
        image.lng,
        imageViewerWidth,
        poleViewerWidth,
        mapPanelsRefactor,
        shouldZoomIn
      );
      setShouldZoomIn(false);
      return;
    }
    // If there are two images or more, extract them
    const [currentImage, previousImage] = imageHistory;

    // Count the distance between the two images
    const distance = haversine(
      currentImage.lat,
      currentImage.lng,
      previousImage.lat,
      previousImage.lng
    );
    if (distance > maxImageDistance) {
      // If the distance is more than X meters, zoom to the current image
      zoomToImage(
        map,
        currentImage.lat,
        currentImage.lng,
        imageViewerWidth,
        poleViewerWidth,
        mapPanelsRefactor,
        shouldZoomIn
      );
      return;
    }

    // If the new image is not in-bounds, zoom to it
    const bounds = map.getBounds();
    if (
      bounds &&
      !bounds.contains({ lat: currentImage.lat, lng: currentImage.lng })
    ) {
      zoomToImage(
        map,
        currentImage.lat,
        currentImage.lng,
        imageViewerWidth,
        poleViewerWidth,
        mapPanelsRefactor,
        shouldZoomIn
      );
      return;
    }
  }, [imageHistory, map, mapPanelsRefactor]);

  // We return nothing because this component is only used for its side effects
  return <></>;
}
