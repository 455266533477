import { ReactNode, createContext, useState, useEffect } from "react";
import {
  getPoleComponentCategories,
  IPoleComponentCategory,
  addPoleComponentCategory,
  deletePoleComponentCategory,
  updatePoleComponentCategory,
} from "views/PoleViewer/api";
import { Outlet } from "react-router-dom";
import { RootState } from "state/reducers";

interface IContextValue {
  componentCategories: IPoleComponentCategory[];
  loading: boolean;
  updateCategory: (
    categoryID: number,
    name?: string | null,
    sorting?: number | null
  ) => Promise<void>;
  deleteCategory: (categoryID: number) => Promise<void>;
  createCategory: (params: {
    name: string;
    sorting?: number | null;
  }) => Promise<number>;
  group: RootState["group"]["groups"][number] | null;
  setGroup: (group: RootState["group"]["groups"][number] | null) => void;
}

// Set up a context that is importable
const context = createContext<IContextValue>({
  componentCategories: [],
  loading: false,
  updateCategory: async () => {},
  deleteCategory: async () => {},
  createCategory: async () => 0,
  group: null,
  setGroup: () => null,
});

interface IProps {
  children?: ReactNode;
}

function CategoryOptionsProvider({ children }: IProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [componentCategories, setComponentCategories] = useState<
    IPoleComponentCategory[]
  >([]);
  const [group, setGroup] = useState<
    RootState["group"]["groups"][number] | null
  >(null);

  async function updateComponentCategories(groupID: number | null) {
    setLoading(true);
    const categories = await getPoleComponentCategories(groupID);
    const sortedCategories = categories.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setComponentCategories(sortedCategories);
    setLoading(false);
  }

  async function updateCategory(
    categoryID: number,
    name?: string | null,
    sorting?: number | null
  ) {
    await updatePoleComponentCategory(categoryID, name, sorting);
    await updateComponentCategories(group?.id ?? null);
  }

  async function deleteCategory(categoryID: number) {
    await deletePoleComponentCategory(categoryID);
    await updateComponentCategories(group?.id ?? null);
  }

  async function createCategory({
    name,
    sorting = null,
  }: {
    name: string;
    sorting?: number | null;
  }) {
    const categoryID = await addPoleComponentCategory(name, sorting, group?.id);
    await updateComponentCategories(group?.id ?? null);
    return categoryID;
  }

  useEffect(() => {
    updateComponentCategories(group?.id ?? null);
  }, [group]);

  // Build the payload to all consumers
  const payload: IContextValue = {
    componentCategories,
    loading,
    deleteCategory,
    updateCategory,
    createCategory,
    group,
    setGroup,
  };

  // Render the context provider
  return (
    <context.Provider value={payload}>
      {children ? children : <Outlet />}
    </context.Provider>
  );
}

export default CategoryOptionsProvider;
export { context };
export type { IContextValue };
