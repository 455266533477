import { useContext } from "react";
import { useGesture } from "@use-gesture/react";
import { MutableRefObject } from "react";
import { AnnotationContext, CanvasContext } from "../provider";
import { convertChangeToPercentage } from "../utils";
import { IWheelState, IPinchState } from "./types";
import { getCanvasBox } from "./utils";
import { compose, scale, translate } from "transformation-matrix";

import { ISpecialAnnotation } from "../provider/AnnotationProvider/context";

interface IProps {
  boxRef: MutableRefObject<HTMLDivElement | null>;
  currentBox: ISpecialAnnotation;
  canvasWidth: number;
  // @ts-ignore
  canvasRef;
  rotation: number;
}

export function useBoxListener({
  boxRef,
  canvasRef,
  currentBox,
  canvasWidth,
  rotation,
}: IProps) {
  const { updateAnnotation, setSelectedAnnotation, isSelectedAnnotation } =
    useContext(AnnotationContext);
  const { matrix, imageDimensions, setMatrix, zoomSpeed } =
    useContext(CanvasContext);

  // @ts-ignore
  function onClick(state) {
    setSelectedAnnotation(currentBox.id);
  }
  // @ts-ignore
  function onDrag(state) {
    if (!isSelectedAnnotation(currentBox.id)) return;
    // Extract the movement from useGesture state
    const movementX = state.delta[0];
    const movementY = state.delta[1];

    // Check the change in terms of canvas percentages
    const { dx, dy } = convertChangeToPercentage(
      movementX,
      movementY,
      canvasWidth,
      // @ts-ignore
      imageDimensions,
      matrix
    );

    const newBox = { ...currentBox };
    newBox.x += dx;
    newBox.y += dy;

    if (newBox.x < 0) newBox.x = 0;
    if (newBox.y < 0) newBox.y = 0;
    if (newBox.x + newBox.w > 1) newBox.x = 1 - newBox.w;
    if (newBox.y + newBox.h > 1) newBox.y = 1 - newBox.h;

    // Update the annotation
    updateAnnotation(currentBox.id, newBox);
  }

  function onDragEnd() {
    updateAnnotation(currentBox.id, currentBox);
  }

  // Manage zooming in and out
  function onWheel(state: IWheelState) {
    // if (!canvasRef.current) return;
    const { left, top } = getCanvasBox(canvasRef);

    // get mouse position
    const currentMouseX = state.event.clientX - left;
    const currentMouseY = state.event.clientY - top;

    // Define scale value based on Y scroll
    let scaleValue = 1 + (zoomSpeed / 100) * state.direction[1];

    //check the current scale and limit it to 0.1 - 10
    const currentScale = matrix.a;
    if (currentScale * scaleValue < 0.01) scaleValue = 0.01 / currentScale;
    if (currentScale * scaleValue > 3) scaleValue = 3 / currentScale;

    // Translate, zoom and then translate back
    const matrixManipulations = [
      translate(currentMouseX, currentMouseY),
      scale(scaleValue),
      translate(-currentMouseX, -currentMouseY),
    ];

    // Save the new matrix
    const newMatrix = compose(matrix, ...matrixManipulations);
    setMatrix(newMatrix);
  }

  function onPinch(state: IPinchState) {
    if (!canvasRef.current) return;
    // Extract some mouse values
    const { left, top } = getCanvasBox(canvasRef);
    const currentMouseX = state.origin[0] - left;
    const currentMouseY = state.origin[1] - top;

    // Figure out how much to rescale
    let scaleValue = 1 + (zoomSpeed / 100) * Math.sign(-state.delta[0]);

    //check the current scale and limit it to 0.1 - 10
    const currentScale = matrix.a;
    if (currentScale * scaleValue < 0.01) scaleValue = 0.01 / currentScale;
    if (currentScale * scaleValue > 3) scaleValue = 3 / currentScale;

    // Translate, zoom and then translate back
    const matrixManipulations = [
      translate(currentMouseX, currentMouseY),
      scale(scaleValue),
      translate(-currentMouseX, -currentMouseY),
    ];
    const newMatrix = compose(matrix, ...matrixManipulations);
    setMatrix(newMatrix);
  }

  useGesture(
    {
      onDrag,
      onDragEnd,
      onClick,
      onWheel,
      onPinch,
    },
    {
      target: boxRef,
    }
  );
}
