import React, { useMemo, useEffect } from "react";
import { useContext } from "react";
import { SummaryContext } from "views/PoleViewer/provider";
import { useCategoryFilter, categoryOptions } from "../../hooks/categoryFilter";
import { IPoleStatus } from "views/PoleViewer/api";
import { getSortedUniqCategories } from "../../utils";

import styled from "styled-components";

export const TabsContainer = styled.div`
  display: flex;
  overflow: hidden;
  background: #f1f1f1;
  border-bottom: 1px solid #ccc;
  padding: 3px;
`;

const Tab = styled.div<{
  isActive: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}>`
  flex: 1;
  min-width: ${(props) => (props.isActive ? "max-content" : "20px")};
  max-width: ${(props) => (props.isActive ? "none" : "100px")};
  padding: 6px 10px;
	background: ${(props) => (props.isActive ? "#fff" : "#ddd")};
  border-radius: 5px 5px 0 0;
  text-align: center;
  cursor: pointer;
  font-size: ${(props) => (props.isActive ? "14px" : "12px")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 2px;
  transition: flex 0.3s ease-in-out, font-size 0.3s ease-in-out, background 0.3s;

  &:hover {
    background: #bbb;
  }
`;
export interface IProps {
  setFilteredComponents: (components: IPoleStatus[]) => void;
}

export default function PoleComponentCategories({
  setFilteredComponents,
}: IProps) {
  const { components } = useContext(SummaryContext);

  const { selectedCategory, setSelectedCategory, filteredComponents } =
    useCategoryFilter({ components });

  useEffect(() => {
    setFilteredComponents(filteredComponents);
  }, [filteredComponents, setFilteredComponents]);

  // Create a memoized list of categories from the components list for the tabs
  const categories = useMemo(() => {
    return getSortedUniqCategories({ components });
  }, [components]);

  const categoryTabValue = (category: string | number | null) => {
    if (category === null) {
      return categoryOptions.MISSING_CATEGORIES;
    }
    return category.toString();
  };

  // If there are no categories or only a single category with a null ID, do not render
  if (
    categories.length === 0 ||
    (categories.length === 1 && categories[0].id === null)
  ) {
    return null;
  }
  return (
    <TabsContainer>
      <Tab
        onClick={() => setSelectedCategory(categoryOptions.ALL)}
        isActive={selectedCategory === categoryTabValue(categoryOptions.ALL)}
      >
        All
      </Tab>
      {categories.map((category) => (
        <Tab
          key={category.id ?? "no-category"}
          isActive={selectedCategory === categoryTabValue(category?.id)}
          onClick={() => setSelectedCategory(categoryTabValue(category?.id))}
        >
          {category.name ?? "No Category"}
        </Tab>
      ))}
    </TabsContainer>
  );
}
